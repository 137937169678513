import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import HeaderBursa from './jsx/components/Bursa/HeaderBursa';

const ForbiddenPage = () => {
  return (
    <>
        <HeaderBursa />
        <Container className="text-center" style={{ marginTop: '100px', width: '50%' }}>
        <Row>
            <Col>
            <h1 className="display-1 text-danger">403</h1>
            <h2 className="mb-4">Forbidden</h2>
            <p>Mohon maaf, anda tidak bisa masuk ke pembuatan Paket Soal dan Bahan Ajar dikarenakan hanya Guru (Gu) yang bisa mengaksesnya, Silahkan ganti dengan akun guru untuk mengaksesnya</p>
            </Col>
        </Row>
        </Container>
    </>
    
  );
};

export default ForbiddenPage;
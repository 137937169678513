import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import {Provider} from 'react-redux';
import {store} from './store/store';
import  ThemeContext  from "./context/ThemeContext"; 
import { LoadingProvider } from './jsx/components/Global/LoadingProvider';
import { CartProvider } from './services/CartContext';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store = {store}>
      <BrowserRouter basename='/'>
      <LoadingProvider>
        <ThemeContext>
            <CartProvider>
              <App />
            </CartProvider>
        </ThemeContext>
      </LoadingProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);
reportWebVitals();
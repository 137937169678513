import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../../../services/AxiosInstance";

const StatistikPaketSoal = () => {
  const { id_paket } = useParams();
  const [activeTab, setActiveTab] = useState('pg');
  const [dataPg, setDataPg] = useState([]);
  const [dataMpg, setDataMpg] = useState([]);
  const [dataSa, setDataSa] = useState([]);
  const [dataBs, setDataBs] = useState([]);
  const [dataUr, setDataUr] = useState([]);
  const [dataPj, setDataPj] = useState([]);

  const navigate = useNavigate();

  const [ujianData, setUjianData] = useState(null);


  const detailUjian = async () => {
    var res = await axiosInstance.get(`paket-soal/${id_paket}?with_content=true`);
    var data = res.data.data;
    getStatistik(data.id);
  }
  
  // use effect
  useEffect(() => {
    detailUjian();
  }, []);

  const getStatistik = async (code) => {
    try {
      const response = await axiosInstance.get(`statistik/paket-soal/${code}`);
      const data = response.data.data;
  
      let dtPgTemp = [];
      let dtMpgTemp = [];
      let dtSaTemp = [];
      let dtBsTemp = [];
      let dtUrTemp = [];
      let dtPjTemp = [];
      
      data.forEach((item) => {
        let availableTab = "pg"
        if (item.data.soal.type === "PG") {
          dtPgTemp.push(item);
        }
        if (item.data.soal.type === "MPG") {
          availableTab = "mpg"
          dtMpgTemp.push(item);
        }
        if (item.data.soal.type === "SA") {
          availableTab = "sa"
          dtSaTemp.push(item);
        }
        if (item.data.soal.type === "BS") {
          availableTab = "bs"
          dtBsTemp.push(item);
        }
        if (item.data.soal.type === "UR") {
          availableTab = "ur"
          dtUrTemp.push(item);
        }
        if (item.data.soal.type === "PJ") {
          availableTab = "pj"
          dtPjTemp.push(item);
        }
        setActiveTab(availableTab);
      });
      
      // Update state once for each data type
      setDataPg(dtPgTemp);
      setDataMpg(dtMpgTemp);
      setDataSa(dtSaTemp);
      setDataBs(dtBsTemp);
      setDataUr(dtUrTemp);
      setDataPj(dtPjTemp);
    } catch (error) {
      console.log(error);
    }
  };

  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
  };

  const formatToTwoDecimalPlaces = (number) => {
    number = parseFloat(number); // Convert to a number
    if (isNaN(number)) {
        return 0;
    }
    return parseFloat(number.toFixed(2));
  }

  return (
    <div className="col-12">
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">Detail Ujian</h3>
        </div>
        <div className="card-body">
          <div className="col-11">
            {ujianData != null && (
              <>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="mb-1">
                    <b className="mb-0">ID Kartu Soal :</b>
                    <p className="mb-0">{ujianData.kartu_soal_code}</p>
                  </div>
                  <div className="mb-1">
                    <b className="mb-0">ID Paket Soal :</b>
                    <p className="mb-0">{'-'}</p>
                  </div>
                  <div className="mb-1">
                    <b className="mb-0">ID Daftar Ujian :</b>
                    <p className="mb-0">{ujianData.ujian_code}</p>
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  
                  <div className="mb-1">
                    <b className="mb-0">Jenjang :</b>
                    <p className="mb-0">{ujianData.jenjang}</p>
                  </div>
                  <div className="mb-1">
                    <b className="mb-0">Kelas :</b>
                    <p className="mb-0">{ujianData.kelas}</p>
                  </div>
                  <div className="mb-1">
                    <b className="mb-0">Mata Pelajaran :</b>
                    <p className="mb-0">{ujianData.mata_pelajaran}</p>
                  </div>
                </div>
                <div>
                  <div className="mb-1">
                    <b className="mb-0">Pemilik :</b>
                    <p className="mb-0">{ujianData.pemilik}</p>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header d-flex justify-content-between flex-wrap">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            {dataPg.length > 0  && (
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${activeTab === "pg" ? "active" : ""}`}
                  onClick={() => handleTabChange("pg")}
                >
                  Pilihan Ganda
                </button>
              </li>
            )}
            {dataMpg.length > 0  && (
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${activeTab === "mpg" ? "active" : ""}`}
                  onClick={() => handleTabChange("mpg")}
                >
                  Multipel Pilihan Ganda
                </button>
              </li>
            )}
            {dataSa.length > 0  && (
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${activeTab === "sa" ? "active" : ""}`}
                  onClick={() => handleTabChange("sa")}
                >
                  Sebab Akibat
                </button>
              </li>
            )}
            {dataBs.length > 0  && (
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${activeTab === "bs" ? "active" : ""}`}
                  onClick={() => handleTabChange("bs")}
                >
                  Benar Salah
                </button>
              </li>
            )}
            {dataUr.length > 0  && (
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${activeTab === "ur" ? "active" : ""}`}
                  onClick={() => handleTabChange("ur")}
                >
                  Uraian
                </button>
              </li>
            )}
            {dataPj.length > 0  && (
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${activeTab === "pj" ? "active" : ""}`}
                  onClick={() => handleTabChange("pj")}
                >
                  Penjodohan
                </button>
              </li>
            )}
          </ul>
        </div>
        
        <div className="card-body">
          {dataPg.length > 0  && (
            <div className="tab-content mt-3">
                <div className={activeTab === "pg" ? "tab-pane fade show active" : "tab-pane fade"}>
                  <h3>Bentuk Soal Pilihan Ganda</h3>
                  <div className="col-12 table-responsive">
                    <table className="table table-bordered table-stripped table-hover border-primary">
                      <thead>
                        <tr className="text-center">
                          <th rowspan="2">No.</th>
                          <th rowspan="2">ID Kartu Soal</th>
                          <th rowspan="2">ID Paket Soal</th>
                          <th rowspan="2">ID Daftar Ujian</th>
                          <th rowspan="2">NPSN</th>
                          <th rowspan="2">Nama Sekolah</th>
                          <th rowspan="2">Jen</th>
                          <th rowspan="2">Kls</th>
                          <th rowspan="2">Jur</th>
                          <th rowspan="2">Rombel</th>
                          <th rowspan="2">Waktu Ujian</th>
                          <th colspan="8">Jumlah Penjawab Pilihan</th>
                          <th colspan="7">% Penjawab Pilihan</th>
                          <th colspan="5">Fungsi Jawaban Pengecoh</th>
                          <th colspan="2">Tingkat Kesulitan</th>
                          <th colspan="4">Daya Beda</th>
                          <th colspan="3">Validitas</th>
                        </tr>
                        <tr>
                          <th>A</th>
                          <th>B</th>
                          <th>C</th>
                          <th>D</th>
                          <th>E</th>
                          <th>K</th>
                          <th>∑</th>
                          <th>NB</th>
                          <th>A %</th>
                          <th>B %</th>
                          <th>C %</th>
                          <th>D %</th>
                          <th>E %</th>
                          <th>K</th>
                          <th>NB %</th>
                          <th>A</th>
                          <th>B</th>
                          <th>C</th>
                          <th>D</th>
                          <th>E</th>
                          <th>p = B/n</th>
                          <th>Kategori</th>
                          <th>rpbis</th>
                          <th>Kategori</th>
                          <th>D =PA-PB</th>
                          <th>Kategori</th>
                          <th>r Tabel</th>
                          <th>r Hitung</th>
                          <th>Validitas</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataPg.map((item, index) => (
                          <tr key={index}>
                            <td>{item.id}</td>
                            <td>{item.code}</td>
                            <td>{item.ujian.paket_soal_code}</td>
                            <td>{item.ujian.code}</td>
                            <td>{item.ujian.created_by_user.detail.npsn}</td>
                            <td>{item.ujian.created_by_user.detail.profile.institusi.nama_institusi}</td>
                            <td>{item.ujian.created_by_user.detail.profile.institusi.jenjang.jenjang}</td>
                            <td>{item.ujian.detail.kelas}</td>
                            <td>{ujianData.jurusan}</td>
                            <td>{item.ujian.detail.rombel}</td>
                            <td>{item.ujian.started_at}</td>
                            <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["a"])}</td>
                            <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["b"])}</td>
                            <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["c"])}</td>
                            <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["d"])}</td>
                            <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["e"])}</td>
                            <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["k"])}</td>
                            <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["zigma"])}</td>
                            <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["nb"])}</td>
                            
                            <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["a"])}</td>
                            <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["b"])}</td>
                            <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["c"])}</td>
                            <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["d"])}</td>
                            <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["e"])}</td>
                            <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["k"])}</td>
                            <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["nb"])}</td>

                            <td>{item.value.fungsi_penjawab_pengecoh["a"] ?? '-'}</td>
                            <td>{item.value.fungsi_penjawab_pengecoh["b"] ?? '-'}</td>
                            <td>{item.value.fungsi_penjawab_pengecoh["c"] ?? '-'}</td>
                            <td>{item.value.fungsi_penjawab_pengecoh["d"] ?? '-'}</td>
                            <td>{item.value.fungsi_penjawab_pengecoh["e"] ?? '-'}</td>

                            <td>{formatToTwoDecimalPlaces(item.value.tingkat_kesulitan["p"])}</td>
                            <td>{item.value.tingkat_kesulitan["kategori"]}</td>
                            
                            <td>{formatToTwoDecimalPlaces(item.value.daya_beda["rpbis"])}</td>
                            <td>{item.value.daya_beda["rpbis_katogori"]}</td>
                            <td>{formatToTwoDecimalPlaces(item.value.daya_beda["diskriminasi"])}</td>
                            <td>{item.value.daya_beda["diskriminasi_kategori"]}</td>

                            <td>{formatToTwoDecimalPlaces(item.value.validitas["rtabel"])}</td>
                            <td>{formatToTwoDecimalPlaces(item.value.validitas["rhitung"])}</td>
                            <td>{item.value.validitas["validitas"]}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
            </div>
          )}
          {dataMpg.length > 0  && (
            <div className="tab-content mt-3">
                <div className={activeTab === "mpg" ? "tab-pane fade show active" : "tab-pane fade"}>
                  <h3>Bentuk Soal Multipel Pilihan Ganda</h3>
                </div>
            </div>
          )}
          {dataSa.length > 0  && (
            <div className="tab-content mt-3">
                <div className={activeTab === "sa" ? "tab-pane fade show active" : "tab-pane fade"}>
                  <h3>Bentuk Soal Sebab Akibat</h3>
                  <div className="col-12 table-responsive">
                    <table className="table table-bordered table-stripped table-hover border-primary">
                      <thead>
                        <tr>
                          <th rowspan="2">No.</th>
                          <th rowspan="2">ID Kartu Soal</th>
                          <th rowspan="2">ID Paket Soal</th>
                          <th rowspan="2">ID Daftar Ujian</th>
                          <th rowspan="2">NPSN</th>
                          <th rowspan="2">Nama Sekolah</th>
                          <th rowspan="2">Jen</th>
                          <th rowspan="2">Kls</th>
                          <th rowspan="2">Jur</th>
                          <th rowspan="2">Rombel</th>
                          <th rowspan="2">Waktu Ujian</th>
                          <th colspan="8">Jumlah Penjawab Pilihan</th>
                          <th colspan="7">% Penjawab Pilihan</th>
                          <th colspan="5">Fungsi Jawaban Pengecoh</th>
                          <th colspan="2">Tingkat Kesulitan</th>
                          <th colspan="4">Daya Beda</th>
                          <th colspan="3">Validitas</th>
                        </tr>
                        <tr>
                          <th>A</th>
                          <th>B</th>
                          <th>C</th>
                          <th>D</th>
                          <th>E</th>
                          <th>K</th>
                          <th>∑</th>
                          <th>NB</th>
                          <th>A %</th>
                          <th>B %</th>
                          <th>C %</th>
                          <th>D %</th>
                          <th>E %</th>
                          <th>K</th>
                          <th>NB %</th>
                          <th>A</th>
                          <th>B</th>
                          <th>C</th>
                          <th>D</th>
                          <th>E</th>
                          <th>p = B/n</th>
                          <th>Kategori</th>
                          <th>rpbis</th>
                          <th>Kategori</th>
                          <th>D =PA-PB</th>
                          <th>Kategori</th>
                          <th>r Tabel</th>
                          <th>r Hitung</th>
                          <th>Validitas</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataSa.map((item, index) => (
                          <tr key={index}>
                            <td>{item.id}</td>
                            <td>{item.code}</td>
                            <td>{item.ujian.paket_soal_code}</td>
                            <td>{item.ujian.code}</td>
                            <td>{item.ujian.created_by_user.detail.npsn}</td>
                            <td>{item.ujian.created_by_user.detail.profile.institusi.nama_institusi}</td>
                            <td>{item.ujian.created_by_user.detail.profile.institusi.jenjang.jenjang}</td>
                            <td>{item.ujian.detail.kelas}</td>
                            <td>{ujianData.jurusan}</td>
                            <td>{item.ujian.detail.rombel}</td>
                            <td>{item.ujian.started_at}</td>
                            <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["a"])}</td>
                            <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["b"])}</td>
                            <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["c"])}</td>
                            <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["d"])}</td>
                            <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["e"])}</td>
                            <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["k"])}</td>
                            <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["zigma"])}</td>
                            <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["nb"])}</td>
                            
                            <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["a"])}</td>
                            <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["b"])}</td>
                            <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["c"])}</td>
                            <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["d"])}</td>
                            <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["e"])}</td>
                            <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["k"])}</td>
                            <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["nb"])}</td>

                            <td>{item.value.fungsi_penjawab_pengecoh["a"] ?? '-'}</td>
                            <td>{item.value.fungsi_penjawab_pengecoh["b"] ?? '-'}</td>
                            <td>{item.value.fungsi_penjawab_pengecoh["c"] ?? '-'}</td>
                            <td>{item.value.fungsi_penjawab_pengecoh["d"] ?? '-'}</td>
                            <td>{item.value.fungsi_penjawab_pengecoh["e"] ?? '-'}</td>

                            <td>{formatToTwoDecimalPlaces(item.value.tingkat_kesulitan["p"])}</td>
                            <td>{item.value.tingkat_kesulitan["kategori"]}</td>
                            
                            <td>{formatToTwoDecimalPlaces(item.value.daya_beda["rpbis"])}</td>
                            <td>{item.value.daya_beda["rpbis_katogori"]}</td>
                            <td>{formatToTwoDecimalPlaces(item.value.daya_beda["diskriminasi"])}</td>
                            <td>{item.value.daya_beda["diskriminasi_kategori"]}</td>

                            <td>{formatToTwoDecimalPlaces(item.value.validitas["rtabel"])}</td>
                            <td>{formatToTwoDecimalPlaces(item.value.validitas["rhitung"])}</td>
                            <td>{item.value.validitas["validitas"]}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
            </div>
          )}
          {dataBs.length > 0  && (
            <div className="tab-content mt-3">
                <div className={activeTab === "bs" ? "tab-pane fade show active" : "tab-pane fade"}>
                  <h3>Bentuk Soal Benar Salah</h3>
                  <div className="col-12 table-responsive">
                    <table className="table table-bordered table-stripped table-hover border-primary">
                      <thead>
                        <tr className="text-center">
                          <th rowspan="2">No.</th>
                          <th rowspan="2">ID Kartu Soal</th>
                          <th rowspan="2">ID Paket Soal</th>
                          <th rowspan="2">ID Daftar Ujian</th>
                          <th rowspan="2">NPSN</th>
                          <th rowspan="2">Nama Sekolah</th>
                          <th rowspan="2">Jen</th>
                          <th rowspan="2">Kls</th>
                          <th rowspan="2">Jur</th>
                          <th rowspan="2">Rombel</th>
                          <th rowspan="2">Waktu Ujian</th>
                          <th colspan="5">Jumlah Penjawab Pilihan</th>
                          <th colspan="4">% Penjawab Pilihan</th>
                          <th colspan="2">Fungsi Jawaban Pengecoh</th>
                          <th colSpan="2">Tingkat Kesulitan</th>
                          <th colspan="4">Daya Beda</th>
                          <th colspan="3">Validitas</th>
                        </tr>
                        <tr>
                          <th>B</th>
                          <th>S</th>
                          <th>K</th>
                          <th>∑</th>
                          <th>NB</th>
                          <th>B %</th>
                          <th>S %</th>
                          <th>K %</th>
                          <th>NB %</th>
                          <th>B</th>
                          <th>S</th>
                          <th>p = B/n</th>
                          <th>Katagori</th>
                          <th>rpbis</th>
                          <th>Katagori</th>
                          <th>D =PA-PB</th>
                          <th>Katagori</th>
                          <th>r Tabel</th>
                          <th>r Hitung</th>
                          <th>Validitas</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataBs.map((item, index) => (
                          <tr key={index}>
                            <td>{item.id}</td>
                            <td>{item.code}</td>
                            <td>{item.ujian.paket_soal_code}</td>
                            <td>{item.ujian.code}</td>
                            <td>{item.ujian.created_by_user.detail.npsn}</td>
                            <td>{item.ujian.created_by_user.detail.profile.institusi.nama_institusi}</td>
                            <td>{item.ujian.created_by_user.detail.profile.institusi.jenjang.jenjang}</td>
                            <td>{item.ujian.detail.kelas}</td>
                            <td>{ujianData.jurusan}</td>
                            <td>{item.ujian.detail.rombel}</td>
                            <td>{item.ujian.started_at}</td>
                            <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["b"])}</td>
                            <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["s"])}</td>
                            <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["k"])}</td>
                            <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["zigma"])}</td>
                            <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["nb"])}</td>
                            
                            <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["b"])}</td>
                            <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["s"])}</td>
                            <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["k"])}</td>
                            <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["nb"])}</td>

                            <td>{item.value.fungsi_penjawab_pengecoh["b"] ?? '-'}</td>
                            <td>{item.value.fungsi_penjawab_pengecoh["s"] ?? '-'}</td>

                            <td>{formatToTwoDecimalPlaces(item.value.tingkat_kesulitan["p"])}</td>
                            <td>{item.value.tingkat_kesulitan["kategori"]}</td>
                            
                            <td>{formatToTwoDecimalPlaces(item.value.daya_beda["rpbis"])}</td>
                            <td>{item.value.daya_beda["rpbis_katogori"]}</td>
                            <td>{formatToTwoDecimalPlaces(item.value.daya_beda["diskriminasi"])}</td>
                            <td>{item.value.daya_beda["diskriminasi_kategori"]}</td>

                            <td>{formatToTwoDecimalPlaces(item.value.validitas["rtabel"])}</td>
                            <td>{formatToTwoDecimalPlaces(item.value.validitas["rhitung"])}</td>
                            <td>{item.value.validitas["validitas"]}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
            </div>
          )}
          {dataUr.length > 0  && (
            <div className="tab-content mt-3">
                <div className={activeTab === "ur" ? "tab-pane fade show active" : "tab-pane fade"}>
                  <h3>Bentuk Soal Uraian</h3>
                  <div className="col-12 table-responsive">
                    <table className="table table-bordered table-stripped table-hover border-primary">
                      <thead>
                        <tr className="text-center">
                          <th rowspan="2">No.</th>
                          <th rowspan="2">ID Kartu Soal</th>
                          <th rowspan="2">ID Paket Soal</th>
                          <th rowspan="2">ID Daftar Ujian</th>
                          <th rowspan="2">NPSN</th>
                          <th rowspan="2">Nama Sekolah</th>
                          <th rowspan="2">Jen</th>
                          <th rowspan="2">Kls</th>
                          <th rowspan="2">Jur</th>
                          <th rowspan="2">Rombel</th>
                          <th rowspan="2">Waktu Ujian</th>
                          <th colspan="13">Jumlah Penjawab dengan Nilai 1 s.d. 10</th>
                          <th colspan="10">% Penjawab Pilihan</th>
                          <th colspan="2">Tingkat Kesulitan</th>
                          <th colspan="4">Daya Beda</th>
                          <th colspan="3">Validitas</th>
                        </tr>
                        <tr>
                          <th>1</th>
                          <th>2</th>
                          <th>3</th>
                          <th>4</th>
                          <th>5</th>
                          <th>6</th>
                          <th>7</th>
                          <th>8</th>
                          <th>9</th>
                          <th>10</th>
                          <th>K</th>
                          <th>∑</th>
                          <th>NB</th>
                          <th>1</th>
                          <th>2</th>
                          <th>3</th>
                          <th>4</th>
                          <th>5</th>
                          <th>6</th>
                          <th>7</th>
                          <th>8</th>
                          <th>9</th>
                          <th>10</th>
                          <th>p = B/n</th>
                          <th>Katagori</th>
                          <th>rpbis</th>
                          <th>Katagori</th>
                          <th>D =PA-PB</th>
                          <th>Katagori</th>
                          <th>r Tabel</th>
                          <th>r Hitung</th>
                          <th>Validitas</th>
                        </tr>
                      </thead>
                      <tbody>
                      {dataUr.map((item, index) => (
                        <tr key={index}>
                          <td>{item.id}</td>
                          <td>{item.code}</td>
                          <td>{item.ujian.paket_soal_code}</td>
                          <td>{item.ujian.code}</td>
                          <td>{item.ujian.created_by_user.detail.npsn}</td>
                          <td>{item.ujian.created_by_user.detail.profile.institusi.nama_institusi}</td>
                          <td>{item.ujian.created_by_user.detail.profile.institusi.jenjang.jenjang}</td>
                          <td>{item.ujian.detail.kelas}</td>
                          <td>{ujianData.jurusan}</td>
                          <td>{item.ujian.detail.rombel}</td>
                          <td>{item.ujian.started_at}</td>
                          <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["1"])}</td>
                          <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["2"])}</td>
                          <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["3"])}</td>
                          <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["4"])}</td>
                          <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["5"])}</td>
                          <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["6"])}</td>
                          <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["7"])}</td>
                          <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["8"])}</td>
                          <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["9"])}</td>
                          <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["10"])}</td>
                          <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["k"])}</td>
                          <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["zigma"])}</td>
                          <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["nb"])}</td>
                          
                          <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["Asosiatif"])}</td>
                          <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["Imitasi"])}</td>
                          <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["Interaksionisme simbolik"])}</td>
                          <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["Internalisasi"])}</td>
                          <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["Pengendalian sosial formal"])}</td>
                          <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["Sosialisasi"])}</td>
                          <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["Struktur sosial"])}</td>
                          <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["Tindakan rasional nilai"])}</td>
                          <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["k"])}</td>
                          <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["nb"])}</td>

                          <td>{formatToTwoDecimalPlaces(item.value.tingkat_kesulitan["p"])}</td>
                          <td>{item.value.tingkat_kesulitan["kategori"]}</td>
                          
                          <td>{formatToTwoDecimalPlaces(item.value.daya_beda["rpbis"])}</td>
                          <td>{item.value.daya_beda["rpbis_katogori"]}</td>
                          <td>{formatToTwoDecimalPlaces(item.value.daya_beda["diskriminasi"])}</td>
                          <td>{item.value.daya_beda["diskriminasi_kategori"]}</td>

                          <td>{formatToTwoDecimalPlaces(item.value.validitas["rtabel"])}</td>
                          <td>{formatToTwoDecimalPlaces(item.value.validitas["rhitung"])}</td>
                          <td>{item.value.validitas["validitas"]}</td>
                        </tr>
                      ))}
                      </tbody>
                    </table>
                  </div>
                </div>
            </div>
          )}
          {dataPj.length > 0  && (
            <div className="tab-content mt-3">
              <div className={activeTab === "pj" ? "tab-pane fade show active" : "tab-pane fade"}>
                <h3>Bentuk Soal Penjodohan</h3>
                <div className="col-12 table-responsive">
                  <table className="table table-bordered table-stripped table-hover border-primary">
                    <thead>
                      <tr className="text-center">
                        <th rowspan="2">No.</th>
                        <th rowspan="2">ID Kartu Soal</th>
                        <th rowspan="2">ID Paket Soal</th>
                        <th rowspan="2">ID Daftar Ujian</th>
                        <th rowspan="2">NPSN</th>
                        <th rowspan="2">Nama Sekolah</th>
                        <th rowspan="2">Jen</th>
                        <th rowspan="2">Kls</th>
                        <th rowspan="2">Jur</th>
                        <th rowspan="2">Rombel</th>
                        <th rowspan="2">Waktu Ujian</th>
                        <th colspan="13">Jumlah Penjawab Pilihan</th>
                        <th colspan="12">% Penjawab Pilihan</th>
                        <th colspan="10">Fungsi Jawaban Pengecoh</th>
                        <th colSpan="2">Tingkat Kesulitan</th>
                        <th colSpan="4">Daya Beda</th>
                        <th colSpan="3">Validitas</th>
                      </tr>
                      <tr>
                        <th>A</th>
                        <th>B</th>
                        <th>C</th>
                        <th>D</th>
                        <th>E</th>
                        <th>F</th>
                        <th>G</th>
                        <th>H</th>
                        <th>I</th>
                        <th>J</th>
                        <th>Ko</th>
                        <th>∑</th>
                        <th>NB</th>
                        <th>A %</th>
                        <th>B %</th>
                        <th>C %</th>
                        <th>D %</th>
                        <th>E %</th>
                        <th>F %</th>
                        <th>G %</th>
                        <th>H %</th>
                        <th>I %</th>
                        <th>J %</th>
                        <th>Ko</th>
                        <th>NB %</th>
                        <th>A</th>
                        <th>B</th>
                        <th>C</th>
                        <th>D</th>
                        <th>E</th>
                        <th>F</th>
                        <th>G</th>
                        <th>H</th>
                        <th>I</th>
                        <th>J</th>
                        <th>p = B/n</th>
                        <th>Kategori</th>
                        <th>rpbis</th>
                        <th>Kategori</th>
                        <th>D =PA-PB</th>
                        <th>Kategori</th>
                        <th>r Tabel</th>
                        <th>r Hitung</th>
                        <th>validitas</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataPj.map((item, index) => (
                        <tr key={index}>
                          <td>{item.id}</td>
                          <td>{item.code}</td>
                          <td>{item.ujian.paket_soal_code}</td>
                          <td>{item.ujian.code}</td>
                          <td>{item.ujian.created_by_user.detail.npsn}</td>
                          <td>{item.ujian.created_by_user.detail.profile.institusi.nama_institusi}</td>
                          <td>{item.ujian.created_by_user.detail.profile.institusi.jenjang.jenjang}</td>
                          <td>{item.ujian.detail.kelas}</td>
                          <td>{ujianData.jurusan}</td>
                          <td>{item.ujian.detail.rombel}</td>
                          <td>{item.ujian.started_at}</td>
                          <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["1"])}</td>
                          <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["2"])}</td>
                          <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["3"])}</td>
                          <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["4"])}</td>
                          <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["5"])}</td>
                          <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["6"])}</td>
                          <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["7"])}</td>
                          <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["8"])}</td>
                          <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["9"])}</td>
                          <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["10"])}</td>
                          <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["k"])}</td>
                          <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["zigma"])}</td>
                          <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["nb"])}</td>
                          
                          <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["Asosiatif"])}</td>
                          <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["Imitasi"])}</td>
                          <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["Interaksionisme simbolik"])}</td>
                          <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["Internalisasi"])}</td>
                          <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["Pengendalian sosial formal"])}</td>
                          <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["Sosialisasi"])}</td>
                          <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["Struktur sosial"])}</td>
                          <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["Tindakan rasional nilai"])}</td>
                          <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["k"])}</td>
                          <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["nb"])}</td>
                          <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["nb"])}</td>
                          <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["nb"])}</td>

                          <td>{item.value.fungsi_penjawab_pengecoh["Pengendalian sosial formal"] ?? '-'}</td>
                          <td>{item.value.fungsi_penjawab_pengecoh["Imitasi"] ?? '-'}</td>
                          <td>{item.value.fungsi_penjawab_pengecoh["Asosiatif"] ?? '-'}</td>
                          <td>{item.value.fungsi_penjawab_pengecoh["Tindakan rasional nilai"] ?? '-'}</td>
                          <td>{item.value.fungsi_penjawab_pengecoh["Struktur sosial"] ?? '-'}</td>
                          <td>{item.value.fungsi_penjawab_pengecoh["Internalisasi"] ?? '-'}</td>
                          <td>{item.value.fungsi_penjawab_pengecoh["Sosialisasi"] ?? '-'}</td>
                          <td>{item.value.fungsi_penjawab_pengecoh["Interaksionisme simbolik"] ?? '-'}</td>
                          <td>{item.value.fungsi_penjawab_pengecoh["Interaksionisme simbolik"] ?? '-'}</td>
                          <td>{item.value.fungsi_penjawab_pengecoh["Interaksionisme simbolik"] ?? '-'}</td>
                          
                          <td>{formatToTwoDecimalPlaces(item.value.tingkat_kesulitan["p"])}</td>
                          <td>{item.value.tingkat_kesulitan["kategori"]}</td>
                          
                          <td>{formatToTwoDecimalPlaces(item.value.daya_beda["rpbis"])}</td>
                          <td>{item.value.daya_beda["rpbis_katogori"]}</td>
                          <td>{formatToTwoDecimalPlaces(item.value.daya_beda["diskriminasi"])}</td>
                          <td>{item.value.daya_beda["diskriminasi_kategori"]}</td>

                          <td>{formatToTwoDecimalPlaces(item.value.validitas["rtabel"])}</td>
                          <td>{formatToTwoDecimalPlaces(item.value.validitas["rhitung"])}</td>
                          <td>{item.value.validitas["validitas"]}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StatistikPaketSoal;

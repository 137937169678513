import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Dropdown, Modal, ModalBody, ModalHeader, ModalTitle } from "react-bootstrap";
import swal from "sweetalert";
import { nanoid } from "nanoid";
import { IMAGES } from "../Dashboard/Content";
import CardPaketSoal from "./ShowPage/Card";
import TablePaketSoal from "./ShowPage/Table";
import axiosInstance from "../../../services/AxiosInstance";
import { isAuthenticated } from "../../../store/selectors/AuthSelectors";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import axiosBahanAjar from "../../../services/axiosBahanAjar";


function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();

    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

const PaketAjar = (props) => {
  const [contacts, setContacts] = useState([]);
  const [showStyle, setShowStyle] = useState("card");
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(15);
  useEffect(() => {
    loadData();
  }, [currentPage]);

  const loadData = async () => {
    try {
      const response = await axiosBahanAjar.get(`paket-ajar?per_page=${perPage}&page=${currentPage}`);
      var data = response.data.data;
      console.log("data:", data);
      setContacts(data.items);
      setTotal(data.meta.totalItems);
    } catch (error) {
      console.log(error);
    }
  };

  const getNonZeroKeys = (score) => {
    return Object.keys(score).filter(key => {
        return Object.values(score[key]).every(value => value !== 0);
    });
  };

  const getColorByTypeSoal = (type) => {
    switch (type) {
      case "PG":
        return "primary";
      case "SA":
        return "warning";
      case "BS":
        return "success";
      case "UR":
        return "danger";
      default:
        return "primary";
    }
  };

  const onDelete = async (id) => {
    try {
      const response = await axiosBahanAjar.delete(`paket-ajar/${id}`);
      console.log(response);
      loadData();
    } catch (error) {
      console.log('delete', error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!\n'+error.response.data.error,
      })
    }
  };

  const handlePaging = (page) => {
    console.log("page:", page);
    setCurrentPage(page);
  }

  const onChangeStatus = async (id, status) => {
    try {
      const response = await axiosBahanAjar.put(`paket-ajar/${id}`, { status: status ? 'publish' : 'draft' });
      console.log(response);
      loadData();
    } catch (error) {
      console.log(error);
    }
  };
 
  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="page-title flex-wrap">
          <div className="input-group search-area mb-md-0 mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Search here..."
            />
            <span className="input-group-text">
              <Link to={"#"}>
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.5605 15.4395L13.7527 11.6317C14.5395 10.446 15 9.02625 15 7.5C15 3.3645 11.6355 0 7.5 0C3.3645 0 0 3.3645 0 7.5C0 11.6355 3.3645 15 7.5 15C9.02625 15 10.446 14.5395 11.6317 13.7527L15.4395 17.5605C16.0245 18.1462 16.9755 18.1462 17.5605 17.5605C18.1462 16.9747 18.1462 16.0252 17.5605 15.4395V15.4395ZM2.25 7.5C2.25 4.605 4.605 2.25 7.5 2.25C10.395 2.25 12.75 4.605 12.75 7.5C12.75 10.395 10.395 12.75 7.5 12.75C4.605 12.75 2.25 10.395 2.25 7.5V7.5Z"
                    fill="#01A3FF"
                  />
                </svg>
              </Link>
            </span>
          </div>
          <div className="d-flex">
            <ul
              as="ul"
              className="nav nav-tabs dzm-tabs me-2"
              id="myTab"
              role="tablist"
            >
              <li as="li" className="nav-item" role="presentation">
                <button
                  className={`nav-link ${showStyle == "card" ? "active" : ""}`}
                  type="button"
                  onClick={() => setShowStyle("card")}
                >
                  <i className="fas fa-address-card"></i>
                </button>
              </li>
              {/* <li as="li" className="nav-item">
                <button
                  className={`nav-link ${showStyle == "list" ? "active" : ""}`}
                  type="button"
                  onClick={() => setShowStyle("list")}
                >
                  <i className="fas fa-list"></i>
                </button>
              </li> */}
            </ul>
            <Link
              type="button"
              className="btn btn-primary"
              to={"/paket-ajar/add"}
            >
              + Create Paket Ajar
            </Link>
          </div>
        </div>
      </div>
      {/* {contacts} */}
      {showStyle == "card" ? (
        <CardPaketSoal onChangeStatus={onChangeStatus} contacts={contacts}  onBursa={loadData} onDelete={onDelete}  total={total} perPage={perPage} onPaging={handlePaging} />
      ) : (
        <TablePaketSoal contacts={contacts}  onBursa={loadData} onDelete={onDelete}  total={total} perPage={perPage} onPaging={handlePaging} />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  console.log('mapStateToProps butirsoal', state);
  return {
    isAuthenticated: isAuthenticated(state),
    user: state.auth.auth,
  };
};

export default withRouter(connect(mapStateToProps)(PaketAjar));

import React, { useState, useRef, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axiosInstance from "../../../../services/AxiosInstance";
import {
  Badge,
  Modal,
  Button,
  Row,
  Col
} from "react-bootstrap";
import Swal from "sweetalert2";
import "./DetailHasil.css";

const KoreksiJawaban = (props) => {
  const inputEl = useRef(null);
  const [activeTab, setActiveTab] = useState('pg');
  const [dtJawaban, setDtJawaban] = useState([]);

  const { id_ujian } = useParams();

  const [showKomposisi, setShowKomposisi] = useState(false);

  useEffect(() => {
    loadDataJawaban()
  }, []);

  const loadDataJawaban = async () => {
    const res = await axiosInstance.get(`ujian/${id_ujian}`);
    const data = res.data.data;
    const paketSoal = await loadPaketSoalDetail(data.paket_soal_id)
    var dtSoal = {
      soal: [],
      jawaban: [],
    };
    
    paketSoal.forEach((item) => {
      if (item.type === "UR") {
        dtSoal.soal.push(item);
      }
    });

    if (dtSoal.soal.length > 0) {
      for (let i = 0; i < data.peserta.length; i++) {
        // Initialize 'jawaban' if it doesn't already exist
        if (!dtSoal['jawaban'][i]) {
          dtSoal['jawaban'][i] = [];
        }
  
        // Add the participant's details
        dtSoal['jawaban'][i].push({
          id: data.peserta[i].id,
          kelas: data.peserta[i].detail.kelas,
          rombel: data.peserta[i].detail.rombel,
          nama_siswa: data.peserta[i].user.name,
          status: data.peserta[i].status,
          score: data.peserta[i].score,
          nilai_bobot: data.peserta[i].nilai_bobot,
          presentase_nilai_bobot: data.peserta[i].presentase_nilai_bobot,
          ranking: data.peserta[i].ranking,
        }); 
  
        // Process the responses
        let response = [];
        for (let keyIndex = 0; keyIndex < dtSoal['soal'].length; keyIndex++) {
          let soalId = dtSoal['soal'][keyIndex].no_urut; // Get the specific soal ID
          // Find the corresponding jawaban
          let jawaban = data.peserta[i].value.find(
            (jawaban) => soalId === jawaban.no_urut
          );
          
          // Push the jawaban into the specific soal ID
          response.push(jawaban);
        }

        // Update 'jawaban' with responses
        dtSoal['jawaban'][i] = {
          ...dtSoal['jawaban'][i][0], // Use the first item in the 'jawaban' array
          response: response,
        };
      }
    }

    setDtJawaban(dtSoal);
  }

  const [selectedPaketSoal, setSelectedPaketSoal] = useState(null);
  const loadPaketSoalDetail = async (id_paket_soal) => {
    var res = await axiosInstance.get(`paket-soal/${id_paket_soal}?with_content=true`);
    console.log('res : ', res)
    return res.data.data.value;
  }


  const getTypeCode = (type) => {
    if (type == "pg") {
      return "Pilihan Ganda";
    }else if (type == "mpg") {
      return "Multiple Pilihan Ganda";
    } else if (type == "bs") {
      return "Benar Salah";
    } else if (type == "ur") {
      return "Uraian";
    } else if (type == "pj") {
      return "Penjodohan";
    } else if (type == "sa") {
      return "Sebab Akibat";
    }
  };


  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
  };
  
  return (
    <div className="col-12">
      <div className="card dz-card">
        <div className="card-header">
          <div className="card-title">Koreksi Jawaban Siswa</div>
        </div>
        <div className="card-body">
          {dtJawaban?.soal?.length > 0 ? (
            <div className="table-responsive">
              <table className="table table-bordered table-stripped table-hover">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Kelas</th>
                    <th>Rombel</th>
                    <th>Nama Peserta</th>
                    {dtJawaban.soal.map((soal, no) => (
                      <th>{soal.no_urut}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                    {dtJawaban.jawaban.map((peserta, no) => (
                      <tr>
                        <td>{no+1}</td>
                        <td>{peserta.kelas}</td>
                        <td>{peserta.rombel}</td>
                        <td>{peserta.nama_siswa}</td>
                        {dtJawaban.jawaban[no].response.map((jawaban, num) => (
                          <td>{jawaban.jawaban}</td>
                        ))}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="col-12 text-center">
              <p className="mb-0">Tidak ada soal uraian</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default KoreksiJawaban;

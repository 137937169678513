import React from 'react';
import Swal from 'sweetalert2';
// import withReactContent from 'sweetalert2-react-content';
import './loading.css'; // Jika ingin menambahkan CSS kustom untuk spinner

const MySwal = Swal;

export const showLoading = () => {
  MySwal.fire({
    title: 'Mohon tunggu...',
    html: '<div class="spinner"></div>',
    allowOutsideClick: false,
    showConfirmButton: false,
  });
};

export const closeLoading = () => {
  Swal.close();
};

export default function LoadingSwal() {
  return null; // Tidak ada UI untuk komponen ini, hanya fungsi
}

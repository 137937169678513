import React, { useEffect, useState } from "react";
import HeaderBursa from "./HeaderBursa";
import bg2 from "../../../images/background/bg2.png";
import bg3 from "../../../images/background/bg3.png";
import bg4 from "../../../images/background/bg4.png";
import bg5 from "../../../images/background/bg5.png";
import bg6 from "../../../images/background/bg6.png";
import FooterBursa from "./FooterBursa";
import Select from "react-select";
import axiosInstance from "../../../services/AxiosInstance";
import CardBursa from "./Card/CardBursa";
import CardPaginate from "./ShowPage/CardPaginate";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import TablePaginate from "./ShowPage/TablePaginate";
import SideBarFilter from "./SideBar/SideBarFilter";

const BursaPaketSoal = () => {
  const [isClearable, setIsClearable] = useState(false);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRtl, setIsRtl] = useState(false);
  const [perPage, setPerPage] = useState(15);
  const [showStyle, setShowStyle] = useState("card");

  const [jenjang, setJenjang] = useState([
    { value: "PAUD", label: "PAUD" },
    { value: "SD/MI", label: "SD/MI" },
    { value: "SMP/MTs", label: "SMP/MTs" },
    { value: "SMA/MA", label: "SMA/MA" },
    { value: "SMK/MAK", label: "SMK/MAK" },
  ]);

  const [nullSel, setNullSel] = useState([
    { value: "-", label: "Select Option" },
  ]);
  const [showFilter, setShowFilter] = useState(false);
  const [konten, setKonten] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      const response = await axiosInstance.get("bursa/paket-soal");
      var data = response.data.data;
      var tmp = []
      for (var i = 0; i < data.length; i++) {
        tmp.push({
          id: data[i].id,
          code: data[i].code,
          judul: data[i].detail.judul,
          jenis: "Paket Soal",
          jumlah_butir: data[i].detail.soal_id_list.length ?? 0,
          created_by: data[i].created_by,
          cover: bg6,
          price: data[i].price,
          score: data[i].score
        })
      }
      setTotal(response.data.total);
      setKonten(tmp);
    } catch (error) {
      console.log(error);
    }
  }

  
  const handlePaging = (page) => {
    console.log("page:", page);
    setCurrentPage(page);
  }

  return (
    <>
      <HeaderBursa />
      <div className="container min-vh-100">
        <div className="row">
        
          <div className="col-12 mt-3">
            <div className="breadcumb d-flex">
              <b>Home </b> <span className="ms-1"> / Bursa</span>
            </div>
          </div>

          <div className="col-3 mt-3">
            <SideBarFilter />
          </div>

          <div className="col-9 mt-3">
            <div className="card">
              <div className="card-header row">
                <div className="col-md-6">
                  <h4 className="mb-0">List Bursa Paket Soal</h4>
                  <p className="text-dark mb-0"><b>12</b> Konten Ditemukan !</p>
                </div>
                <div className="col-md-6 d-flex justify-content-end">
                  <ul
                    as="ul"
                    className="nav nav-tabs dzm-tabs me-2"
                    id="myTab"
                    role="tablist"
                  >
                    <li as="li" className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${showStyle == "card" ? "active" : ""}`}
                        type="button"
                        onClick={() => setShowStyle("card")}
                      >
                        <i className="fas fa-address-card"></i>
                      </button>
                    </li>
                    <li as="li" className="nav-item">
                      <button
                        className={`nav-link ${showStyle == "list" ? "active" : ""}`}
                        type="button"
                        onClick={() => setShowStyle("list")}
                      >
                        <i className="fas fa-list"></i>
                      </button>
                    </li>
                  </ul>
                  <div>

                  <div
                    onClick={() => setShowFilter(!showFilter)}
                    className="form-control d-flex justify-content-between align-items-center flex-wrap ms-auto"
                    style={{ maxWidth: '200px', cursor: 'pointer'}}
                  >
                    <div>
                      <h4 className="mb-0">
                        {" "}
                        <i className="fas fa-filter"></i> Filter
                      </h4>
                    </div>
                    <div className="ms-4">
                      <span>
                        <i
                          className={`fas fa-chevron-${showFilter ? "up" : "down"}`}
                        ></i>
                      </span>
                    </div>
                  </div>
                  </div>
                </div>
                {showFilter ? (
                  <div className="card border shadow-sm position-absolute right-0" style={{ 
                      minHeight: 'fit-content', 
                      width:'fit-content', 
                      right:'0',
                      top: '70%'
                    }}>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="form-group">
                            <h5 className="text-primary">
                              Jenjang <span className="text-danger">*</span>
                            </h5>
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              defaultValue={jenjang[0]}
                              isDisabled={isDisabled}
                              isLoading={isLoading}
                              isClearable={isClearable}
                              isRtl={isRtl}
                              isSearchable={isSearchable}
                              name="color"
                              options={jenjang}
                              onChange=""
                            />
                          </div>
                          <div className="form-group mt-3">
                            <h5 className="text-primary">
                              Mata Pelajaran <span className="text-danger">*</span>
                            </h5>
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              defaultValue={nullSel[0]}
                              isDisabled={isDisabled}
                              isLoading={isLoading}
                              isClearable={isClearable}
                              isRtl={isRtl}
                              isSearchable={isSearchable}
                              name="color"
                              options={nullSel}
                              onChange=""
                            />
                          </div>
                          <div className="form-group mt-3">
                            <h5 className="text-primary">
                              Kelas <span className="text-danger">*</span>
                            </h5>
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              defaultValue={nullSel[0]}
                              isDisabled={isDisabled}
                              isLoading={isLoading}
                              isClearable={isClearable}
                              isRtl={isRtl}
                              isSearchable={isSearchable}
                              name="color"
                              options={nullSel}
                              onChange=""
                            />
                          </div>
                        </div>

                        <div className="col-sm-12">
                          <div className="form-group">
                            <h5 className="text-primary">
                              Jurusan <span className="text-danger">*</span>
                            </h5>
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              defaultValue={nullSel[0]}
                              isDisabled={isDisabled}
                              isLoading={isLoading}
                              isClearable={isClearable}
                              isRtl={isRtl}
                              isSearchable={isSearchable}
                              name="color"
                              options={nullSel}
                              onChange=""
                            />
                          </div>
                          <div className="form-group mt-3">
                            <h5 className="text-primary">
                              Semester <span className="text-danger">*</span>
                            </h5>
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              defaultValue={nullSel[0]}
                              isDisabled={isDisabled}
                              isLoading={isLoading}
                              isClearable={isClearable}
                              isRtl={isRtl}
                              isSearchable={isSearchable}
                              name="color"
                              options={nullSel}
                              onChange=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="card-body py-2">
                {showStyle === "card" ? (
                  <CardPaginate data={konten} onBursa={loadData} total={total} perPage={perPage} type="paket" onPaging={handlePaging}></CardPaginate>
                ) : (
                  <TablePaginate data={konten} onBursa={loadData}  total={total} perPage={perPage} type="paket" onPaging={handlePaging} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterBursa />
    </>
  );
};

export default BursaPaketSoal;

import React, { useEffect, useRef, useState } from "react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useCart } from "../../../../services/CartContext";

const getColorJenisSoal = (type) => {
  switch (type) {
    case "PG":
      return "primary";
    case "MPG":
      return "primary";
    case "SA":
      return "warning";
    case "BS":
      return "success";
    case "UR":
      return "danger";
    case "PJ":
      return "primary";
    default:
      return "primary";
  }
};

const SideBarFilter = (props) => {
  const prevSoalValue = useRef();
  const {cart, dispatch } = useCart();

  useEffect(() => {

  }, [])


  return (
    <>
      <div className="card border shadow-sm w-100">
        <div className="card-body">
          <h5 className="card-title">Brands</h5>
          <div className="form-check">
              <input className="form-check-input" type="checkbox" id="mercedes"/>
              <label className="form-check-label" for="mercedes">Mercedes Benz</label>
          </div>
          <div className="form-check">
              <input className="form-check-input" type="checkbox" id="nissan"/>
              <label className="form-check-label" for="nissan">Nissan Altima</label>
          </div>
          <div className="form-check">
              <input className="form-check-input" type="checkbox" id="anotherBrand"/>
              <label className="form-check-label" for="anotherBrand">Another Brand</label>
          </div>
          
          <hr />

          <h5 className="card-title">Choose type</h5>
          <div className="form-check">
              <input className="form-check-input" type="radio" name="itemType" id="firstHand" value="firstHand"/>
              <label className="form-check-label" for="firstHand">First hand items</label>
          </div>
          <div className="form-check">
              <input className="form-check-input" type="radio" name="itemType" id="brandNew" value="brandNew"/>
              <label className="form-check-label" for="brandNew">Brand new items</label>
          </div>
          <div className="form-check">
              <input className="form-check-input" type="radio" name="itemType" id="someOther" value="someOther"/>
              <label className="form-check-label" for="someOther">Some other option</label>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideBarFilter;

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import axiosInstance from "../../../../services/AxiosInstance";
import Pagination from "../../Pagination";
import CardBursa from "../Card/CardBursa";

const CardPaginate = (props) => {
  const [show, setShow] = useState(false);

  const handleShow = (paketSoal) => {
    setShow(true)
    setSelectedPaketSoal(paketSoal)
  };
  const handleClose = () => setShow(false);

  const [selectedPaketSoal, setSelectedPaketSoal] = useState(null);
  const [price, setPrice] = useState(null);

  const handleSubmitBursa = async () => {
    var params = {
      price: price
    }
    var resPrice = await axiosInstance.put(`paket-soal/price/${selectedPaketSoal.id}`, params);
    var resBursa = await axiosInstance.put(`paket-soal/activate-bursa/${selectedPaketSoal.id}`, {});
    if (resPrice.data && resBursa.data) {
      props.onBursa(true)
      handleClose();
    }
  };

  const navigate = useNavigate();
  const handleLink = (e) => {
    navigate(e);
  };

  const onPaging = (page) => {
    props.onPaging(page);
  }

  return (
    <div className="col-xl-12">
      <div className="row">
        {props.data.map((data, index) => (
          <CardBursa key={index} data={data} type={props.type}/>
        ))}
      </div>
      <Pagination totalData={props.total} dataPerPage={props.perPage} onChange={onPaging} />

      <Modal dialogClassName="modal-lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Bursa Paket Soal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div className="form-group">
            <b className="mb-3">Sampul</b>
            <Dropzone
              inputContent="Drop files here to upload"
              className="mt-3"
              accept="image/*,audio/*,video/*"
              styles={{
                dropzone: {
                  minHeight: 200,
                  maxHeight: 250,
                  width: "100%",
                  backgroundColor: "#f2f4fa",
                  border: "1px dashed #DDDFE1",
                  overflow: "hidden",
                },
                inputLabel: {
                  color: "#7e7e7e",
                  fontSize: "16px",
                  fontWeight: "normal",
                  backgroundColor: "#f2f4fa",
                },
              }}
            />
          </div> */}
          <div className="form-group mt-4">
            <b className="mb-3">Harga</b>
            <input
              type="number"
              className="form-control mt-2"
              placeholder="Masukkan Harga Scoin"
              onChange={(e) => setPrice(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-outline-secondary" onClick={handleClose}>
            Close
          </button>
          <button className="btn btn-outline-primary" onClick={handleSubmitBursa}>
            Masukkan Bursa
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default CardPaginate;

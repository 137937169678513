import React, { useState } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import "./AddPaketAjar.css";

function Topik(props) {
  const [topics, setTopics] = useState([]);

  const addRow = () => {
    const newTopic = {
      id: topics.length + 1,
      name: "",
      selectedCards: [], // Inisialisasi selectedCards sebagai array kosong
    };
    setTopics([...topics, newTopic]);
  };

  const handleInputChange = (index, event) => {
    const newTopics = [...topics];
    newTopics[index].name = event.target.value;
    setTopics(newTopics);
  };

  const handleCheckboxChange = (topicId, cardNumber) => {
    setTopics((prevTopics) =>
      prevTopics.map((topic) => {
        if (topic.id === topicId) {
          // Tambahkan atau hapus kartu soal dari topik saat ini
          const isSelected = topic.selectedCards?.includes(cardNumber); // Tambahkan ? untuk menangani undefined
          const updatedCards = isSelected
            ? topic.selectedCards.filter((card) => card !== cardNumber) // Hapus kartu soal
            : [...(topic.selectedCards || []), cardNumber]; // Tambahkan kartu soal
          return { ...topic, selectedCards: updatedCards };
        } else {
          // Hapus kartu soal dari topik lain jika sedang dipilih
          const updatedCards = topic.selectedCards?.filter((card) => card !== cardNumber) || [];
          return { ...topic, selectedCards: updatedCards };
        }
      })
    );
  };

  const generateOutput = () => {
    const output = topics.map((topic, index) => ({
      no_urut: topic.id,
      code: `T${topic.id}`, // Generate kode topik
      name: topic.name,
      soal_ids: topic.selectedCards, // Ambil selectedCards sebagai soal_ids
    }));
    console.log(output); // Tampilkan hasil di konsol
    // alert(JSON.stringify(output, null, 2)); // Tampilkan hasil di popup
    props.onSaveButton(output);
  };


  const removeRow = (id) => {
    const updatedTopics = topics.filter((topic) => topic.id !== id);
    // Perbarui nomor urut topik setelah penghapusan
    setTopics(
      updatedTopics.map((topic, index) => ({
        ...topic,
        id: index + 1,
      }))
    );
  };
  return (
    <div className="">
      <table className="custom-table">
        <thead>
          <tr style={{ height: "130px" }}>
            {topics.length > 0 && <th></th> }
            <th style={{ width: "5px" }}>No</th>
            <th>Nama Topik</th>
            {Object.keys(props.arrSelectedSoal).map((key, keyIndex) =>
              props.arrSelectedSoal[key].map((item, itemIndex) => (
                <th key={`${keyIndex}-${itemIndex}`} className="rotated-header">
                  {item.kode_butir}
                </th>
              ))
            )}
          </tr>
        </thead>
        <tbody>
          {topics.map((topic, index) => (
            <tr key={topic.id}>
                <td style={{ textAlign: "center", padding: "0px", margin: "0px", width: "0px" }}>
                    <i
                        className="fas fa-trash text-danger cursor-pointer"
                        style={{ fontSize: "15px" }}
                        onClick={() => removeRow(topic.id)} // Panggil fungsi hapus
                    ></i>
                </td>
              <td className="text-center">{topic.id}</td>
              <td>
                <Form.Control
                  style={{ width: "250px" }}
                  type="text"
                  placeholder="Nama Topik"
                  value={topic.name}
                  onChange={(e) => handleInputChange(index, e)}
                />
              </td>
              {Object.keys(props.arrSelectedSoal).map((key, keyIndex) =>
                props.arrSelectedSoal[key].map((item, itemIndex) => (
                  <td key={`${keyIndex}-${itemIndex}`} style={{ textAlign: "center" }}>
                    <Form.Check
                      type="checkbox"
                      checked={topic.selectedCards?.includes(item.id)} // Gunakan ?. untuk mencegah error
                      onChange={() => handleCheckboxChange(topic.id, item.id)}
                    />
                  </td>
                ))
              )}
            </tr>
          ))}
        </tbody>
      </table>
      <Row>
        <Col md={6}>
          <Button variant="primary" onClick={addRow}>
            Tambahkan Topik
          </Button>
        </Col>
        <Col md={6} className="text-end">
          <Button variant="primary" onClick={generateOutput}>Simpan</Button>
        </Col>
      </Row>
    </div>
  );
}

export default Topik;

import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Dropdown, Modal, ModalBody, ModalHeader, ModalTitle } from "react-bootstrap";
import swal from "sweetalert";
import { nanoid } from "nanoid";
import { IMAGES } from "../Dashboard/Content";
import CardPaketSoal from "./ShowPage/Card";
import TablePaketSoal from "./ShowPage/Table";
import axiosInstance from "../../../services/AxiosInstance";
import { isAuthenticated } from "../../../store/selectors/AuthSelectors";
import { connect } from "react-redux";
import Swal from "sweetalert2";


function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();

    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

const PaketSoal = (props) => {
  const [contacts, setContacts] = useState([]);
  const [showStyle, setShowStyle] = useState("card");
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(16);
  useEffect(() => {
    loadData();
  }, [currentPage]);

  const loadData = async () => {
    try {
      const response = await axiosInstance.get(`paket-soal?per_page=${perPage}&page=${currentPage}`);
      var data = response.data.data;
      console.log("data:", data);
      var tmp = []
      for (var i = 0; i < data.length; i++) {
        var date = new Date(data[i].created_at)
        const formattedDate = `${String(date.getDate()).padStart(2, '0')}-${String(date.getMonth() + 1).padStart(2, '0')}-${date.getFullYear()}`;
        var type = getNonZeroKeys(data[i].score);
        var tmpType = []
        for (var j = 0; j < type.length; j++) {
          tmpType.push({ name: type[j], color: getColorByTypeSoal(type[j]) });
        }
        tmp.push({
          id: data[i].id,
          kode_paket: data[i].code,
          paket_soal_name: data[i].detail.judul,
          author: props.user.name,
          jenis_soal: tmpType,
          is_draft: data[i].is_draft,
          is_bursa: data[i].is_bursa,
          created_at: formattedDate,
          original: data[i]
        })
      }
      setContacts(tmp);
      setTotal(response.data.total);
    } catch (error) {
      console.log(error);
    }
  };

  const getNonZeroKeys = (score) => {
    return Object.keys(score).filter(key => {
        return Object.values(score[key]).every(value => value !== 0);
    });
  };

  const getColorByTypeSoal = (type) => {
    switch (type) {
      case "PG":
        return "primary";
      case "SA":
        return "warning";
      case "BS":
        return "success";
      case "UR":
        return "danger";
      default:
        return "primary";
    }
  };

  const onDelete = async (id) => {
    try {
      const response = await axiosInstance.delete(`paket-soal/${id}`);
      console.log(response);
      loadData();
    } catch (error) {
      console.log(error);
    }
  };

  const handlePaging = (page) => {
    console.log("page:", page);
    setCurrentPage(page);
  }




  // handle edit bobot soal
  const [showModal, setShowModal] = useState(false); // Kontrol modal
  const [selectedPaketSoal, setSelectedPaketSoal] = useState(null);
  
  const handleEditBobot = (item) => {
    console.log("item:", item);
    setSelectedPaketSoal(item.original);
    Object.keys(item.original.score).forEach((key) => {
      if (item.original.score[key].jumlah_soal !== 0) {
        handleActive(key);
      }
    })
    setFormValues(item.original.score);
    setShowModal(true);
  }

  const [activeTabs, setActiveTabs] = useState([]); // Menggunakan array untuk menyimpan tab aktif
  const [formValues, setFormValues] = useState({
    PG: { jumlah_soal: 0, bobot: 0, point_benar: 0, point_salah: 0, point_kosong: 0 },
    MPG: { jumlah_soal: 0, bobot: 0, point_benar: 0, point_salah: 0, point_kosong: 0 },
    SA: { jumlah_soal: 0, bobot: 0, point_benar: 0, point_salah: 0, point_kosong: 0 },
    UR: { jumlah_soal: 0, bobot: 0, point_benar: 0, point_salah: 0, point_kosong: 0 },
    PJ: { jumlah_soal: 0, bobot: 0, point_benar: 0, point_salah: 0, point_kosong: 0 },
    BS: { jumlah_soal: 0, bobot: 0, point_benar: 0, point_salah: 0, point_kosong: 0 },
  });

  const getTypeName = (type) => {
    if (type == "PG") {
      return "Pilihan Ganda";
    } else if (type == "MPG") {
      return "Multiple Pilihan Ganda";
    } else if (type == "BS") {
      return "Benar Salah";
    } else if (type == "UR") {
      return "Uraian";
    } else if (type == "PJ") {
      return "Penjodohan";
    } else if (type == "SA") {
      return "Sebab Akibat";
    }
  };

  const handleActive = (tab) => {
    setActiveTabs((prevActiveTabs) =>
      prevActiveTabs.includes(tab)
        ? prevActiveTabs.filter((t) => t !== tab) // Jika sudah aktif, nonaktifkan
        : [...prevActiveTabs, tab] // Jika belum aktif, tambahkan ke tab aktif
    );
  };

  const handleInput = (e, tab, key) => {
    const { value } = e.target;
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [tab]: {
        ...prevFormValues[tab],
        [key]: value,
      },
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    let isValid = true;

    console.log("validate form tab :", activeTabs);
    activeTabs.forEach((tab) => {
      newErrors[tab] = {};
      var formValue = formValuesRef.current;
      console.log("tengah validate form :", tab, formValue[tab]);
      Object.keys(formValue[tab]).forEach((key) => {
        console.log("validate form :", key, formValue[tab], formValue[tab][key]);
        if(!nullableField.includes(key) && formValue[tab][key] == 0){
          isValid = false;
          newErrors[tab][key] = `${key} is required`;
        }
      });
    });

    setErrors(newErrors);
    return {
      isValid: isValid,
      errors: newErrors,
    };
  };

  const [errors, setErrors] = useState({
    PG: {},
    MPG: {},
    SA: {},
    UR: {},
    PJ: {},
    BS: {},
  });

  useEffect(() => {
    console.log("activeTabs:", activeTabs);
    console.log("formValues:", formValues);
    formValuesRef.current = formValues;
  }, [activeTabs, formValues, errors]);
  
  const formValuesRef = useRef(formValues);
  
  const nullableField = ['point_salah', 'point_kosong'];

  const handleSaveBobot = async () => {
    try {
      var validate = validateForm();
      if (!validate.isValid) {
        var text = '';
        for (const [key, value] of Object.entries(validate.errors)) {
          console.log(key, value, typeof value, typeof value === 'object' ? Object.keys(value).length !== 0 : 'bukan object');
          if (typeof value === 'object') {
            if (Object.keys(value).length !== 0) {
              text += "<b>" + getTypeName(key) + "</b><br/>";
              for (const [k, v] of Object.entries(value)) {
                console.log(k, v);
                text += v + "<br/>";
              }
            }
          }else{
            text += value + "<br/>";
          }
        }
        Swal.fire({
          title: "Oops !",
          html: text,
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Oke !",
        });
        return;
      }

      // if
      // const result = {};
      console.log("selectedPaketSoal.detail.soal_id_list:", selectedPaketSoal.detail.soal_id_list);
      console.log(typeof selectedPaketSoal.detail.soal_id_list == 'object')
      console.log(typeof selectedPaketSoal.detail.soal_id_list);
      console.log((selectedPaketSoal.detail.soal_id_list instanceof Array))
      // console.log(selectedPaketSoal.detail.soal_id_list.isArray());
      // for (let i = 0; i < selectedPaketSoal.detail.soal_id_list.length; i++) {
      //   result[i + 1] = selectedPaketSoal.detail.soal_id_list[i];
      // }
      var params = {
        "type": selectedPaketSoal.type,
        "is_draft": false,
        "detail": {
            "judul": selectedPaketSoal.detail.judul,
            "jenjang": selectedPaketSoal.detail.jenjang,
            "kurikulum": selectedPaketSoal.detail.kurikulum ?? null,
            "kelas": selectedPaketSoal.detail.kelas,
            "jurusan": selectedPaketSoal.detail.jurusan,
            "semester": selectedPaketSoal.detail.semester,
            "kelompok_mata_pelajaran": selectedPaketSoal.detail.kelompok_mata_pelajaran,
            "jenis_kelompok_mata_pelajaran": selectedPaketSoal.detail.jenis_kelompok_mata_pelajaran,
            "mata_pelajaran": selectedPaketSoal.detail.mata_pelajaran,
            "durasi": selectedPaketSoal.detail.durasi,
            "indikator": selectedPaketSoal.detail.indikator,
            "image": null
        },
        "score": formValues,
        "soal_id": (selectedPaketSoal.detail.soal_id_list instanceof Array) ? selectedPaketSoal.detail.soal_id_list.reduce((acc, id, index) => {
          acc[index + 1] = id;
          return acc;
        }, {}) : selectedPaketSoal.detail.soal_id_list
      }
      console.log("params:", params);
      const response = await axiosInstance.put(`paket-soal/${selectedPaketSoal.id}`, params);
      console.log(response);
      setShowModal(false);
      loadData();
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Bobot berhasil disimpan',
      })
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      })
    }
  }

  const handleOnHideModal = () => {
    console.log("handleOnHideModal");
    setSelectedPaketSoal(null);
    setActiveTabs([]);
    setFormValues({})
    setShowModal(false);
  }
 
  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="page-title flex-wrap">
          <div className="input-group search-area mb-md-0 mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Search here..."
            />
            <span className="input-group-text">
              <Link to={"#"}>
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.5605 15.4395L13.7527 11.6317C14.5395 10.446 15 9.02625 15 7.5C15 3.3645 11.6355 0 7.5 0C3.3645 0 0 3.3645 0 7.5C0 11.6355 3.3645 15 7.5 15C9.02625 15 10.446 14.5395 11.6317 13.7527L15.4395 17.5605C16.0245 18.1462 16.9755 18.1462 17.5605 17.5605C18.1462 16.9747 18.1462 16.0252 17.5605 15.4395V15.4395ZM2.25 7.5C2.25 4.605 4.605 2.25 7.5 2.25C10.395 2.25 12.75 4.605 12.75 7.5C12.75 10.395 10.395 12.75 7.5 12.75C4.605 12.75 2.25 10.395 2.25 7.5V7.5Z"
                    fill="#01A3FF"
                  />
                </svg>
              </Link>
            </span>
          </div>
          <div className="d-flex">
            <ul
              as="ul"
              className="nav nav-tabs dzm-tabs me-2"
              id="myTab"
              role="tablist"
            >
              <li as="li" className="nav-item" role="presentation">
                <button
                  className={`nav-link ${showStyle == "card" ? "active" : ""}`}
                  type="button"
                  onClick={() => setShowStyle("card")}
                >
                  <i className="fas fa-address-card"></i>
                </button>
              </li>
              <li as="li" className="nav-item">
                <button
                  className={`nav-link ${showStyle == "list" ? "active" : ""}`}
                  type="button"
                  onClick={() => setShowStyle("list")}
                >
                  <i className="fas fa-list"></i>
                </button>
              </li>
            </ul>
            <Link
              type="button"
              className="btn btn-primary"
              to={"/paket-soal/action/add"}
            >
              + Buat Paket Soal
            </Link>
          </div>
        </div>
      </div>
      {/* {contacts} */}
      {showStyle == "card" ? (
        <CardPaketSoal contacts={contacts} onBobot={handleEditBobot}  onBursa={loadData} onDelete={onDelete}  total={total} perPage={perPage} onPaging={handlePaging} />
      ) : (
        <TablePaketSoal contacts={contacts} onBobot={handleEditBobot} onBursa={loadData} onDelete={onDelete}  total={total} perPage={perPage} onPaging={handlePaging} />
      )}

      <Modal show={showModal} size="xl" >
        <ModalHeader closeButton onHide={handleOnHideModal}>
          <ModalTitle>Ubah Bobot Paket Soal</ModalTitle>
        </ModalHeader>
        <ModalBody>
        <div className="col-sm-12 d-flex justify-content-between flex-wrap mt-3">
          <ul className="nav nav-tabs dzm-tabs" id="myTab" role="tablist">
            {["PG", "MPG", "SA", "UR", "PJ", "BS"].map((tab, idx) => (
              <li key={idx} className="nav-item">
                <button
                  onClick={() => handleActive(tab)}
                  className={`nav-link m-1 btn-outline-primary btn-sm ${activeTabs.includes(tab) ? "active" : null}`}
                  type="button"
                >
                  {getTypeName(tab)}
                </button>
              </li>
            ))}
          </ul>
        </div>
        <div className="col-sm-12 mt-2">
          {activeTabs.map((tab, idx) => (
            <div key={idx} className="row mt-3">
              <div className="col">
                <div className="form-group">
                  <h5 className={`text-primary text-bold`}>
                    Jumlah Soal ({tab.toUpperCase()})
                  </h5>
                  <input
                    type="text"
                    className={`form-control border-primary`}
                    value={formValues[tab].jumlah_soal}
                    onChange={(e) => handleInput(e, tab, "jumlah_soal")}
                  />
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <h5 className={`text-primary text-bold`}>
                    Bobot ({tab.toUpperCase()})
                  </h5>
                  <input
                    type="text"
                    className={`form-control border-primary`}
                    value={formValues[tab].bobot}
                    onChange={(e) => handleInput(e, tab, "bobot")}
                  />
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <h5 className={`text-primary text-bold`}>
                    Poin Benar ({tab.toUpperCase()})
                  </h5>
                  <input
                    type="text"
                    className={`form-control border-primary`}
                    value={formValues[tab].point_benar}
                    onChange={(e) => handleInput(e, tab, "point_benar")}
                  />
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <h5 className={`text-primary text-bold`}>
                    Poin Salah ({tab.toUpperCase()})
                  </h5>
                  <input
                    type="text"
                    className={`form-control border-primary`}
                    value={formValues[tab].point_salah}
                    onChange={(e) => handleInput(e, tab, "point_salah")}
                  />
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <h5 className={`text-primary text-bold`}>
                    Poin Tidak Jawab ({tab.toUpperCase()})
                  </h5>
                  <input
                    type="text"
                    className={`form-control border-primary`}
                    value={formValues[tab].point_kosong}
                    disabled={true}
                    onChange={(e) => handleInput(e, tab, "point_kosong")}
                  />
                </div>
              </div>
            </div>
          ))}
          <div className="row mt-5">
            <div className="col-12 text-end">
              <button
                type="button"
                onClick={handleSaveBobot}
                className="btn btn-primary mt-3"
              >
                Simpan
              </button>
            </div>
          </div>

        </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  console.log('mapStateToProps butirsoal', state);
  return {
    isAuthenticated: isAuthenticated(state),
    user: state.auth.auth,
  };
};

export default withRouter(connect(mapStateToProps)(PaketSoal));

import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import Swal from "sweetalert2";
import axiosInstance from "../../../services/AxiosInstance";
import { error } from "ajv/dist/vocabularies/applicator/dependencies";
import { Navigate, useNavigate } from "react-router-dom";
import Pagination from "../Pagination";

import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import "./AddPaketAjar.css";
import Topik from "./Topik";
import axiosBahanAjar from "../../../services/axiosBahanAjar";
import ModalSearch from "./ModalBahanAjar";

const ItemType = "row";

const AddPaketAjar = () => {
  const navigate = useNavigate();
  const [isClearable, setIsClearable] = useState(false);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRtl, setIsRtl] = useState(false);
  const [step, setStep] = useState("first");


  const [nullSel, setNullSel] = useState([
    { value: "-", label: "Select Option" },
  ]);
  const [showFilter, setShowFilter] = useState(false);

  const [elemenSoal, setElemenSoal] = useState([]);

  const [kelasOpt, setKelasOpt] = useState([]);
  const [selectedKelas, setSelectedKelas] = useState(null);

  const [active, setActive] = useState("pilihan-ganda");
  const [showBtnAdd, setShowBtnAdd] = useState(true);

  const [jurusanOpt, setJurusanOpt] = useState([]);
  const [selectedJurusan, setSelectedJurusan] = useState(null);

  const [typeOpt, setTypeOpt] = useState([
    { value: "test-online/usbk", label: "Test Online/USBK" },
    { value: "tryout", label: "Tryout" },
  ])

  const [selectedType, setSelectedType] = useState(null);

  const [jenjangOpt, setJenjangOpt] = useState([
    { value: "PAUD", label: "PAUD" },
    { value: "SD/MI", label: "SD/MI" },
    { value: "SMP/MTs", label: "SMP/MTs" },
    { value: "SMA/MA", label: "SMA/MA" },
    { value: "SMK/MAK", label: "SMK/MAK" },
  ]);
  const [selectedJenjang, setselectedJenjang] = useState(null);

  const [semesterOpt, setSemesterOpt] = useState([
    { value: "ganjil", label: "Ganjil" },
    { value: "genap", label: "Genap" },
  ])
  const [selectedSemester, setSelectedSemester] = useState(null);

  const [selectedMataPelajaran, setSelectedMataPelajaran] = useState(null);

  const [duration, setDuration] = useState(0);
  const [title, setTitle] = useState(null);
  const [indicator, setIndicator] = useState(null);
  const [arrMataPelajaran, setArrMataPelajaran] = useState([]);

  const [arrButirSoal, setArrButirSoal] = useState([]);

  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(5);

  const [searchKeyword, setSearchKeyword] = useState("");
  const [excludeSoalId, setExcludeSoalId] = useState([]);
  useEffect(() => {
    // loadMataPelajaran();
    loadKurikulum();
    loadJenjang();
  }, [])
  useEffect(() => {
    console.log("load soal :", currentPage);
    console.log("load excludeSoalId :", excludeSoalId);
    getListSoal();
  }, [currentPage, selectedKelas, selectedMataPelajaran, excludeSoalId]);
  const getListSoal = async () => {
    // console.log("getListSoal:", selectedKelas);
    // console.log("getListSoal:", selectedMataPelajaran);
    const res = await axiosBahanAjar.get("bahan-ajar?page=" + currentPage + "&per_page=" + perPage + "&kelas=" + (selectedKelas ?? "") + "&mata_pelajaran=" + (selectedMataPelajaran?.label ?? "") + "&topic=" + searchKeyword + "&exclude=" + excludeSoalId.join(","));

    if (res.data) {
      var soal = res.data.data;
      console.log("soal:", soal);
      var tmp = []
      for (var i = 0; i < soal.length; i++) {
        // tmp.push({
        //   id: soal[i].id,
        //   judul: soal[i].detail.materi,
        //   kode_butir: soal[i].code,
        //   tipe_soal: getTypeName(soal[i].type),
        //   kelas: soal[i].detail.kelas,
        //   type_style: typeByJenisSoal(soal[i].type),
        //   groupId: soal[i].detail.group
        // },);
      }

      setArrButirSoal(soal.items);
      setTotal(soal.meta.totalItems);
      // setCurrentPage(res.data.current_page);
    }
  };

  const typeByJenisSoal = (type) => {
    switch (type) {
      case "PG":
        return "pilihan-ganda";
      case "SA":
        return "sebab-akibat";
      case "BS":
        return "benar-salah";
      case "UR":
        return "uraian";
      case "PJ":
        return "penjodohan";
      case "MPG":
        return "pilihan-ganda";
      default:
        return "PG";
    }
  };

  const [mataPelajaranOpt, setMataPelajaranOpt] = useState([]);
  const loadMataPelajaran = async (jenisKelompok) => {
    var response = await axiosInstance.get("select/mata-pelajaran?jenis_kelompok_mata_pelajaran_id="+ jenisKelompok.value + "&kurikulum=" + selectedKurikulum.value);
    if (response.data) {
      var tmp = [];
      var data = response.data.data;
      for (var i = 0; i < data.length; i++) {
        tmp.push({ value: data[i].id, label: data[i].label });
      }
      setMataPelajaranOpt(tmp);
    }
  };

  const handleSelectMataPelajaran = (e) => {
    if (e) {
      setSelectedMataPelajaran(e);
    }
  };

  const onPaging = (page) => {
    // getListSoal();
    setCurrentPage(page);
  }

  const [arrSelectedSoal, setArrSelectedSoal] = useState([]);


  

  const [errors, setErrors] = useState({
    
  });

  const validateForm = () => {
    const newErrors = {};
    let isValid = true;
    console.log("Title :", title);

    // if(selectedType == null){
    //   isValid = false;
    //   newErrors['platform'] = "Platform is required";
    // }
    if(title == null){
      isValid = false;
      newErrors['title'] = "Judul is required";
    }
    if(selectedJenjang == null){
      isValid = false;
      newErrors['jenjang'] = "Jenjang is required";
    }

    if(selectedMataPelajaran == null){
      isValid = false;
      newErrors['mapel'] = "Mata Pelajaran is required";
    }

    if(selectedKelas == null){
      isValid = false;
      newErrors['kelas'] = "Kelas is required";
    }

    if(selectedJurusan == null){
      isValid = false;
      newErrors['jurusan'] = "Jurusan is required";
    }

    if(selectedSemester == null){
      isValid = false;
      newErrors['semester'] = "Semester is required";
    }

    // if(duration == 0){
    //   isValid = false;
    //   newErrors['duration'] = "Duration is required";
    // }

    if(indicator == null){
      isValid = false;
      newErrors['indicator'] = "Indikator is required";
    }

    setErrors(newErrors);
    return {
      isValid: isValid,
      errors: newErrors,
    };
  };

  const getTypeName = (type) => {
    if (type == "PG") {
      return "Pilihan Ganda";
    } else if (type == "MPG") {
      return "Multiple Pilihan Ganda";
    } else if (type == "BS") {
      return "Benar Salah";
    } else if (type == "UR") {
      return "Uraian";
    } else if (type == "PJ") {
      return "Penjodohan";
    } else if (type == "SA") {
      return "Sebab Akibat";
    }
  };

  const handleSelectJenjang = (e) => {
    if (e) {
      if(selectedKurikulum == null){
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Pilih Kurikulum terlebih dahulu",
        });
        return;
      }
      setSelectedKelas(null);
      // if (e.value == "PAUD") {
      //   setKelasOpt([{ value: "0", label: "0" }]);
      // } else if (e.value == "SD/MI") {
      //   setKelasOpt([
      //     { value: "I", label: "I" },
      //     { value: "II", label: "II" },
      //     { value: "III", label: "III" },
      //     { value: "IV", label: "IV" },
      //     { value: "V", label: "V" },
      //     { value: "VI", label: "VI" },
      //   ]);
      // } else if (e.value == "SMP/MTs") {
      //   setKelasOpt([
      //     { value: "VII", label: "VII" },
      //     { value: "VIII", label: "VIII" },
      //     { value: "IX", label: "IX" },
      //   ]);
      // } else if (e.value == "SMA/MA") {
      //   setKelasOpt([
      //     { value: "X", label: "X" },
      //     { value: "XI", label: "XI" },
      //     { value: "XII", label: "XII" },
      //   ]);
      // } else if (e.value == "SMK/MAK") {
      //   setKelasOpt([
      //     { value: "X", label: "X" },
      //     { value: "XI", label: "XI" },
      //     { value: "XII", label: "XII" },
      //   ]);
      // }
      setselectedJenjang(e.value);
      getJurusan(e.value);
      getKelas(e.value);
      loadKelompokMataPelajaran(e);
    }
  };

  const [kurikulum, setKurikulum] = useState([]);
  const [selectedKurikulum, setSelectedKurikulum] = useState(null);

  const loadKurikulum = async () => {
    var response = await axiosInstance.get("/select/kurikulum");
    if (response.data) {
      var tmp = [];
      var data = response.data.data;
      for (var i = 0; i < data.length; i++) {
        tmp.push({ value: data[i].value, label: data[i].label });
      }
      setKurikulum(tmp);
    }
  };

  const handleSelectKurikulum = (e) => {
    if (e) {
      setSelectedKurikulum(e);
    }
  };


  const loadJenjang = async () => {
    var response = await axiosInstance.get("select/jenjang");
    if (response.data) {
      var tmp = [];
      var data = response.data.data;
      console.log("loadJenjang data:", data);
      for (var i = 0; i < data.length; i++) {
        tmp.push({id: data[i].id, value: data[i].jenjang, label: data[i].jenjang });
      }
      setJenjangOpt(tmp);
    }
  }

  const [kelompokMataPelajaranOpt, setKelompokMataPelajaranOpt] = useState([]);
  const [selectedKelompokMataPelajaran, setSelectedKelompokMataPelajaran] = useState(null);

  const loadKelompokMataPelajaran = async (jenjang) => {
    console.log("loadKelompokMataPelajaran : {}", jenjang);
    var response = await axiosInstance.get("select/kelompok-mata-pelajaran?jenjang_id="+ jenjang.id + "&kurikulum=" + selectedKurikulum.value);
    if (response.data) {
      var tmp = [];
      var data = response.data.data;
      console.log("loadKelompokMataPelajaran data:", data);
      for (var i = 0; i < data.length; i++) {
        tmp.push({ value: data[i].id, label: data[i].label });
      }
      setKelompokMataPelajaranOpt(tmp);
    }
  };

  const handleSelectKelompokMataPelajaran = (e) => {
    if (e) {
      setSelectedKelompokMataPelajaran(e);
      loadJenisKelompokMataPelajaran(e);
    }
  };

  const [jenisKelompokMataPelajaranOpt, setJenisKelompokMataPelajaranOpt] = useState([]);
  const [selectedJenisKelompokMataPelajaran, setSelectedJenisKelompokMataPelajaran] = useState(null);

  const loadJenisKelompokMataPelajaran = async (kelompok) => {
    console.log("loadJenisKelompokMataPelajaran : {}", kelompok);
    var response = await axiosInstance.get("select/jenis-kelompok-mata-pelajaran?kelompok_mata_pelajaran_id="+ kelompok.value + "&kurikulum=" + selectedKurikulum.value);
    if (response.data) {
      var tmp = [];
      var data = response.data.data;
      console.log("loadJenisKelompokMataPelajaran data:", data);
      for (var i = 0; i < data.length; i++) {
        tmp.push({ value: data[i].id, label: data[i].label });
      }
      setJenisKelompokMataPelajaranOpt(tmp);
    }
  }

  const handleSelectJenisKelompokMataPelajaran = (e) => {
    if (e) {
      setSelectedJenisKelompokMataPelajaran(e);
      loadMataPelajaran(e);
    }
  };
  
  const [disabledSelectSoal, setDisabledSelectSoal] = useState(false);
  const handleSelectedSoal = async (e) => {
    setDisabledSelectSoal(true);
    console.log("handleSelectedSoal", e);
    console.log("handleSelectedSoal", e);
    console.log("arrSelectedSoal", arrSelectedSoal, JSON.stringify(arrSelectedSoal));
    console.log("excludeSoalId", excludeSoalId, JSON.stringify(excludeSoalId));
    // // console.log("undefined , ", arrSelectedSoal[e.type_style] == undefined)
    //   // setArrSelectedSoal([...arrSelectedSoal, e]);
    //   // if (arrSelectedSoal == undefined) {
    //   //   setArrSelectedSoal({ ...arrSelectedSoal, [e.type_style]: [e] });
    //   //   console.log("arrSelectedSoal", arrSelectedSoal);

    //   //   return;
    //   // }
    //   const exists = arrSelectedSoal.some((item) => item.id === e.id);

    //   console.log("exists", exists);

    //   if (exists) {
    //     Swal.fire({
    //       icon: "error",
    //       title: "Oops...",
    //       text: "Soal sudah dipilih",
    //     })
    //   } else {
    //     setArrSelectedSoal((prevState) => [...prevState, e]);
    //     setExcludeSoalId([...excludeSoalId, e.id]);

    //     setTimeout(() => {
    //       setDisabledSelectSoal(false);
    //       console.log("disabledSelectSoal", disabledSelectSoal);
    //     }, 100)
    //     // setArrSelectedSoal({
    //     //   ...arrSelectedSoal[e.type_style],
    //     //   [e.type_style]: tmp
    //     // });

    //     // console.log("arrSelectedSoal", arrSelectedSoal);
    //     // setArrSelectedSoal([e.tipe_soal]: [...arrSelectedSoal[e.tipe_soal], e]);
    //   }

    // setTimeout(() => {
    //   setDisabledSelectSoal(false);
    //   console.log("disabledSelectSoal", disabledSelectSoal);
    // }, 100)
    
    
  }

  const parseAllValuesToFloat = (obj) => {
    const result = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        result[key] = Object.fromEntries(
          Object.entries(obj[key]).map(([k, v]) => [k, parseFloat(v)])
        );
      }
    }
    return result;
  };

  const handleNext = () => {
    var validate = validateForm();
    console.log("validate:", validate);
    if (validate.isValid) {
      
      setStep("next");
    } else {
      console.log("Form validation failed.");
      var text = '';
      for (const [key, value] of Object.entries(validate.errors)) {
        console.log(key, value, typeof value, typeof value === 'object' ? Object.keys(value).length !== 0 : 'bukan object');
        if (typeof value === 'object') {
          if (Object.keys(value).length !== 0) {
            text += "<b>" + getTypeName(key) + "</b><br/>";
            for (const [k, v] of Object.entries(value)) {
              console.log(k, v);
              text += v + "<br/>";
            }
          }
        }else{
          text += value + "<br/>";
        }
      }
      Swal.fire({
        title: "Oops !",
        html: text,
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oke !",
      });
    }
  };

  const handleFormChange = (value, type) => {
    if (type == "type") {
      setSelectedType(value);
    }
    if (type == "title") {
      setTitle(value);
    }

    if (type == "matapelajaran") {
      setSelectedMataPelajaran(value);
    }

    if (type == "kelas") {
      setSelectedKelas(value);
    }

    if (type == "jurusan") {
      setSelectedJurusan(value);
    }

    if (type == "semester") {
      setSelectedSemester(value);
    }

    if (type == "duration") {
      setDuration(value);
    }

    if (type == "indicator") {
      setIndicator(value);
    }
  };

  function capitalizeWords(text) {
    return text
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  const getJurusan = async (jenjang) => {
    var response = await axiosInstance.get("/select/jurusan?jenjang=" + jenjang);
    if (response.data) {
      var tmp = [];
      var data = response.data.data;
      for (var i = 0; i < data.length; i++) {
        tmp.push({ value: data[i].value, label: data[i].text });
      }
      setJurusanOpt(tmp);
    }
  };

  const getKelas = async (jenjang) => {
    var response = await axiosInstance.get("/select/kelas?jenjang=" + jenjang);
    if (response.data) {
      var tmp = [];
      var data = response.data.data;
      for (var i = 0; i < data.length; i++) {
        tmp.push({ value: data[i].value, label: data[i].text });
      }
      setKelasOpt(tmp);
    }
  };

  const handleSave = async () => {
    const hasItems = categories.some((category) => category.items.length > 0);
    if (!hasItems) {
      Swal.fire({
        title: "Oops !",
        text: "Pilih minimal 1 soal",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oke !",
      });
      return;
    }

console.log("jenjang:", selectedJenjang);
console.log("id_jenjang:", jenjangOpt.find((jenjang) => jenjang.value == selectedJenjang).id);
console.log("kelas:", selectedKelas);
console.log("id_kelas:", kelasOpt.find((kelas) => kelas.label == selectedKelas).value);
console.log("jurusan:", selectedJurusan);
console.log("id_jurusan:", jurusanOpt.find((jurusan) => jurusan.label == selectedJurusan).value);
console.log("kurikulum:", selectedKurikulum);
console.log("kelompok_mata_pelajaran:", selectedKelompokMataPelajaran);
console.log("jenis_kelompok_mata_pelajaran:", selectedJenisKelompokMataPelajaran);
console.log("mata_pelajaran:", selectedMataPelajaran);
    // return;

    var params = {
      "topic": title,
      "price": 0,
      "description": indicator,
      "detail": {
          "id_jenjang": jenjangOpt.find((jenjang) => jenjang.value == selectedJenjang).id,
          "jenjang": selectedJenjang,
          "kelas": selectedKelas,
          "jurusan": selectedJurusan,
          "id_kurikulum": selectedKurikulum.value,
          "kurikulum": selectedKurikulum.label,
          "id_kelompok_mata_pelajaran": selectedKelompokMataPelajaran.value,
          "kelompok_mata_pelajaran": selectedKelompokMataPelajaran.label,
          "id_jenis_kelompok_mata_pelajaran": selectedJenisKelompokMataPelajaran.value,
          "jenis_kelompok_mata_pelajaran": selectedJenisKelompokMataPelajaran.label,
          "id_mata_pelajaran": selectedMataPelajaran.value,
          "mata_pelajaran": selectedMataPelajaran.label,
      },
      "bahan_ajar_id_list": categories,
    };

    console.log("params", params);
    console.log("params", JSON.stringify(params));

    // return;

    var res = await axiosBahanAjar.post("paket-ajar", params);
    if (res.data.success) {
      Swal.fire({
        title: "Success !",
        text: "Paket Soal Berhasil",
        icon: "success",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oke !",
      })
      console.log("res:", res.data);
      navigate("/paket-ajar/list");
    }else{
      Swal.fire({
        title: "Oops !",
        text: res.data.message,
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oke !",
      })
    }
  }

  

  const handleRemove = (index, soal) => {
    setArrSelectedSoal((prevItems) => prevItems.filter(item => item !== soal));
  }

  const moveRow = (key, dragIndex, hoverIndex) => {
    setArrSelectedSoal((prevState) => {
        const updatedSoal = [...prevState]; // Salin array sebelumnya
        const [movedItem] = updatedSoal.splice(dragIndex, 1); // Hapus item dari dragIndex
        updatedSoal.splice(hoverIndex, 0, movedItem); // Sisipkan item ke hoverIndex

        return updatedSoal; // Kembalikan array yang diperbarui
    });
  };

  const DraggableRow = ({ item, index, moveRow }) => {
    const ref = React.useRef(null);
  
    const [, drop] = useDrop({
      accept: "row",
      hover: (draggedItem) => {
        if (draggedItem.index !== index) {
          moveRow(draggedItem.index, index);
          draggedItem.index = index;
        }
      },
    });
  
    const [{ isDragging }, drag] = useDrag({
      type: "row",
      item: { index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });
  
    drag(drop(ref));
  
    return (
      <tr
        ref={ref}
        style={{
          opacity: isDragging ? 0.5 : 1,
          cursor: "move",
        }}
      >
        <td>{index + 1}</td>
        <td>{item.id_bahan_ajar}</td>
        <td>{item.topic}</td>
        <td>{item.media}</td>
        <td>
          <button className="btn btn-danger btn-sm" onClick={() => deleteRow(item.id)}>{'Hapus'}</button>
        </td>
      </tr>
    );
  };

  const formatText = (text) => {
    return text
      .split('-')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const [order, setOrder] = useState();

  useEffect(() => {
    // setOrder(Object.keys(arrSelectedSoal));
    setDisabledSelectSoal(false);
    console.log("arrSelectedSoal:", arrSelectedSoal);
  }, [arrSelectedSoal]);
  // Fungsi untuk memindahkan kategori
  const moveCategory = (dragIndex, hoverIndex) => {
    setOrder((prevOrder) => {
      const updatedOrder = [...prevOrder];
      const [movedKey] = updatedOrder.splice(dragIndex, 1);
      updatedOrder.splice(hoverIndex, 0, movedKey);
      return updatedOrder;
    });
  // };

  return (
    <DndProvider backend={HTML5Backend}>
      <div>
        {order.map((key, index) => (
          <DraggableCategory
            key={key}
            categoryKey={key}
            category={arrSelectedSoal[key]}
            index={index}
            moveCategory={moveCategory}
          />
        ))}
      </div>
    </DndProvider>
  );
};

const deleteRow = (itemId) => {
  setArrSelectedSoal((prevState) => prevState.filter((item) => item.id !== itemId));
  setExcludeSoalId((prevState) => prevState.filter((id) => id !== itemId));
};

const DraggableCategory = ({category, index, moveCategory, deleteRow }) => {
  const ref = React.useRef(null);

  // Drop target
  const [, drop] = useDrop({
    accept: "category",
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveCategory(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  // Drag source
  const [{ isDragging }, drag] = useDrag({
    type: "category",
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <div
      ref={ref}
      style={{
        opacity: isDragging ? 0.5 : 1,
        marginBottom: "20px",
        border: "1px solid #ccc",
        padding: "10px",
        backgroundColor: "#f9f9f9",
        cursor: "move",
      }}
    >
      <table className="table table-responsive table-bordered w-100">
        <thead>
          <tr>
            <th>No</th>
            {/* <th>Bentuk Soal</th> */}
            <th>Kode Soal</th>
            <th>Judul Soal</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {category.map((item, idx) => (
            <DraggableRow
              key={idx}
              item={item}
              index={idx}
              moveRow={moveRow}
            />
            // <tr key={item.id}>
            //   <td>{idx + 1}</td>
            //   {/* <td>{item.bentukSoal}</td> */}
            //   <td>{item.kode_butir}</td>
            //   <td>{item.judul}</td>
            //   <td>
            //     <button
            //       className="btn btn-danger btn-sm"
            //       onClick={() => deleteRow(categoryKey, item.id)}
            //     >
            //       Hapus
            //     </button>
            //   </td>
            // </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
  const handleCariButir = (e) => {
    console.log("handleCariButir");
    if (currentPage === 1) {
      getListSoal();  
    }
    setCurrentPage(1);
    
  };

  const [arrTopik, setArrTopik] = useState([]);
  

  const [categoryField, setCategoryField] = useState("");
  const [categories, setCategories] = useState([
  ]);

  const addCategory = () => {
    const newCategory = {
      id: Date.now(),
      name: categoryField,
      items: [],
    };
    setCategories([...categories, newCategory]);

    setCategoryField("");
  };

  const addItem = (item) => {
    console.log("categoryId:", item);
    
    const newItem = {
      // uuid: Date.now(),
      ...item
    };
    setCategories((prevCategories) => {
      const isItemExists = prevCategories.some((category) =>
        category.items.some((i) => i.id === newItem.id)
      );
  
      if (isItemExists) {
        Swal.fire({
          icon: "warning",
          title: "Item Sudah Ada",
          text: "Item ini sudah ada di salah satu kategori."
        });
        return prevCategories;
      }

      setExcludeSoalId([...excludeSoalId, item.id]);

      setTimeout(() => {
        setDisabledSelectSoal(false);
      }, 100)
  
      return prevCategories.map((category) =>
        category.id === selectedCategoryId
          ? { ...category, items: [...category.items, newItem] }
          : category
      );
    });
  };

  const moveItem = (draggedItem, targetCategoryId) => {
    setCategories((prevCategories) => {
      const updatedCategories = prevCategories.map((category) => {
        if (category.id === targetCategoryId) {
          return {
            ...category,
            items: [...category.items, draggedItem],
          };
        }
        return {
          ...category,
          items: category.items.filter((item) => item.id !== draggedItem.id),
        };
      });
      return updatedCategories;
    });
  };

  const DraggableItem = ({ item, moveItem }) => {
    const [, drag] = useDrag(() => ({
      type: ItemType,
      item,
    }));
  
    return (
      <tr
        ref={drag}
        className="p-2 mb-2 bg-blue-100 rounded-lg cursor-pointer shadow-sm"
      >
        {/* {JSON.stringify(item)} */}
        <td>{item.id}</td>
        <td>{item.id_bahan_ajar}</td>
        <td>{item.topic}</td>
        <td>{item.media}</td>
        <td>
          <button
            className="btn btn-danger btn-sm"
            onClick={() => setCategories((prevCategories) =>
              prevCategories.map((category) =>
                category.items.some((i) => i.id === item.id)
                  ? { ...category, items: category.items.filter((i) => i.id !== item.id) }
                  : category
              )
            )}
          >
            Hapus
          </button>
        </td>
      </tr>
    );
  };

  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const handleTambahBahanAjar = (categoryId) => {
    setSelectedCategoryId(categoryId)
    setIsModalOpen(true)
  };
  
  const DroppableCategory = ({ category, addItem, moveItem }) => {
    const [, drop] = useDrop(() => ({
      accept: ItemType,
      drop: (draggedItem) => moveItem(draggedItem, category.id),
    }));
  
    return (
      <div ref={drop} className="w-64 p-4 bg-gray-50 rounded-lg shadow-md">
        <div>
          <h3 className="mb-4 text-lg font-semibold">{category.name}</h3>
          <div>
          <table className="table table-responsive table-bordered w-100">
            <thead>
              <tr>
                <th>No</th>
                {/* <th>Bentuk Soal</th> */}
                <th>Kode</th>
                <th>Topik</th>
                <th>Media</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {category.items.map((item, idx) => (
                <DraggableItem
              key={item.id}
              item={item}
              moveItem={moveItem}
            />
                
              ))}
            </tbody>
          </table>
          </div>
          {/* {category.items.map((item) => (
            <DraggableItem
              key={item.id}
              item={item}
              moveItem={moveItem}
            />
          ))} */}
          <button
            // variant="outline"
            // className="w-full mt-4"
            className="btn btn-sm m-1 btn-primary"
            onClick={() => handleTambahBahanAjar(category.id)}
          >
            Tambahkan Bahan Ajar
          </button>
        </div>
      </div>
    );
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      {step == "first" ? (
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h4>Tambah Paket Ajar</h4>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <h5 className="text-primary">
                        Judul <span className="text-danger">*</span>
                      </h5>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Masukkan Judul Paket"
                        onChange={(e) => handleFormChange(e.target.value, "title")}
                      />
                    </div>
                    <div className="form-group mt-3">
                      <h5 className="text-primary">
                        Kurikulum <span className="text-danger">*</span>
                      </h5>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        defaultValue={nullSel[0]}
                        isDisabled={isDisabled}
                        isLoading={isLoading}
                        isClearable={isClearable}
                        isRtl={isRtl}
                        isSearchable={isSearchable}
                        name="color"
                        options={kurikulum}
                        onChange={handleSelectKurikulum}
                      />
                    </div>
                    <div className="form-group mt-3">
                      <h5 className="text-primary">
                        Jenjang <span className="text-danger">*</span>
                      </h5>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        defaultValue={nullSel[0]}
                        isDisabled={isDisabled}
                        isLoading={isLoading}
                        isClearable={isClearable}
                        isRtl={isRtl}
                        isSearchable={isSearchable}
                        name="color"
                        options={jenjangOpt}
                        onChange={handleSelectJenjang}
                      />
                    </div>
                    <div className="form-group mt-3">
                      <h5 className="text-primary">
                        Kelompok Mata Pelajaran <span className="text-danger">*</span>
                      </h5>
                      <Select
                          className="basic-single"
                          classNamePrefix="select"
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="color"
                          options={kelompokMataPelajaranOpt}
                          onChange={handleSelectKelompokMataPelajaran}
                          value={selectedKelompokMataPelajaran}
                        />
                    </div>
                    <div className="form-group mt-3">
                      <h5 className="text-primary">
                        Jenis Kelompok Mata Pelajaran <span className="text-danger">*</span>
                      </h5>
                      <Select
                          className="basic-single"
                          classNamePrefix="select"
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="color"
                          options={jenisKelompokMataPelajaranOpt}
                          onChange={handleSelectJenisKelompokMataPelajaran}
                          value={selectedJenisKelompokMataPelajaran}
                        />
                    </div>
                    <div className="form-group mt-3">
                      <h5 className="text-primary">
                        Mata Pelajaran <span className="text-danger">*</span>
                      </h5>
                      {/* <input
                        type="text"
                        className="form-control"
                        placeholder="Masukkan Mata Pelajaran"
                        onChange={(e) => handleFormChange(e.target.value, "matapelajaran")}
                      /> */}
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        defaultValue={mataPelajaranOpt[0]}
                        isDisabled={isDisabled}
                        isLoading={isLoading}
                        isClearable={isClearable}
                        isRtl={isRtl}
                        isSearchable={isSearchable}
                        name="color"
                        options={mataPelajaranOpt}
                        onChange={(e) => handleFormChange(e, "matapelajaran")}
                      />
                    </div>
                    
                  </div>

                  <div className="col-sm-6">
                  <div className="form-group">
                      <h5 className="text-primary">
                        Kelas <span className="text-danger">*</span>
                      </h5>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        defaultValue={nullSel[0]}
                        isDisabled={isDisabled}
                        isLoading={isLoading}
                        isClearable={isClearable}
                        isRtl={isRtl}
                        isSearchable={isSearchable}
                        name="color"
                        options={kelasOpt}
                        onChange={(e) => handleFormChange(e.value, "kelas")}
                      />
                    </div>
                    <div className="form-group  mt-3">
                      <h5 className="text-primary">
                        Jurusan <span className="text-danger">*</span>
                      </h5>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isDisabled={isDisabled}
                        isLoading={isLoading}
                        isClearable={isClearable}
                        isRtl={isRtl}
                        isSearchable={isSearchable}
                        name="color"
                        options={jurusanOpt}
                        onChange={(e) => handleFormChange(e.value, "jurusan")}
                      />
                    </div>
                    <div className="form-group mt-3">
                      <h5 className="text-primary">
                        Semester <span className="text-danger">*</span>
                      </h5>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isDisabled={isDisabled}
                        isLoading={isLoading}
                        isClearable={isClearable}
                        isRtl={isRtl}
                        isSearchable={isSearchable}
                        name="color"
                        options={semesterOpt}
                        onChange={(e) => handleFormChange(e.value, "semester")}
                      />
                    </div>
                    <div className="form-group mt-3">
                      <h5 className="text-primary">
                        Indikator <span className="text-danger">*</span>
                      </h5>
                      <textarea
                        name=""
                        id=""
                        className="form-control"
                        placeholder="Masukkan indikator paket ajar"
                        onChange={(e) => handleFormChange(e.target.value, "indicator")}
                      ></textarea>
                    </div>
                    {/* <div className="form-group mt-3">
                      <h5 className="text-primary">
                        Durasi (Menit)<span className="text-danger">*</span>
                      </h5>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Masukkan durasi"
                        onChange={(e) => handleFormChange(e.target.value, "duration")}
                      />
                    </div>
                    <div className="form-group mt-3">
                      <h5 className="text-primary">
                        Platform <span className="text-danger">*</span>
                      </h5>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        defaultValue={nullSel[0]}
                        isDisabled={isDisabled}
                        isLoading={isLoading}
                        isClearable={isClearable}
                        isRtl={isRtl}
                        isSearchable={isSearchable}
                        name="color"
                        options={typeOpt}
                        onChange={(e) => handleFormChange(e.value, "type")}
                      />
                    </div> */}
                  </div>
                  {/* <div className="col-sm-12">
                    <div className="form-group mt-3">
                      <h5 className="text-primary">
                        Indikator <span className="text-danger">*</span>
                      </h5>
                      <textarea
                        name=""
                        id=""
                        className="form-control"
                        placeholder="Masukkan indikator paket soal"
                        onChange={(e) => handleFormChange(e.target.value, "indicator")}
                      ></textarea>
                    </div>
                  </div> */}
                  {/* <div className="col-sm-12 mt-4">
                    <div className="row">
                      <div className="col-5">
                        {" "}
                        <hr />
                      </div>
                      <div className="col-2 text-center">
                        <h5 className="text-primary mt-1">
                          Komposisi Paket Soal
                        </h5>
                      </div>
                      <div className="col-5">
                        {" "}
                        <hr />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 d-flex justify-content-between flex-wrap mt-3">
                  <ul className="nav nav-tabs dzm-tabs" id="myTab" role="tablist">
                    {["PG", "MPG", "SA", "UR", "PJ", "BS"].map((tab, idx) => (
                      <li key={idx} className="nav-item">
                        <button
                          onClick={() => handleActive(tab)}
                          className={`nav-link m-1 btn-outline-primary btn-sm ${activeTabs.includes(tab) ? "active" : null}`}
                          type="button"
                        >
                          {getTypeName(tab)}
                        </button>
                      </li>
                    ))}
                  </ul>
                  </div> */}
                  <div className="col-sm-12 mt-2">
        {/* {activeTabs.map((tab, idx) => (
          <div key={idx} className="row mt-3">
            <div className="col">
              <div className="form-group">
                <h5 className={`text-primary text-bold`}>
                  Jumlah Soal ({tab.toUpperCase()})
                </h5>
                <input
                  type="text"
                  className={`form-control border-primary`}
                  value={formValues[tab].jumlah_soal}
                  onChange={(e) => handleInput(e, tab, "jumlah_soal")}
                />
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <h5 className={`text-primary text-bold`}>
                  Bobot ({tab.toUpperCase()})
                </h5>
                <input
                  type="text"
                  className={`form-control border-primary`}
                  value={formValues[tab].bobot}
                  onChange={(e) => handleInput(e, tab, "bobot")}
                />
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <h5 className={`text-primary text-bold`}>
                  Poin Benar ({tab.toUpperCase()})
                </h5>
                <input
                  type="text"
                  className={`form-control border-primary`}
                  value={formValues[tab].point_benar}
                  onChange={(e) => handleInput(e, tab, "point_benar")}
                />
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <h5 className={`text-primary text-bold`}>
                  Poin Salah ({tab.toUpperCase()})
                </h5>
                <input
                  type="text"
                  className={`form-control border-primary`}
                  value={formValues[tab].point_salah}
                  onChange={(e) => handleInput(e, tab, "point_salah")}
                />
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <h5 className={`text-primary text-bold`}>
                  Poin Tidak Jawab ({tab.toUpperCase()})
                </h5>
                <input
                  type="text"
                  className={`form-control border-primary`}
                  value={formValues[tab].point_kosong}
                  disabled={true}
                  onChange={(e) => handleInput(e, tab, "point_kosong")}
                />
              </div>
            </div>
          </div>
        ))} */}
        <div className="row mt-5">
                      <div className="col-12 text-end">
                        <button
                          type="button"
                          onClick={handleNext}
                          className="btn btn-primary mt-3"
                        >
                          Selanjutnya <i className="fas fa-arrow-right"></i>
                        </button>
                      </div>
                    </div>

      </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-sm-12">
            <div className="row">
              <div className="col-12">
                <div className="card card-c-left">
                  <div className="card-header">
                    <h5>Paket Ajar</h5>
                  </div>
                  <div className="card-body table-responsive">
                  <div className="row">
                    <div className="col-4">
                      <input type="text" className="form-control" placeholder="Masukkan Nama Kategori" onChange={(e) => setCategoryField(e.target.value)} />
                    </div>
                    <div className="col-4">
                      <button className="btn btn-sm btn-primary" onClick={addCategory}>Tambah</button>
                    </div>
                  </div>
                  <DndProvider backend={HTML5Backend}>
                  {categories.map((category) => (
                    <DroppableCategory
                      key={category.id}
                      category={category}
                      addItem={addItem}
                      moveItem={moveItem}
                    />
                  ))}
                  </DndProvider>

                  
                    <div className="col-sm-12 text-end">
                      <button className="btn btn-sm m-1 btn-primary" onClick={handleSave}>
                        Simpan
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ModalSearch
            arrButirSoal={arrButirSoal}
            total={arrButirSoal.length}
            perPage={5}
            onPaging={onPaging}
            setSearchKeyword={setSearchKeyword}
            handleCariButir={handleCariButir}
            handleSelectedSoal={addItem}
            disabledSelectSoal={false} // Atur ke `true` jika tombol perlu dinonaktifkan
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
          />
        </div>
      )}
    </>
  );
};

export default AddPaketAjar;

import React, { useEffect, useRef, useState } from "react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useCart } from "../../../../services/CartContext";

const getColorJenisSoal = (type) => {
  switch (type) {
    case "PG":
      return "primary";
    case "MPG":
      return "primary";
    case "SA":
      return "warning";
    case "BS":
      return "success";
    case "UR":
      return "danger";
    case "PJ":
      return "primary";
    default:
      return "primary";
  }
};

const CardBursa = (props) => {
  const prevSoalValue = useRef();
  const {cart, dispatch } = useCart();

  useEffect(() => {

  }, [])

  const TitleTooltip = ({ title, el }) => {

    const renderTooltip = (props) => (
      <Tooltip id="dynamic-tooltip" {...props}>
        {title}
      </Tooltip>
    );
  
    return (
      <>
        <OverlayTrigger
          placement="left"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip}
        >
          <>
            <h4 className="text-dark text-truncate mb-0">{title}</h4>
          </>
        </OverlayTrigger>
      </>
    );
  };

  const handleAddToCart = (product) => {
    dispatch({ type: 'ADD_TO_CART', payload: { ...product, quantity: 1 } });
  };

  const isInCart = (productId) => {
    return cart.some((item) => item.id === productId);
  };

  return (
    <>
      <div className="col-md-6 col-lg-4 col-sm-6 mt-2">
        <div className="card border shadow-sm w-100">
          <div className="ctn-cover position-relative">
            <div className="bg-primary position-absolute mt-3 p-2 rounded-start" style={{ right: 0 }}>
              {props.type == 'paket' && (
                <small className="text-light"><i className="fas fa-book"></i> <b>{props.data.jumlah_butir}</b> Soal</small>
              )}
            </div>
            <img src={props.data.cover} className="w-100" style={{ height: '180px' }} alt="" />
          </div>
          <div className="ctn-v p-3">
            <div className="d-flex justify-content-start mb-1" >
              {props.type === 'paket' ? (
                <>
                  {Object.entries(props.data.score).map(([key, value]) => (
                    value.jumlah_soal >= 1 ? (
                      <span
                        key={key} 
                        className={`badge badge-sm badge-${getColorJenisSoal(key)}`}
                      >
                        {key}
                      </span>
                    ) : null
                  ))}
                </>
              ) : ('')}
            </div>
            <TitleTooltip key={props.data.id} el={'h4'} title={props.data.judul} />
            <hr className="my-2" />
            <div className="d-flex justify-content-between text-align-center">
              <div>
                <p className="text-dark text-truncate mb-0"><b>SC. {props.data.price}</b></p>
                <span>By {props.data.created_by}</span>
              </div>
              <button className="btn btn-primary btn-sm btn-icon"
                onClick={() => handleAddToCart(props.data)}
                disabled={isInCart(props.data.id)}>
                  <i className="fa fa-shopping-cart me-1"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardBursa;

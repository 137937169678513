import axios from 'axios';
// import https from 'https';
import { HOSTNAME } from '../constant';
import Cookies from "js-cookie";

axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;
axios.defaults.xsrfCookieName = "XSRF-TOKEN";
axios.defaults.xsrfHeaderName = "X-XSRF-TOKEN";
const laravelSessionCookie = Cookies.get('laravel_session');

console.log("laravel_session", laravelSessionCookie);
// axios.defaults.httpsAgent = new https.Agent({ rejectUnauthorized: false });
const axiosBahanAjar = axios.create({
    baseURL: 'https://adminmaudev.schoolmedia.my.id/api-ba/',
    withCredentials: true,
    withXSRFToken: true,
    // proxy: {
    //     protocol: 'http',
    //     host: 'http://bahanajarapi.schoolmedia.my.id:3000',
        // hostname: '127.0.0.1' // Takes precedence over 'host' if both are defined
        // port: 9000,
        // auth: {
        //   username: 'mikeymike',
        //   password: 'rapunz3l'
        // }
    // }
});

axiosBahanAjar.interceptors.request.use((config) => {
    // config.headers['Content-Type'] = 'application/json';
    console.log('config', config);
    return config;
})



axiosBahanAjar.interceptors.response.use((response) => {
    console.log('response instance : ', response);
    return response;
})

export default axiosBahanAjar;

import React, { useState, useRef, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axiosInstance from "../../../../services/AxiosInstance";
import pMinDelay from "p-min-delay";
import loadable from "@loadable/component";

const StatistikKartuSoal = () => {
  const { id_kartu } = useParams();

  //Load data statistik
  const [typeSoal, setTypeSoal] = useState(null);
  const [dtStatistik, setDtStatistik] = useState([]);

  useEffect(() => {
    detailKartu()
  }, [id_kartu]);

  
  const detailKartu = async () => {
    var res = await axiosInstance.get(`soal/${id_kartu}?with_jawaban=true`);
    var data = res.data.data;
    setTypeSoal(data.type)
    getStatistik(data.code);
  }
  

  const getStatistik = async (code) => {
    const response = await axiosInstance.get(`statistik/soal/${code}`);
    setDtStatistik(response.data.data)
  };

  const formatToTwoDecimalPlaces = (number) => {
    number = parseFloat(number); // Convert to a number
    if (isNaN(number)) {
        return 0;
    }
    return parseFloat(number.toFixed(2));
  }

  return (
    <>
    <div className="col-12">
      <div className="card">
        <div className="card-header card-header-komposisi d-flex justify-content-between flex-wrap">
          <div>
            <div className="card-title">Statistik Butir Soal</div>
          </div>
        </div>
      </div>
    </div>
    <div className="col-12">
      <div className="card">
        <div className="card-header d-flex justify-content-between flex-wrap">
          <h5 className="card-title">Data Statistik</h5>
        </div>
        
        <div className="card-body">
          {typeSoal == 'PG' && (
            <div className="col-12 table-responsive">
              <table className="table table-bordered table-stripped table-hover border-primary">
                <thead>
                  <tr className="text-center">
                    <th rowspan="2">No.</th>
                    <th rowspan="2">ID Kartu Soal</th>
                    <th rowspan="2">ID Paket Soal</th>
                    <th rowspan="2">ID Daftar Ujian</th>
                    <th rowspan="2">NPSN</th>
                    <th rowspan="2">Nama Sekolah</th>
                    <th rowspan="2">Jen</th>
                    <th rowspan="2">Kls</th>
                    <th rowspan="2">Rombel</th>
                    <th rowspan="2">Waktu Ujian</th>
                    <th colspan="8">Jumlah Penjawab Pilihan</th>
                    <th colspan="7">% Penjawab Pilihan</th>
                    <th colspan="5">Fungsi Jawaban Pengecoh</th>
                    <th colspan="2">Tingkat Kesulitan</th>
                    <th colspan="4">Daya Beda</th>
                    <th colspan="3">Validitas</th>
                  </tr>
                  <tr>
                    <th>A</th>
                    <th>B</th>
                    <th>C</th>
                    <th>D</th>
                    <th>E</th>
                    <th>K</th>
                    <th>∑</th>
                    <th>NB</th>
                    <th>A %</th>
                    <th>B %</th>
                    <th>C %</th>
                    <th>D %</th>
                    <th>E %</th>
                    <th>K</th>
                    <th>NB %</th>
                    <th>A</th>
                    <th>B</th>
                    <th>C</th>
                    <th>D</th>
                    <th>E</th>
                    <th>p = B/n</th>
                    <th>Kategori</th>
                    <th>rpbis</th>
                    <th>Kategori</th>
                    <th>D =PA-PB</th>
                    <th>Kategori</th>
                    <th>r Tabel</th>
                    <th>r Hitung</th>
                    <th>Validitas</th>
                  </tr>
                </thead>
                <tbody>
                  {dtStatistik.length <= 0 && (
                    <tr>
                      <td colSpan={39}>Tidak ada data sama sekali</td>
                    </tr>
                  )}
                  {dtStatistik.map((item, index) => (
                    <tr key={index}>
                      <td>{item.id}</td>
                      <td>{item.code}</td>
                      <td>{item?.ujian?.paket_soal_code ?? '-'}</td>
                      <td>{item?.ujian?.code ?? '-'}</td>
                      <td>{item?.ujian?.created_by_user.detail.npsn ?? '-'}</td>
                      <td>{item?.ujian?.created_by_user.detail.profile.institusi.nama_institusi ?? '-'}</td>
                      <td>{item?.ujian?.created_by_user.detail.profile.institusi.jenjang.jenjang ?? '-'}</td>
                      <td>{item?.ujian?.detail.kelas ?? '-'}</td>
                      <td>{item?.ujian?.detail.rombel ?? '-'}</td>
                      <td>{item?.ujian?.started_at ?? '-'}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["a"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["b"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["c"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["d"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["e"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["k"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["zigma"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["nb"])}</td>
                      
                      <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["a"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["b"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["c"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["d"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["e"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["k"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["nb"])}</td>

                      <td>{item.value.fungsi_penjawab_pengecoh["a"] ?? '-'}</td>
                      <td>{item.value.fungsi_penjawab_pengecoh["b"] ?? '-'}</td>
                      <td>{item.value.fungsi_penjawab_pengecoh["c"] ?? '-'}</td>
                      <td>{item.value.fungsi_penjawab_pengecoh["d"] ?? '-'}</td>
                      <td>{item.value.fungsi_penjawab_pengecoh["e"] ?? '-'}</td>

                      <td>{formatToTwoDecimalPlaces(item.value.tingkat_kesulitan["p"])}</td>
                      <td>{item.value.tingkat_kesulitan["kategori"]}</td>
                      
                      <td>{formatToTwoDecimalPlaces(item.value.daya_beda["rpbis"])}</td>
                      <td>{item.value.daya_beda["rpbis_katogori"]}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.daya_beda["diskriminasi"])}</td>
                      <td>{item.value.daya_beda["diskriminasi_kategori"]}</td>

                      <td>{formatToTwoDecimalPlaces(item.value.validitas["rtabel"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.validitas["rhitung"])}</td>
                      <td>{item.value.validitas["validitas"]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          {typeSoal == 'MPG'  && ("")}
          {typeSoal == 'SA'  && (
            <div className="col-12 table-responsive">
              <table className="table table-bordered table-stripped table-hover border-primary">
                <thead>
                  <tr>
                    <th rowspan="2">No.</th>
                    <th rowspan="2">ID Kartu Soal</th>
                    <th rowspan="2">ID Paket Soal</th>
                    <th rowspan="2">ID Daftar Ujian</th>
                    <th rowspan="2">NPSN</th>
                    <th rowspan="2">Nama Sekolah</th>
                    <th rowspan="2">Jen</th>
                    <th rowspan="2">Kls</th>
                    <th rowspan="2">Rombel</th>
                    <th rowspan="2">Waktu Ujian</th>
                    <th colspan="8">Jumlah Penjawab Pilihan</th>
                    <th colspan="7">% Penjawab Pilihan</th>
                    <th colspan="5">Fungsi Jawaban Pengecoh</th>
                    <th colspan="2">Tingkat Kesulitan</th>
                    <th colspan="4">Daya Beda</th>
                    <th colspan="3">Validitas</th>
                  </tr>
                  <tr>
                    <th>A</th>
                    <th>B</th>
                    <th>C</th>
                    <th>D</th>
                    <th>E</th>
                    <th>K</th>
                    <th>∑</th>
                    <th>NB</th>
                    <th>A %</th>
                    <th>B %</th>
                    <th>C %</th>
                    <th>D %</th>
                    <th>E %</th>
                    <th>K</th>
                    <th>NB %</th>
                    <th>A</th>
                    <th>B</th>
                    <th>C</th>
                    <th>D</th>
                    <th>E</th>
                    <th>p = B/n</th>
                    <th>Kategori</th>
                    <th>rpbis</th>
                    <th>Kategori</th>
                    <th>D =PA-PB</th>
                    <th>Kategori</th>
                    <th>r Tabel</th>
                    <th>r Hitung</th>
                    <th>Validitas</th>
                  </tr>
                </thead>
                <tbody>
                  {dtStatistik.length <= 0 && (
                    <tr>
                      <td colSpan={39}>Tidak ada data sama sekali</td>
                    </tr>
                  )}
                  {dtStatistik.map((item, index) => (
                    <tr key={index}>
                      <td>{item.id}</td>
                      <td>{item.code}</td>
                      <td>{item?.ujian?.paket_soal_code ?? '-'}</td>
                      <td>{item?.ujian?.code ?? '-'}</td>
                      <td>{item?.ujian?.created_by_user.detail.npsn ?? '-'}</td>
                      <td>{item?.ujian?.created_by_user.detail.profile.institusi.nama_institusi ?? '-'}</td>
                      <td>{item?.ujian?.created_by_user.detail.profile.institusi.jenjang.jenjang ?? '-'}</td>
                      <td>{item?.ujian?.detail.kelas ?? '-'}</td>
                      <td>{item?.ujian?.detail.rombel ?? '-'}</td>
                      <td>{item?.ujian?.started_at ?? '-'}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["a"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["b"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["c"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["d"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["e"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["k"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["zigma"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["nb"])}</td>
                      
                      <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["a"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["b"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["c"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["d"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["e"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["k"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["nb"])}</td>

                      <td>{item.value.fungsi_penjawab_pengecoh["a"] ?? '-'}</td>
                      <td>{item.value.fungsi_penjawab_pengecoh["b"] ?? '-'}</td>
                      <td>{item.value.fungsi_penjawab_pengecoh["c"] ?? '-'}</td>
                      <td>{item.value.fungsi_penjawab_pengecoh["d"] ?? '-'}</td>
                      <td>{item.value.fungsi_penjawab_pengecoh["e"] ?? '-'}</td>

                      <td>{formatToTwoDecimalPlaces(item.value.tingkat_kesulitan["p"])}</td>
                      <td>{item.value.tingkat_kesulitan["kategori"]}</td>
                      
                      <td>{formatToTwoDecimalPlaces(item.value.daya_beda["rpbis"])}</td>
                      <td>{item.value.daya_beda["rpbis_katogori"]}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.daya_beda["diskriminasi"])}</td>
                      <td>{item.value.daya_beda["diskriminasi_kategori"]}</td>

                      <td>{formatToTwoDecimalPlaces(item.value.validitas["rtabel"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.validitas["rhitung"])}</td>
                      <td>{item.value.validitas["validitas"]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          {typeSoal == 'BS'  && (
            <div className="col-12 table-responsive">
              <table className="table table-bordered table-stripped table-hover border-primary">
                <thead>
                  <tr className="text-center">
                    <th rowspan="2">No.</th>
                    <th rowspan="2">ID Kartu Soal</th>
                    <th rowspan="2">ID Paket Soal</th>
                    <th rowspan="2">ID Daftar Ujian</th>
                    <th rowspan="2">NPSN</th>
                    <th rowspan="2">Nama Sekolah</th>
                    <th rowspan="2">Jen</th>
                    <th rowspan="2">Kls</th>
                    <th rowspan="2">Rombel</th>
                    <th rowspan="2">Waktu Ujian</th>
                    <th colspan="5">Jumlah Penjawab Pilihan</th>
                    <th colspan="4">% Penjawab Pilihan</th>
                    <th colspan="2">Fungsi Jawaban Pengecoh</th>
                    <th colSpan="2">Tingkat Kesulitan</th>
                    <th colspan="4">Daya Beda</th>
                    <th colspan="3">Validitas</th>
                  </tr>
                  <tr>
                    <th>B</th>
                    <th>S</th>
                    <th>K</th>
                    <th>∑</th>
                    <th>NB</th>
                    <th>B %</th>
                    <th>S %</th>
                    <th>K %</th>
                    <th>NB %</th>
                    <th>B</th>
                    <th>S</th>
                    <th>p = B/n</th>
                    <th>Katagori</th>
                    <th>rpbis</th>
                    <th>Katagori</th>
                    <th>D =PA-PB</th>
                    <th>Katagori</th>
                    <th>r Tabel</th>
                    <th>r Hitung</th>
                    <th>Validitas</th>
                  </tr>
                </thead>
                <tbody>
                  {dtStatistik.length <= 0 && (
                    <tr>
                      <td colSpan={30}>Tidak ada data sama sekali</td>
                    </tr>
                  )}
                  {dtStatistik.map((item, index) => (
                    <tr key={index}>
                      <td>{item.id}</td>
                      <td>{item.code}</td>
                      <td>{item?.ujian?.paket_soal_code ?? '-'}</td>
                      <td>{item?.ujian?.code ?? '-'}</td>
                      <td>{item?.ujian?.created_by_user.detail.npsn ?? '-'}</td>
                      <td>{item?.ujian?.created_by_user.detail.profile.institusi.nama_institusi ?? '-'}</td>
                      <td>{item?.ujian?.created_by_user.detail.profile.institusi.jenjang.jenjang ?? '-'}</td>
                      <td>{item?.ujian?.detail.kelas ?? '-'}</td>
                      <td>{item?.ujian?.detail.rombel ?? '-'}</td>
                      <td>{item?.ujian?.started_at ?? '-'}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["b"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["s"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["k"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["zigma"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["nb"])}</td>
                      
                      <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["b"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["s"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["k"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["nb"])}</td>

                      <td>{item.value.fungsi_penjawab_pengecoh["b"] ?? '-'}</td>
                      <td>{item.value.fungsi_penjawab_pengecoh["s"] ?? '-'}</td>

                      <td>{formatToTwoDecimalPlaces(item.value.tingkat_kesulitan["p"])}</td>
                      <td>{item.value.tingkat_kesulitan["kategori"]}</td>
                      
                      <td>{formatToTwoDecimalPlaces(item.value.daya_beda["rpbis"])}</td>
                      <td>{item.value.daya_beda["rpbis_katogori"]}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.daya_beda["diskriminasi"])}</td>
                      <td>{item.value.daya_beda["diskriminasi_kategori"]}</td>

                      <td>{formatToTwoDecimalPlaces(item.value.validitas["rtabel"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.validitas["rhitung"])}</td>
                      <td>{item.value.validitas["validitas"]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          {typeSoal == 'UR'  && (
            <div className="col-12 table-responsive">
              <table className="table table-bordered table-stripped table-hover border-primary">
                <thead>
                  <tr className="text-center">
                    <th rowspan="2">No.</th>
                    <th rowspan="2">ID Kartu Soal</th>
                    <th rowspan="2">ID Paket Soal</th>
                    <th rowspan="2">ID Daftar Ujian</th>
                    <th rowspan="2">NPSN</th>
                    <th rowspan="2">Nama Sekolah</th>
                    <th rowspan="2">Jen</th>
                    <th rowspan="2">Kls</th>
                    <th rowspan="2">Rombel</th>
                    <th rowspan="2">Waktu Ujian</th>
                    <th colspan="13">Jumlah Penjawab dengan Nilai 1 s.d. 10</th>
                    <th colspan="10">% Penjawab Pilihan</th>
                    <th colspan="2">Tingkat Kesulitan</th>
                    <th colspan="4">Daya Beda</th>
                    <th colspan="3">Validitas</th>
                  </tr>
                  <tr>
                    <th>1</th>
                    <th>2</th>
                    <th>3</th>
                    <th>4</th>
                    <th>5</th>
                    <th>6</th>
                    <th>7</th>
                    <th>8</th>
                    <th>9</th>
                    <th>10</th>
                    <th>K</th>
                    <th>∑</th>
                    <th>NB</th>
                    <th>1</th>
                    <th>2</th>
                    <th>3</th>
                    <th>4</th>
                    <th>5</th>
                    <th>6</th>
                    <th>7</th>
                    <th>8</th>
                    <th>9</th>
                    <th>10</th>
                    <th>p = B/n</th>
                    <th>Katagori</th>
                    <th>rpbis</th>
                    <th>Katagori</th>
                    <th>D =PA-PB</th>
                    <th>Katagori</th>
                    <th>r Tabel</th>
                    <th>r Hitung</th>
                    <th>Validitas</th>
                  </tr>
                </thead>
                <tbody>
                  {dtStatistik.length <= 0 && (
                    <tr>
                      <td colSpan={42}>Tidak ada data sama sekali</td>
                    </tr>
                  )}
                  {dtStatistik.map((item, index) => (
                    <tr key={index}>
                      <td>{item.id}</td>
                      <td>{item.code}</td>
                      <td>{item?.ujian?.paket_soal_code ?? '-'}</td>
                      <td>{item?.ujian?.code ?? '-'}</td>
                      <td>{item?.ujian?.created_by_user.detail.npsn ?? '-'}</td>
                      <td>{item?.ujian?.created_by_user.detail.profile.institusi.nama_institusi ?? '-'}</td>
                      <td>{item?.ujian?.created_by_user.detail.profile.institusi.jenjang.jenjang ?? '-'}</td>
                      <td>{item?.ujian?.detail.kelas ?? '-'}</td>
                      <td>{item?.ujian?.detail.rombel ?? '-'}</td>
                      <td>{item?.ujian?.started_at ?? '-'}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["1"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["2"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["3"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["4"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["5"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["6"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["7"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["8"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["9"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["10"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["k"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["zigma"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["nb"])}</td>
                      
                      <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["Asosiatif"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["Imitasi"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["Interaksionisme simbolik"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["Internalisasi"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["Pengendalian sosial formal"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["Sosialisasi"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["Struktur sosial"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["Tindakan rasional nilai"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["k"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["nb"])}</td>

                      <td>{formatToTwoDecimalPlaces(item.value.tingkat_kesulitan["p"])}</td>
                      <td>{item.value.tingkat_kesulitan["kategori"]}</td>
                      
                      <td>{formatToTwoDecimalPlaces(item.value.daya_beda["rpbis"])}</td>
                      <td>{item.value.daya_beda["rpbis_katogori"]}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.daya_beda["diskriminasi"])}</td>
                      <td>{item.value.daya_beda["diskriminasi_kategori"]}</td>

                      <td>{formatToTwoDecimalPlaces(item.value.validitas["rtabel"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.validitas["rhitung"])}</td>
                      <td>{item.value.validitas["validitas"]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          {typeSoal == 'PJ'  && (
            <div className="col-12 table-responsive">
              <table className="table table-bordered table-stripped table-hover border-primary">
                <thead>
                  <tr className="text-center">
                    <th rowspan="2">No.</th>
                    <th rowspan="2">ID Kartu Soal</th>
                    <th rowspan="2">ID Paket Soal</th>
                    <th rowspan="2">ID Daftar Ujian</th>
                    <th rowspan="2">NPSN</th>
                    <th rowspan="2">Nama Sekolah</th>
                    <th rowspan="2">Jen</th>
                    <th rowspan="2">Kls</th>
                    <th rowspan="2">Rombel</th>
                    <th rowspan="2">Waktu Ujian</th>
                    <th colspan="13">Jumlah Penjawab Pilihan</th>
                    <th colspan="12">% Penjawab Pilihan</th>
                    <th colspan="10">Fungsi Jawaban Pengecoh</th>
                    <th colSpan="2">Tingkat Kesulitan</th>
                    <th colSpan="4">Daya Beda</th>
                    <th colSpan="3">Validitas</th>
                  </tr>
                  <tr>
                    <th>A</th>
                    <th>B</th>
                    <th>C</th>
                    <th>D</th>
                    <th>E</th>
                    <th>F</th>
                    <th>G</th>
                    <th>H</th>
                    <th>I</th>
                    <th>J</th>
                    <th>Ko</th>
                    <th>∑</th>
                    <th>NB</th>
                    <th>A %</th>
                    <th>B %</th>
                    <th>C %</th>
                    <th>D %</th>
                    <th>E %</th>
                    <th>F %</th>
                    <th>G %</th>
                    <th>H %</th>
                    <th>I %</th>
                    <th>J %</th>
                    <th>Ko</th>
                    <th>NB %</th>
                    <th>A</th>
                    <th>B</th>
                    <th>C</th>
                    <th>D</th>
                    <th>E</th>
                    <th>F</th>
                    <th>G</th>
                    <th>H</th>
                    <th>I</th>
                    <th>J</th>
                    <th>p = B/n</th>
                    <th>Kategori</th>
                    <th>rpbis</th>
                    <th>Kategori</th>
                    <th>D =PA-PB</th>
                    <th>Kategori</th>
                    <th>r Tabel</th>
                    <th>r Hitung</th>
                    <th>validitas</th>
                  </tr>
                </thead>
                <tbody>
                  {dtStatistik.length <= 0 && (
                    <tr>
                      <td colSpan={54}>Tidak ada data sama sekali</td>
                    </tr>
                  )}
                  {dtStatistik.map((item, index) => (
                    <tr key={index}>
                      <td>{item.id}</td>
                      <td>{item.code}</td>
                      <td>{item?.ujian?.paket_soal_code ?? '-'}</td>
                      <td>{item?.ujian?.code ?? '-'}</td>
                      <td>{item?.ujian?.created_by_user.detail.npsn ?? '-'}</td>
                      <td>{item?.ujian?.created_by_user.detail.profile.institusi.nama_institusi ?? '-'}</td>
                      <td>{item?.ujian?.created_by_user.detail.profile.institusi.jenjang.jenjang ?? '-'}</td>
                      <td>{item?.ujian?.detail.kelas ?? '-'}</td>
                      <td>{item?.ujian?.detail.rombel ?? '-'}</td>
                      <td>{item?.ujian?.started_at ?? '-'}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["1"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["2"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["3"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["4"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["5"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["6"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["7"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["8"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["9"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["10"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["k"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["zigma"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.jumlah_penjawab_pilihan["nb"])}</td>
                      
                      <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["Asosiatif"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["Imitasi"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["Interaksionisme simbolik"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["Internalisasi"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["Pengendalian sosial formal"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["Sosialisasi"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["Struktur sosial"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["Tindakan rasional nilai"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["k"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["nb"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["nb"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.presentase_penjawab_pilihan["nb"])}</td>

                      <td>{item.value.fungsi_penjawab_pengecoh["Pengendalian sosial formal"] ?? '-'}</td>
                      <td>{item.value.fungsi_penjawab_pengecoh["Imitasi"] ?? '-'}</td>
                      <td>{item.value.fungsi_penjawab_pengecoh["Asosiatif"] ?? '-'}</td>
                      <td>{item.value.fungsi_penjawab_pengecoh["Tindakan rasional nilai"] ?? '-'}</td>
                      <td>{item.value.fungsi_penjawab_pengecoh["Struktur sosial"] ?? '-'}</td>
                      <td>{item.value.fungsi_penjawab_pengecoh["Internalisasi"] ?? '-'}</td>
                      <td>{item.value.fungsi_penjawab_pengecoh["Sosialisasi"] ?? '-'}</td>
                      <td>{item.value.fungsi_penjawab_pengecoh["Interaksionisme simbolik"] ?? '-'}</td>
                      <td>{item.value.fungsi_penjawab_pengecoh["Interaksionisme simbolik"] ?? '-'}</td>
                      <td>{item.value.fungsi_penjawab_pengecoh["Interaksionisme simbolik"] ?? '-'}</td>
                      
                      <td>{formatToTwoDecimalPlaces(item.value.tingkat_kesulitan["p"])}</td>
                      <td>{item.value.tingkat_kesulitan["kategori"]}</td>
                      
                      <td>{formatToTwoDecimalPlaces(item.value.daya_beda["rpbis"])}</td>
                      <td>{item.value.daya_beda["rpbis_katogori"]}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.daya_beda["diskriminasi"])}</td>
                      <td>{item.value.daya_beda["diskriminasi_kategori"]}</td>

                      <td>{formatToTwoDecimalPlaces(item.value.validitas["rtabel"])}</td>
                      <td>{formatToTwoDecimalPlaces(item.value.validitas["rhitung"])}</td>
                      <td>{item.value.validitas["validitas"]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  </>
  );
};

export default StatistikKartuSoal;

import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Pagination from "../Pagination";

const ModalSearch = ({
  arrButirSoal,
  total,
  perPage,
  onPaging,
  setSearchKeyword,
  handleCariButir,
  handleSelectedSoal,
  disabledSelectSoal,
  isModalOpen, setIsModalOpen
}) => {
//   const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      {/* <Button variant="primary" onClick={() => setIsModalOpen(true)} className="mb-4">
        Open Modal
      </Button> */}
      <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Daftar Bahan Ajar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-4">
            <div className="row">
              <div className="col-8">
                <input
                  type="search"
                  className="form-control"
                  placeholder="Cari nama bahan ajar"
                  onChange={(e) => setSearchKeyword(e.target.value)}
                />
              </div>
              <div className="col-4">
                <Button variant="primary" onClick={() => handleCariButir()} className="w-100">
                  Cari
                </Button>
              </div>
            </div>
          </div>
          <div
            className="overflow-auto"
            style={{ maxHeight: "500px" }}
          >
            <div className="row">
              {arrButirSoal.map((v, i) => (
                <div className="col-12 pb-0 mb-0" key={i}>
                  <div className="card mt-2 rounded pb-0 border">
                    <div className="card-header row">
                      <div className="col-9">
                        <h5>{v.topic}</h5>
                        <p>{v.id_bahan_ajar}</p>
                        <div className="row">
                        <div className="col-4">Tipe Media</div>
                        <div className="col-8">: {v.media}</div>
                      </div>
                      <div className="row">
                        <div className="col-4">Kelas</div>
                        <div className="col-8">: {v.detail.kelas}</div>
                      </div>
                      </div>
                      <div className="col-3 text-right">
                        <Button
                          variant="outline-primary"
                          size="sm"
                          disabled={disabledSelectSoal}
                          onClick={() => handleSelectedSoal(v)}
                          className="rounded-circle"
                        >
                          +
                        </Button>
                      </div>
                    </div>
                    {/* <div className="card-body">
                      <div className="row mb-0 mt-3">
                        <div className="col-4">Tipe Media</div>
                        <div className="col-8">: {v.media}</div>
                      </div>
                      <div className="row">
                        <div className="col-4">Kelas</div>
                        <div className="col-8">: {v.detail.kelas}</div>
                      </div>
                    </div> */}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Pagination
            totalData={total}
            dataPerPage={perPage}
            onChange={onPaging}
            visiblePageNumbers={3}
            showEllipsis={false}
          />
          <Button variant="secondary" onClick={() => setIsModalOpen(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalSearch;

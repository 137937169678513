import React, { useEffect, useRef, useState } from "react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
import { isEqual } from "../../../../utils";
import axiosInstance from "../../../../services/AxiosInstance";
import {
  ClassicEditor
} from 'ckeditor5';
import SMEditor from "../../Editor";
import Swal from "sweetalert2";
function uploadAdapter(loader) {
  return {
    upload: () => {
      return new Promise(async (resolve, reject) => {
        try {
          const file = await loader.file;
          console.log('loader file : ', file);
          const response = await axiosInstance.request({
            method: "POST",
            url: `media/upload`,
            data: {
              file: file
            },
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });
          console.log(response);
          resolve({
            default: `${response.data.url}`
          });
        } catch (error) {
          reject("Hello");
        }
      });
    },
    abort: () => {}
  };
}
function uploadPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    console.log("loader : ", loader);
    return uploadAdapter(loader);
  };
}
const SebabAkibat = (props) => {
  const [soals, setSoals] = useState([{
    soal: "",
    pilihan: {},
    selectedOption: ""
  }]);
  const [firstLoad, setFirstLoad] = useState(true);
  const prevSoalValue = useRef();

  useEffect(() => {
    if (props.defaultValue != null && props.action != 'add') {
      setSoals([{
        soal: props.defaultValue.pertanyaan,
        pilihan: Object.keys(props.defaultValue.pilihan).reduce((acc, key) => {
          acc[key] = props.defaultValue.pilihan[key];
          return acc;
        }, {}),
        selectedOption: props.defaultValue.jawaban,
      }]);
      setFirstLoad(false);
    }else{
      const newPilihan = [...Array(props.jumlahpg)].reduce((acc, _, index) => {
        const key = String.fromCharCode(97 + index); // 'a', 'b', 'c', ...
        acc[key] = '';
        return acc;
      }, {});

      setSoals([{
        soal: "",
        pilihan: newPilihan,
        selectedOption: "",
      }]);
    }
  }, [props.jumlahpg, props.defaultValue, firstLoad]);

  const handleInputChange = (index, key, value) => {
    const newSoals = [...soals];
    newSoals[index].pilihan[key] = value;
    setSoals(newSoals);
  };

  const handleRadioChange = (index, value) => {
    const newSoals = [...soals];
    newSoals[index].selectedOption = value;
    setSoals(newSoals);
  };

  const handleSaveButton = (redirect) => {
    const valid = soals.every((soal) => soal.soal.trim() !== "" && Object.values(soal.pilihan).every((pilihan) => pilihan.trim() !== "") && soal.selectedOption.trim() !== "");
    
    if (!valid) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Tolong lengkapi semua soal dan pilihan jawaban!',
      })
      return;
    }

    const params = soals.map((soal) => ({
      pertanyaan: soal.soal,
      pilihan: soal.pilihan,
      jawaban: soal.selectedOption,
    }));
    
    props.onSaveButton(params, redirect);

    const newPilihan = [...Array(props.jumlahpg)].reduce((acc, _, index) => {
      const key = String.fromCharCode(97 + index); // 'a', 'b', 'c', ...
      acc[key] = '';
      return acc;
    }, {});

    if (redirect) {
      setSoals([{
        soal: "",
        pilihan: newPilihan,
        selectedOption: "",
      }]);
    }
  };

  const handleAddSoal = () => {
    const newPilihan = [...Array(props.jumlahpg)].reduce((acc, _, index) => {
      const key = String.fromCharCode(97 + index); // 'a', 'b', 'c', ...
      acc[key] = '';
      return acc;
    }, {});
    setSoals([
      ...soals,
      {
        soal: "",
        pilihan: newPilihan,
        selectedOption: "",
      }
    ]);
  };

  const handleRemoveSoal = (index) => {
    const newSoals = soals.filter((_, i) => i !== index);
    setSoals(newSoals);
  };

  return (
    <>
      {soals.map((item, index) => (
        <div key={index}>
          <div className="row">
            <div className="col-sm-12">
              <h5 className="text text-primary">
                Input Soal <span className="text-danger">*</span>
              </h5>
              <div className="col-xl-12 mt-2">
                <SMEditor
                  placeholder="Tuliskan soal di sini..."
                  disabled={props.isDisabled}
                  data={item.soal}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    const newSoals = [...soals];
                    newSoals[index].soal = data;
                    setSoals(newSoals);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row mt-4 mb-3">
            <div className="col-sm-12">
              <h5 className="text text-primary">
                Pilihan Jawaban <span className="text-danger">*</span>
              </h5>
              {Object.keys(item.pilihan).map((key) => (
                <div className="row mt-2" key={key}>
                  <div className="col-1 centered-item">
                    <input
                      className="form-check-input ms-4"
                      type="radio"
                      name={`gridRadios-${index}`}
                      value={key}
                      onChange={() => handleRadioChange(index, key)}
                      checked={item.selectedOption === key}
                      disabled={props.isDisabled}
                    />
                  </div>
                  <div className="col-11">
                    <SMEditor
                      placeholder={`Tambahkan opsi pilihan ${key.toUpperCase()} disini.`}
                      data={item.pilihan[key]}
                      disabled={props.isDisabled}
                      onBlur={(event, editor) => {
                        const data = editor.getData();
                        handleInputChange(index, key, data);
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-sm-12 text-end">
              {soals.length > 1 && (
                <button
                  className="btn btn-sm m-1 btn-danger"
                  onClick={() => handleRemoveSoal(index)}
                >
                  Hapus Soal
                </button>
              )}
            </div>
          </div>
        </div>
      ))}
      {!props.isDisabled && (
        <div className="row mt-3 mb-4">
          <div className="col-sm-12 text-end">
            {props.action == 'add' ? (
              <>
                <button className="btn btn-sm m-1 btn-outline-primary" onClick={handleAddSoal}>
                  Tambah Soal
                </button>
                <button
                  className="btn btn-sm m-1 btn-danger"
                  onClick={() => handleSaveButton(false)}
                >
                  Simpan & Keluar
                </button>
                <button
                  className="btn btn-sm m-1 btn-primary"
                  onClick={() => handleSaveButton(true)}
                >
                  Simpan & Buat Baru
                </button>
              </>
            ) : (
              <>
                <button
                  className="btn btn-sm m-1 btn-danger"
                  onClick={() => handleSaveButton(false)}
                >
                  Update
                </button>
              </>
            )}
            
          </div>
        </div>
      )}
    </>
  );
};

export default SebabAkibat;


// const SebabAkibat = (props) => {
//   console.log("ini props : ", props.jumlahpg);

//   const [soal, setSoal] = useState("");
//   const [pilihan, setPilihan] = useState({});
//   const [selectedOption, setSelectedOption] = useState('');

//   const [firstLoad, setFirstLoad] = useState(true);
//   const prevSoalValue = useRef();
//   useEffect(() => {
//     if (props.defaultValue != null && firstLoad) {
//       if(!isEqual(prevSoalValue.current, soal)){
//         prevSoalValue.current = soal;

//         console.log("sebab akibat default value : ", props.defaultValue);
//         setSoal(props.defaultValue.pertanyaan);

//         const newPilihan = [...Array(props.jumlahpg)].reduce((acc, _, index) => {
//           const key = String.fromCharCode(97 + index); // 'a', 'b', 'c', ...
//           acc[key] = props.defaultValue.pilihan[key];
//           return acc;
//         }, {});

//         console.log("newPilihan : ", newPilihan);
//         setPilihan(newPilihan);
//         setSelectedOption(props.defaultValue.jawaban)
//       }
//     }else{
//       const newPilihan = [...Array(props.jumlahpg)].reduce((acc, _, index) => {
//         const key = String.fromCharCode(97 + index); // 'a', 'b', 'c', ...
//         acc[key] = '';
//         return acc;
//       }, {});
//       setPilihan(newPilihan);
//     }
//   }, [props.jumlahpg, props.defaultValue]);

//   // Fungsi untuk menangani perubahan pada input teks
//   const handleInputChange = (key, value) => {
//     setPilihan({
//       ...pilihan,
//       [key]: value
//     });
//   };

//   // Fungsi untuk menangani perubahan pilihan radio
//   const handleRadioChange = (value) => {
//     setSelectedOption(value);
//   };



//   const handleSaveButton = (redirect) => {
//     var params = {
//       "pertanyaan": soal,
//       "pilihan": pilihan,
//       "jawaban": selectedOption
//     };
//     props.onSaveButton(params, redirect)

//     if (redirect) {
//       setSoal("");
//       const newPilihan = [...Array(props.jumlahpg)].reduce((acc, _, index) => {
//         const key = String.fromCharCode(97 + index); // 'a', 'b', 'c', ...
//         acc[key] = '';
//         return acc;
//       }, {});
//       setPilihan(newPilihan);
//       setSelectedOption('');
//     }
//   };

//   return (
//     <>
//       <div className="row">
//         <div className="col-sm-12">
//           <h5 className="text text-primary">
//             Input Soal <span className="text-danger">*</span>
//           </h5>
//           <div className="col-xl-12 mt-2">
//             {/* <CKEditor
//               config={{
//                 placeholder: "Tuliskan soal di sini...",
//                 extraPlugins: [uploadPlugin],
//               }}
//               editor={ClassicEditor}
//               data={soal}
//               onReady={(editor) => {}}
//               onChange={(event, editor) => {
//                 const data = editor.getData();
//                 setSoal(data);
//               }}
//               onBlur={(event, editor) => {}}
//               onFocus={(event, editor) => {}}
//               disabled={props.isDisabled}
//             /> */}
//             <SMEditor
//                 placeholder="Tuliskan soal di sini..."
//                 disabled={props.isDisabled}
//                 data={soal}
//                 onChange={(event, editor) => {
//                   const data = editor.getData();
//                   setSoal(data);
//                 }}
//               />
//           </div>
//         </div>
//       </div>
//       <div className="row mt-4 mb-3">
//         <div className="col-sm-12">
//           <h5 className="text text-primary">
//             Pilihan Jawaban <span className="text-danger">*</span>
//           </h5>
//           {Object.keys(pilihan).map((key, index) => (
//             <div className="row mt-2" key={index}>
//               <div className="col-1 centered-item">
//                 <input
//                   className="form-check-input ms-4"
//                   type="radio"
//                   name="gridRadios"
//                   value={key}
//                   onChange={() => handleRadioChange(key)}
//                   checked={selectedOption === key}
//                   disabled={props.isDisabled}
//                 />
//               </div>
//               <div className="col-11">
//                 {/* <input type="text" className="form-control"
//                 placeholder={`Tambahkan opsi pilihan ${key.toUpperCase()} disini.`}
//                 value={pilihan[key]}
//                 disabled={props.isDisabled}
//                 onChange={(e) => handleInputChange(key, e.target.value)} /> */}
//                 <SMEditor
//                     placeholder={`Tambahkan opsi pilihan ${key.toUpperCase()} disini.`}
//                     data={pilihan[key]}
//                     disabled={props.isDisabled}
//                     onBlur={(event, editor) => {
//                       const data = editor.getData();
//                       // handleSoalChange(groupIndex, data);
//                       handleInputChange(key, data)
//                     }}
//                   />
//               </div>
//               {/* <div className="col-3">
//                 <button className="btn btn-outline-primary w-100">
//                   <i className="fas fa-image me-2"></i>
//                   Add Media
//                 </button>
//               </div> */}
//             </div>
//           ))}
//         </div>
//       </div>
//       {props.isDisabled ? "" : (
//       <div className="row mt-3 mb-4">
//         <div className="col-sm-12 text-end">
//           {/* <button className="btn btn-sm m-1 btn-outline-primary">
//             Simpan Draft
//           </button> */}
//           {/* <button className="btn btn-sm m-1 btn-primary" onClick={handleSaveButton}>Simpan</button> */}
//           <button className="btn btn-sm m-1 btn-danger" onClick={() => handleSaveButton(false)}>Simpan & Keluar</button>
//           <button className="btn btn-sm m-1 btn-primary" onClick={() => handleSaveButton(true)}>Simpan & Buat Baru</button>
//         </div>
//       </div>
//       )}
//     </>
//   );
// };

// export default SebabAkibat;

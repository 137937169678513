import React, { useEffect, useState } from "react";

function CustomSwitch({ id, value, onChange }) {
  const [isPublished, setIsPublished] = useState(false);

  useEffect(() => {
    setIsPublished(value);
  }, [value]);

  const handleSwitchToggle = () => {
    const newStatus = !isPublished;
    setIsPublished(newStatus);
    if (onChange) onChange(id, newStatus); // Kirim id dan status baru ke parent
  };

  return (
    <div
      onClick={handleSwitchToggle}
      style={{
        width: "85px", // Lebar diperkecil
        height: "30px", // Tinggi diperkecil
        borderRadius: "15px", // Sesuaikan dengan tinggi untuk tampilan melengkung
        backgroundColor: isPublished ? "#4caf50" : "#ccc",
        position: "relative",
        cursor: "pointer",
        transition: "background-color 0.3s",
        display: "flex",
        alignItems: "center",
      }}
    >
      {/* Label "Draft" dan "Publish" */}
      <span
        style={{
          position: "absolute",
          left: isPublished ? "8px" : "30px", // Sesuaikan posisi label
          color: "#fff",
          fontWeight: "bold",
          fontSize: "12px", // Ukuran font diperkecil
          transition: "left 0.3s",
        }}
      >
        {isPublished ? "Publish" : "Draft"}
      </span>

      {/* Knob */}
      <div
        style={{
          width: "22px", // Lebar knob diperkecil
          height: "22px", // Tinggi knob diperkecil
          borderRadius: "50%",
          backgroundColor: "#fff",
          position: "absolute",
          top: "4px", // Sesuaikan posisi vertikal knob
          left: isPublished ? "60px" : "4px", // Sesuaikan posisi horizontal knob
          transition: "left 0.3s",
          boxShadow: "0 0 5px rgba(0,0,0,0.3)",
        }}
      ></div>
    </div>
  );
}

export default CustomSwitch;

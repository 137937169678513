import React, { useEffect, useRef, useState } from "react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
import { isEqual } from "../../../../utils";
import axiosInstance from "../../../../services/AxiosInstance";
import SMEditor from "../../Editor";

function uploadAdapter(loader) {
  return {
    upload: () => {
      return new Promise(async (resolve, reject) => {
        try {
          const file = await loader.file;
          console.log('loader file : ', file);
          const response = await axiosInstance.request({
            method: "POST",
            url: `media/upload`,
            data: {
              file: file
            },
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });
          console.log(response);
          resolve({
            default: `${response.data.url}`
          });
        } catch (error) {
          reject("Hello");
        }
      });
    },
    abort: () => {}
  };
}
function uploadPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    console.log("loader : ", loader);
    return uploadAdapter(loader);
  };
}

const Penjodohan = (props) => {
  const [soalList, setSoalList] = useState([{
    pertanyaan: "",
    pasangan: "",
    pengecoh: "",
  }]);
  const [errors, setErrors] = useState([]);

  const prevSoalValue = useRef();

  useEffect (() => {
    console.log("soalList useEffect : ", soalList);
    
  }, [soalList]);

  useEffect(() => {
    console.log("Penjodohan useEffect : ", props.defaultValue);
    if (props.defaultValue != null && !isEqual(prevSoalValue.current, props.defaultValue)) {
      prevSoalValue.current = props.defaultValue;
      if (props.action === "detail" || props.action == "update") {
        console.log("Penjodohan useEffect detail : ", props.defaultValue);
        setSoalList([props.defaultValue]);
        // setTimeout(() => {
        //   updateSoal(0, "pertanyaan", props.defaultValue.pertanyaan);
        //   updateSoal(0, "pasangan", props.defaultValue.pasangan);
        //   updateSoal(0, "pengecoh", props.defaultValue.pengecoh);
        // }, 100);
      }else{
        setSoalList(props.defaultValue);
      }
    }
  }, [props.defaultValue]);

  const validateSoal = () => {
    const newErrors = soalList.map((soal, index) => {
      const error = {};
      if (!soal.pertanyaan.trim()) {
        error.pertanyaan = "Pernyataan tidak boleh kosong.";
      }
      if (!soal.pasangan.trim()) {
        error.pasangan = "Pasangan tidak boleh kosong.";
      }
      return error;
    });
    setErrors(newErrors);
    return newErrors.every((error) => Object.keys(error).length === 0);
  };

  const handleSaveButton = (redirect) => {
    if (!validateSoal()) return;

    props.onSaveButton(soalList, redirect);

    if (redirect) {
      setSoalList([{
        pertanyaan: "",
        pasangan: "",
        pengecoh: "",
      }]);
      setErrors([]);
    }
  };

  const handleAddSoal = () => {
    setSoalList([...soalList, { pertanyaan: "", pasangan: "", pengecoh: "" }]);
    setErrors([...errors, {}]);
  };

  const handleRemoveSoal = (index) => {
    setSoalList(soalList.filter((_, i) => i !== index));
    setErrors(errors.filter((_, i) => i !== index));
  };

  const updateSoal = (index, field, value) => {
    console.log("updateSoal : ", index, field, value);
    console.log("soalList : ", soalList);
    const updatedSoalList = soalList.map((soal, i) =>
      i === index ? { ...soal, [field]: value } : soal
    );
    console.log("updatedSoalList : ", updatedSoalList);
    setSoalList(updatedSoalList);

    const updatedErrors = errors.map((error, i) => {
      if (i === index) {
        const newError = { ...error };
        if (field === "pertanyaan" && !value.trim()) {
          newError.pertanyaan = "Pernyataan tidak boleh kosong.";
        } else if (field === "pertanyaan") {
          delete newError.pertanyaan;
        }
        if (field === "pasangan" && !value.trim()) {
          newError.pasangan = "Pasangan tidak boleh kosong.";
        } else if (field === "pasangan") {
          delete newError.pasangan;
        }
        return newError;
      }
      return error;
    });
    setErrors(updatedErrors);
  };

  return (
    <>
      {soalList.map((soal, index) => (
        <div key={index} className="soal-item mb-4">
          <div className="row">
            <div className="col-sm-12">
              <h5 className="text text-primary">
                Pernyataan {index + 1} <span className="text-danger">*</span>
              </h5>
              <div className="col-xl-12 mt-2">
                <SMEditor
                  placeholder="Tulis Pernyataan di sini"
                  data={soal.pertanyaan}
                  disabled={props.isDisabled}
                  onBlur={(event, editor) => {
                    const data = editor.getData();
                    updateSoal(index, "pertanyaan", data);
                  }}
                />
                {errors[index]?.pertanyaan && (
                  <div className="text-danger mt-1">{errors[index].pertanyaan}</div>
                )}
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-sm-6">
              <h5 className="text text-primary">
                Pasangan <span className="text-danger">*</span>
              </h5>
              <div className="col-xl-12 mt-2">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Masukkan pasangan"
                  value={soal.pasangan}
                  onChange={(e) => updateSoal(index, "pasangan", e.target.value)}
                  disabled={props.isDisabled}
                />
                {errors[index]?.pasangan && (
                  <div className="text-danger mt-1">{errors[index].pasangan}</div>
                )}
              </div>
            </div>
            <div className="col-sm-6">
              <h5 className="text text-primary">Pengecoh</h5>
              <div className="col-xl-12 mt-2">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Masukkan pengecoh"
                  value={soal.pengecoh}
                  onChange={(e) => updateSoal(index, "pengecoh", e.target.value)}
                  disabled={props.isDisabled}
                />
              </div>
            </div>
          </div>
          {!props.isDisabled && soalList.length > 1 && (
            <div className="text-end mt-2">
              <button
                className="btn btn-sm btn-danger"
                onClick={() => handleRemoveSoal(index)}
              >
                Hapus Soal
              </button>
            </div>
          )}
          <hr />
        </div>
      ))}

      {!props.isDisabled && (props.action === "add") && (
        <div className="row mt-3">
          <div className="col-sm-12 text-start">
            <button className="btn btn-sm btn-outline-primary" onClick={handleAddSoal}>
              Tambah Soal
            </button>
          </div>
        </div>
      )}

      {!props.isDisabled && (
        <div className="row mt-3 mb-4">
          <div className="col-sm-12 text-end">
            {props.action === 'update' ? (
              <>
                <button className="btn btn-sm m-1 btn-danger" onClick={() => handleSaveButton(false)}>
                  Update
                </button>
              </>
            ) :  (
              <>
                <button className="btn btn-sm m-1 btn-danger" onClick={() => handleSaveButton(false)}>
                  Simpan & Keluar
                </button>
                <button className="btn btn-sm m-1 btn-primary" onClick={() => handleSaveButton(true)}>
                  Simpan & Buat Baru
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Penjodohan;


// const Penjodohan = (props) => {
//   const [soal, setSoal] = useState(null);
//   const [pasangan, setPasangan] = useState(null);
//   const [pengecoh, setPengecoh] = useState(null);

//   const prevSoalValue = useRef();
//   useEffect(() => {
//     if (props.defaultValue != null) {
//       if(!isEqual(prevSoalValue.current, soal)){
//         prevSoalValue.current = soal;
//         setSoal(props.defaultValue.pertanyaan);
//         setPasangan(props.defaultValue.pasangan);
//         setPengecoh(props.defaultValue.pengecoh);
//       }
//     }
//   }, [props.defaultValue])
//   const handleSaveButton = (redirect) => {
//     var params = {
//       "pertanyaan": soal,
//       "pasangan": pasangan,
//       "pengecoh": pengecoh

//     };
//     props.onSaveButton(params, redirect)

//     if (redirect) {
//       setSoal("");
//       setPasangan("");
//       setPengecoh("");
//     }
//   };

//   return (
//     <>
//       <div className="row">
//         <div className="col-sm-12">
//           <h5 className="text text-primary">
//             Pernyataan <span className="text-danger">*</span>
//           </h5>
//           <div className="col-xl-12 mt-2">
//             <SMEditor
//               placeholder="Tulis Pernyataan disini"
//               data={soal}
//               disabled={props.isDisabled}
//               onChange={(event, editor) => {
//                 const data = editor.getData();
//                 setSoal(data);
//               }}
//               onBlur={(event, editor) => {}}
//             />
//           </div>
//         </div>
//       </div>
//       <div className="col-sm-12">
//         <hr />
//       </div>

//       <div className="row mt-4">
//         <div className="col-sm-6">
//           <h5 className="text text-primary">
//             Pasangan <span className="text-danger">*</span>
//           </h5>
//           <div className="col-xl-12 mt-2">
//             <input
//               type="text"
//               className="form-control"
//               placeholder="Masukkan pasangan"
//               value={pasangan}
//               onChange={(e) => setPasangan(e.target.value)}
//               disabled={props.isDisabled}
//             />
//           </div>
//         </div>
//         <div className="col-sm-6">
//           <h5 className="text text-primary">Pengecoh</h5>
//           <div className="col-xl-12 mt-2">
//             <input
//               type="text"
//               className="form-control"
//               placeholder="Masukkan pengecoh"
//               value={pengecoh}
//               onChange={(e) => setPengecoh(e.target.value)}
//               disabled={props.isDisabled}
//             />
//           </div>
//         </div>
//       </div>
//       {props.isDisabled ? "" : (
//       <div className="row mt-3 mb-4">
//         <div className="col-sm-12 text-end">
//           {/* <button className="btn btn-sm m-1 btn-outline-primary">
//             Simpan Draft
//           </button> */}
//           {/* <button className="btn btn-sm m-1 btn-primary" onClick={handleSaveButton}>Simpan</button> */}
//           <button className="btn btn-sm m-1 btn-danger" onClick={() => handleSaveButton(false)}>Simpan & Keluar</button>
//           <button className="btn btn-sm m-1 btn-primary" onClick={() => handleSaveButton(true)}>Simpan & Buat Baru</button>
//         </div>
//       </div>
//       )}
//     </>
//   );
// };

// export default Penjodohan;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Pagination = ({ totalData, dataPerPage, onChange, visiblePageNumbers = 5, showEllipsis = true }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(totalData / dataPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    onChange(pageNumber); // Call the parent onChange function
  };

  const renderPageNumbers = () => {
    let pages = [];

    const halfVisible = Math.floor(visiblePageNumbers / 2);
    const startPage = Math.max(1, currentPage - halfVisible);
    const endPage = Math.min(totalPages, currentPage + halfVisible);

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
          <Link 
            to={"#"} 
            className="page-link" 
            onClick={() => handlePageChange(i)}
          >
            {i}
          </Link>
        </li>
      );
    }

    return pages;
  };

  return (
    <div className="table-pagination teach mt-3 gap-1" style={{ display: 'flex', alignItems: 'baseline' }}>
      <small>
        <span>{(currentPage - 1) * dataPerPage + 1}-{Math.min(currentPage * dataPerPage, totalData)}</span> / <span>{totalData}</span> data
      </small>
      <nav>
        <ul className="pagination pagination-gutter pagination-primary no-bg">
          <li className={`page-item page-indicator ${currentPage === 1 ? 'disabled' : ''}`}>
            <Link 
              to={"#"} 
              className="page-link" 
              onClick={() => currentPage > 1 && handlePageChange(currentPage - 1)}
            >
              <i className="fa-solid fa-chevron-left"></i>
            </Link>
          </li>

          {/* Show first page and ellipsis conditionally */}
          {showEllipsis && currentPage > visiblePageNumbers && (
            <>
              <li className="page-item">
                <Link to={"#"} className="page-link" onClick={() => handlePageChange(1)}>1</Link>
              </li>
              <li className="page-item disabled"><span className="page-link">...</span></li>
            </>
          )}

          {renderPageNumbers()}

          {/* Show last page and ellipsis conditionally */}
          {showEllipsis && currentPage < totalPages - visiblePageNumbers + 1 && (
            <>
              <li className="page-item disabled"><span className="page-link">...</span></li>
              <li className="page-item">
                <Link to={"#"} className="page-link" onClick={() => handlePageChange(totalPages)}>{totalPages}</Link>
              </li>
            </>
          )}

          <li className={`page-item page-indicator ${currentPage === totalPages ? 'disabled' : ''}`}>
            <Link 
              to={"#"} 
              className="page-link" 
              onClick={() => currentPage < totalPages && handlePageChange(currentPage + 1)}
            >
              <i className="fa-solid fa-chevron-right"></i>
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Pagination;

import { lazy, Suspense, useEffect } from "react";
/// Components
import Index from "./jsx/index";
import { connect, useDispatch } from "react-redux";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
// action
import { checkAutoLogin, isLogin } from "./services/AuthService";
import { isAuthenticated } from "./store/selectors/AuthSelectors";

/// Style
import "./other/swiper/css/swiper-bundle.min.css";
import "./other/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import LandingPage from "./jsx/components/Bursa/Index";
import { useCookies } from 'react-cookie';
import BursaKartuSoal from "./jsx/components/Bursa/BursaKartuSoal";
import BursaTryout from "./jsx/components/Bursa/BursaTryout";
import BursaPaketSoal from "./jsx/components/Bursa/BursaPaketSoal";
import SMEditor from "./jsx/components/Editor";
import Cart from "./jsx/components/Bursa/Cart";
import ForbiddenPage from "./Forbidden";

// require('dotenv').config()

const SignUp = lazy(() => import("./jsx/pages/Registration"));
const Login = lazy(() => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  checkAutoLogin(dispatch, navigate, true);
});

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();

    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

function App(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(['XSRF-TOKEN']);

  // console.log('cookie', cookies["XSRF-TOKEN"]);
  const location = useLocation();
  const withoutCredentialRouter = ['/', '/bursa/kartu-soal', '/bursa/paket-soal', '/bursa/tryout', '/test/editor', '/bursa/keranjang'];
  // console.log(location.pathname);
  useEffect(() => {
    console.log("App", location.pathname);
    if (withoutCredentialRouter.includes(location.pathname)) {
      checkAutoLogin(dispatch, navigate, false);
    }else{
      checkAutoLogin(dispatch, navigate, true);
    }
    // if (cookies["XSRF-TOKEN"] !== undefined) {
      // checkAutoLogin(dispatch, navigate, false);
    // }
    
  }, [location]);

  let routeblog = (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/bursa/kartu-soal" element={<BursaKartuSoal />} />
      <Route path="/bursa/paket-soal" element={<BursaPaketSoal />} />
      <Route path="/bursa/tryout" element={<BursaTryout />} />
      <Route path="/test/editor" element={<SMEditor />} />
      <Route path="/login" element={<Login />} />
      <Route path="/page-register" element={<SignUp />} />
      <Route path="/bursa/keranjang" element={<Cart />} />
      <Route path="*" element={<ForbiddenPage />} />
    </Routes>
  );
  if (props.isAuthenticated && props.user.group_code == "GU") {
    return (
      <>
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          <Index />
          {/* <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/bursa/keranjang" element={<Cart />} />
            <Route path="/bursa/kartu-soal" element={<BursaKartuSoal />} />
            <Route path="/bursa/paket-soal" element={<BursaPaketSoal />} />
            <Route path="/bursa/tryout" element={<BursaTryout />} />
          </Routes> */}
        </Suspense>
      </>
    );
  } else {
    return (
      <div className="vh-100">
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          {routeblog}
        </Suspense>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
    user: state.auth.auth,
  };
};

export default withRouter(connect(mapStateToProps)(App));

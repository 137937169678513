import React, { useEffect, useState } from "react";
import { Button, Form, Row, Col, Modal } from "react-bootstrap";
import "./AddPaketSoal.css";
import axiosInstance from "../../../services/AxiosInstance";

function Topik(props) {
  const [topics, setTopics] = useState(props.topics ?? []);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [butirSoal, setButirSoal] = useState(null);
  var noUrut = 1;
  const arrTable = {
    'pilihan-ganda' : "Pilihan Ganda",
    'benar-salah' : "Benar Salah",
    'penjodohan' : "Penjodohan",
    'sebab-akibat' : "Sebab Akibat",
    'uraian' : "Uraian",
  };
  
  const addRow = () => {
    const newTopic = {
      id: topics.length + 1,
      name: "",
      soal_ids: [], // Inisialisasi soal_ids sebagai array kosong
    };
    setTopics([...topics, newTopic]);
  };

  const handleInputChange = (index, event) => {
    const newTopics = [...topics];
    newTopics[index].name = event.target.value;
    setTopics(newTopics);
  };

  const handleBack = () => {
    props.handleBack('next');
  }

  const handleCheckboxChange = (topicId, cardNumber) => {
    setTopics((prevTopics) =>
      prevTopics.map((topic) => {
        if (topic.id === topicId) {
          // Tambahkan atau hapus kartu soal dari topik saat ini
          const isSelected = topic.soal_ids?.includes(cardNumber); // Tambahkan ? untuk menangani undefined
          const updatedCards = isSelected
            ? topic.soal_ids.filter((card) => card !== cardNumber) // Hapus kartu soal
            : [...(topic.soal_ids || []), cardNumber]; // Tambahkan kartu soal
          return { ...topic, soal_ids: updatedCards };
        } else {
          // Hapus kartu soal dari topik lain jika sedang dipilih
          const updatedCards = topic.soal_ids?.filter((card) => card !== cardNumber) || [];
          return { ...topic, soal_ids: updatedCards };
        }
      })
    );
  };

  const generateOutput = (status = false) => {
    const output = topics.map((topic, index) => ({
      no_urut: topic.id,
      code: `T${topic.id}`, // Generate kode topik
      name: topic.name,
      soal_ids: topic.soal_ids, // Ambil soal_ids sebagai soal_ids
    }));
    // alert(JSON.stringify(output, null, 2)); // Tampilkan hasil di popup
    props.onSaveButton(output, status);
  };


  const removeRow = (id) => {
    const updatedTopics = topics.filter((topic) => topic.id !== id);
    // Perbarui nomor urut topik setelah penghapusan
    setTopics(
      updatedTopics.map((topic, index) => ({
        ...topic,
        id: index + 1,
      }))
    );
  };

  
  const detailButirSoal = async (id) => {
    try {
      const response = await axiosInstance.get(`soal/${id}?with_jawaban=true`);
      var data = response.data.data;
      await setButirSoal(data);
      await setShowDetailModal(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="card">
      <div className="card-header">
        <h2 className="card-title">
          Topik
        </h2>
        <div className="card-toolbar">
          <Button variant="primary" size="sm" onClick={addRow}>
            Tambahkan Topik
          </Button>
        </div>
      </div>
      <div className="card-body">
        {/* <table className="table table-bordered table-stripped">
          <thead>
            <tr>
              <th>PG</th>
              <th>MPG</th>
              <th>SA</th>
              <th>BS</th>
              <th>UR</th>
              <th>PJ</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
            </tr>
          </tbody>
        </table> */}
        {Object.keys(props.arrSelectedSoal).map((key, keyIndex) => (
          <div className="table-responsive">
            <h5>{arrTable[key] ?? ''}</h5>
            <table className="table table-stripped table-bordered table-hover">
              <thead>
                <tr>
                  {topics.length > 0 && <th></th> }
                  <th style={{ width: "5px" }}>No</th>
                  <th style={{ whiteSpace: 'nowrap' }}>Nama Topik</th>
                  {props.arrSelectedSoal[key].map((item, itemIndex) => (
                    <th key={`${keyIndex}-${itemIndex}`} className="text-center px-0">
                      <b className="text-decoration-underline" style={{cursor: "pointer"}} onClick={() => detailButirSoal(item.id)}>{noUrut++}</b>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {topics.map((topic, index) => (
                  <tr key={topic.id}>
                      <td style={{ textAlign: "center", padding: "0px", margin: "0px", width: "0px" }}>
                          <i
                            className="fas fa-trash text-danger cursor-pointer"
                            style={{ fontSize: "15px" }}
                            onClick={() => removeRow(topic.id)} // Panggil fungsi hapus
                          ></i>
                      </td>
                    <td className="text-center">{topic.id}</td>
                    <td>
                      <Form.Control
                        style={{ width: "250px" }}
                        type="text"
                        placeholder="Nama Topik"
                        value={topic.name}
                        onChange={(e) => handleInputChange(index, e)}
                      />
                    </td>
                    {props.arrSelectedSoal[key].map((item, itemIndex) => (
                      <td key={`${keyIndex}-${itemIndex}`} className="px-0" style={{ textAlign: "center" }}>
                        <Form.Check
                          type="checkbox"
                          checked={topic.soal_ids?.includes(item.id)} // Gunakan ?. untuk mencegah error
                          onChange={() => handleCheckboxChange(topic.id, item.id)}
                        />
                      </td>
                    ))}
                  </tr>
                ))}
                {topics.length == 0 && (
                  <tr className="text-center">
                    <td colSpan={props.arrSelectedSoal[key].length + 2}>Belum Ada Topik</td>
                  </tr>
                )}
              </tbody>
            </table>
            <hr />
          </div>
        ))}
      </div>
      <div className="card-footer">
        <Row>
          <Col md={6}>

            <Button variant="secondary" size="sm" onClick={handleBack}>
              Sebelumnya
            </Button>
          </Col>
          <Col md={6} className="text-end d-flex justify-content-end">
            <Button variant="info" className="me-2" size="sm" onClick={() => generateOutput(true)}>Simpan Draft</Button>
            <Button variant="primary" size="sm" onClick={() => generateOutput(false)}>Simpan</Button>
          </Col>
        </Row>
      </div>
      
      <Modal dialogClassName="modal-lg" show={showDetailModal} onHide={() => setShowDetailModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Detail Butir Soal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {butirSoal != null ? (
            <>
              <div className="card">
                <div className="card-body py-0">
                  <h3>Informasi</h3>
                  <div className="d-flex justify-content-between">
                    <p><strong>Code : </strong> {butirSoal.code}</p>
                    <p><strong>Type : </strong> {butirSoal.type}</p>
                    <p><strong>Judul : </strong> {butirSoal.detail.materi}</p>
                  </div>
                </div>
              </div>

              <div className="card">
                {butirSoal.type == 'PG' || butirSoal.type == 'BS' || butirSoal.type == 'SA' ? (
                  <>
                    <div className="card-body py-0">
                      <h3>Soal</h3>
                      <h5><strong>Pertanyaan : </strong></h5>
                      <p dangerouslySetInnerHTML={{ __html: butirSoal.value.pertanyaan }}></p>
                      
                      <h5><strong>Pilihan : </strong></h5>
                      <table class="">
                        {Object.entries(butirSoal.value.pilihan).map(([key, value], index) => (
                          <tr key={index} className="d-flex justify-content-start align-items-start">
                            <td className="pe-2">{key.toUpperCase()}. </td> 
                            <td className="pe-2"><span dangerouslySetInnerHTML={{ __html: value }} /></td>
                          </tr>
                        ))}
                      </table>
                      
                      <p><strong>Jawaban : </strong> {butirSoal.value.jawaban.toUpperCase()}</p>
                    </div>
                  </>
                ) : ('')}
                {butirSoal.type == 'UR' ? (
                  <>
                    <div className="card-body py-0">
                      <h3>Soal</h3>
                      <h5><strong>Pertanyaan : </strong></h5>
                      <p dangerouslySetInnerHTML={{ __html: butirSoal.value.pertanyaan }}></p>
                      <h5><strong>Pembahasan : </strong></h5>
                      <p dangerouslySetInnerHTML={{ __html: butirSoal.value.pembahasan }}></p>
                    </div>
                  </>
                ) : ('')}
                {butirSoal.type == 'PJ' ? (
                  <>
                    <div className="card-body py-0">
                      <h3>Soal</h3>
                      <h5><strong>Pertanyaan : </strong></h5>
                      <p dangerouslySetInnerHTML={{ __html: butirSoal.value.pertanyaan }}></p>
                      <h5><strong>Pasangan : </strong></h5>
                      <p dangerouslySetInnerHTML={{ __html: butirSoal.value.pasangan }}></p>
                      <h5><strong>Pengecoh : </strong></h5>
                      <p dangerouslySetInnerHTML={{ __html: butirSoal.value.pengecoh }}></p>
                    </div>
                  </>
                ) : ('')}
                {butirSoal.type == 'MPG' ? (
                  <>
                    <div className="card-body py-0">
                      <h3>Soal</h3>
                      <h5><strong>Content:</strong></h5>
                      <p dangerouslySetInnerHTML={{ __html: butirSoal.value.content }}></p>
                      {Object.entries(butirSoal.value.soal).map(([key, value], index) => (
                        <table key={index} className="mt-4">
                            <tr>
                              <td colSpan={2}>
                                <h5><strong>Pertanyaan : </strong></h5>
                                <p dangerouslySetInnerHTML={{ __html: value.pertanyaan }}></p>
                              </td>
                            </tr>
                            {Object.entries(value.pilihan).map(([idx, row], key2) => (
                              <tr key={key2} className="d-flex justify-content-start align-items-start">
                                <td className="pe-2">{idx.toUpperCase()}. </td> 
                                <td className="pe-2"><span dangerouslySetInnerHTML={{ __html: row }} /></td>
                              </tr>
                            ))}
                            <tr><strong>Jawaban : </strong> {value.jawaban.toUpperCase()}</tr>
                        </table>
                      ))}
                      
                    </div>
                  </>
                ) : ('')}
              </div>
            </>
          ) : ('')}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-outline-secondary btn-sm" onClick={() => setShowDetailModal(false)}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Topik;

import React, { useEffect, useState } from "react";
import Select from "react-select";
import axiosInstance from "../../../../services/AxiosInstance";
import axiosBahanAjar from "../../../../services/axiosBahanAjar";

const Preview = (props) => {
  const [soal, setSoal] = useState([
    // Soal Pilihan Ganda
    // {
    //   type: "pilihan-ganda",
    //   soal: "Ibukota negara Indonesia adalah?",
    //   option: [
    //     { result: false, value: "Bandung" },
    //     { result: false, value: "Surabaya" },
    //     { result: true, value: "Jakarta" },
    //     { result: false, value: "Yogyakarta" },
    //     { result: false, value: "Medan" },
    //   ],
    // },
    // {
    //   type: "pilihan-ganda",
    //   soal: "Pancasila memiliki berapa sila?",
    //   option: [
    //     { result: true, value: "5" },
    //     { result: false, value: "4" },
    //     { result: false, value: "3" },
    //     { result: false, value: "6" },
    //     { result: false, value: "7" },
    //   ],
    // },
    // {
    //   type: "pilihan-ganda",
    //   soal: "Siapa penemu listrik?",
    //   option: [
    //     { result: false, value: "Alexander Graham Bell" },
    //     { result: true, value: "Thomas Alva Edison" },
    //     { result: false, value: "Nikola Tesla" },
    //     { result: false, value: "Albert Einstein" },
    //     { result: false, value: "James Watt" },
    //   ],
    // },
    // {
    //   type: "pilihan-ganda",
    //   soal: "Berapakah jumlah provinsi di Indonesia?",
    //   option: [
    //     { result: false, value: "30" },
    //     { result: false, value: "32" },
    //     { result: false, value: "34" },
    //     { result: true, value: "38" },
    //     { result: false, value: "40" },
    //   ],
    // },
    // {
    //   type: "pilihan-ganda",
    //   soal: "Siapakah penulis novel 'Laskar Pelangi'?",
    //   option: [
    //     { result: false, value: "Andrea Hirata" },
    //     { result: true, value: "Andrea Hirata" },
    //     { result: false, value: "Pramoedya Ananta Toer" },
    //     { result: false, value: "Habiburrahman El Shirazy" },
    //     { result: false, value: "Dewi Lestari" },
    //   ],
    // },
    // {
    //   type: "pilihan-ganda",
    //   soal: "Gunung tertinggi di Indonesia adalah?",
    //   option: [
    //     { result: true, value: "Puncak Jaya" },
    //     { result: false, value: "Gunung Rinjani" },
    //     { result: false, value: "Gunung Semeru" },
    //     { result: false, value: "Gunung Kerinci" },
    //     { result: false, value: "Gunung Merapi" },
    //   ],
    // },
    // {
    //   type: "pilihan-ganda",
    //   soal: "Pulau terbesar di Indonesia adalah?",
    //   option: [
    //     { result: true, value: "Kalimantan" },
    //     { result: false, value: "Sumatera" },
    //     { result: false, value: "Papua" },
    //     { result: false, value: "Jawa" },
    //     { result: false, value: "Sulawesi" },
    //   ],
    // },
    // {
    //   type: "pilihan-ganda",
    //   soal: "Siapakah tokoh Proklamator Indonesia selain Soekarno?",
    //   option: [
    //     { result: false, value: "Soeharto" },
    //     { result: true, value: "Mohammad Hatta" },
    //     { result: false, value: "Sutan Sjahrir" },
    //     { result: false, value: "Ki Hajar Dewantara" },
    //     { result: false, value: "Bung Tomo" },
    //   ],
    // },
    // {
    //   type: "pilihan-ganda",
    //   soal: "Sungai terpanjang di Indonesia adalah?",
    //   option: [
    //     { result: true, value: "Sungai Kapuas" },
    //     { result: false, value: "Sungai Musi" },
    //     { result: false, value: "Sungai Mahakam" },
    //     { result: false, value: "Sungai Brantas" },
    //     { result: false, value: "Sungai Barito" },
    //   ],
    // },
    // {
    //   type: "pilihan-ganda",
    //   soal: "Siapakah penemu telepon?",
    //   option: [
    //     { result: true, value: "Alexander Graham Bell" },
    //     { result: false, value: "Thomas Alva Edison" },
    //     { result: false, value: "Nikola Tesla" },
    //     { result: false, value: "Samuel Morse" },
    //     { result: false, value: "James Watt" },
    //   ],
    // },

    // // Soal Uraian
    // {
    //   type: "uraian",
    //   soal: "Jelaskan proses terjadinya gerhana matahari.",
    //   option: [],
    // },
    // {
    //   type: "uraian",
    //   soal: "Apa yang dimaksud dengan globalisasi dan dampaknya bagi budaya lokal?",
    //   option: [],
    // },
    // {
    //   type: "uraian",
    //   soal: "Jelaskan perbedaan antara hak asasi manusia dan kewajiban asasi manusia.",
    //   option: [],
    // },
    // {
    //   type: "uraian",
    //   soal: "Apa fungsi dari PBB (Perserikatan Bangsa-Bangsa)?",
    //   option: [],
    // },
    // {
    //   type: "uraian",
    //   soal: "Sebutkan dan jelaskan jenis-jenis energi terbarukan.",
    //   option: [],
    // },
  ]);

  // useEffect(() => {
  //   loadSoal();
  // }, [props.paketSoal]);

  // const loadSoal = async () => {
  //   // props.paketSoal.selectedPackage.id
  //   var res = await axiosBahanAjar.get('paket-ajar/'+ props.paketSoal.selectedPackage.id + '?with_content=true');

  //   console.log("res loadSoal : ", res);
  //   console.log("res loadSoal : ", res.data);
  //   setSoal(res.data.bahan_ajar_details	);
  // };

  

  return (
    <section>
      {/* {JSON.stringify(props.paketSoal)} */}
      <div className="row">
        <div className="col-12">
          <hr />
         
        </div>
      </div>
      <b>Preview Pembelajaran</b>
      {/* <p>Pilihlah jawaban antara (A / B / C / D ) dengan benar</p> */}
      { props.paketSoal != null && props.paketSoal.selectedPackage.bahan_ajar_id_list.map((soal, index) => (
        soal.items.map((v, i) => (
          <>
          <div className="row mt-5">
              <div className="col-1 text-center">
                <b>{i + 1}.</b>
              </div>
              <div className="col-11">
                <div className="col-soal mb-3">
                  <div dangerouslySetInnerHTML={{__html: v.topic}}></div>
                </div>
                {v.media == 'video' && (
                  <div className="col-soal mb-3">
                    <video width={"350px"} controls>
                      <source src={v.media_url} type="video/mp4" />
                    </video>
                  </div>
                )}

                {v.media == 'audio' && (
                  <div className="col-soal mb-3">
                    <audio width={"350px"} controls>
                      <source src={v.media_url} type="audio/mpeg" />
                    </audio>
                  </div>
                )}

                <span dangerouslySetInnerHTML={{__html: v.description}}></span>
              </div>
            </div>
          </>
        ))
      ))}
      <div className="row">
        <div className="col-12">
          <hr />
        </div>
      </div>
    </section>
  );
};

export default Preview;

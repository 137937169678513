import Swal from "sweetalert2";
import axiosInstance from "../../services/AxiosInstance";
import {
    Plugin,
    ButtonView,
    toWidget
} from 'ckeditor5';
// import { toWidget } from '@ckeditor/ckeditor5-widget/src/utils';

import { showLoading, closeLoading } from "../customs/loading/loading";
export class SMUploader {
    constructor(loader) {
        this.loader = loader;
    }

    upload() {
        return this.loader.file.then(file => new Promise(async (resolve, reject) => {
            console.log('SM Uploader');
            const formData = new FormData();
            formData.append('file', file);

            const response = await axiosInstance.request({
                method: "POST",
                url: `media/upload`,
                data: formData,
                headers: {
                  "Content-Type": "multipart/form-data"
                }
            });
            var data = response.data;
            if (data && data.url) {
                const fileUrl = data.url;
                const fileType = file.type;

                // Deteksi tipe file dan tambahkan elemen HTML yang sesuai
                if (fileType.startsWith('image/')) {
                    resolve({
                        default: fileUrl // Untuk gambar, cukup gunakan URL langsung
                    });
                } else if (fileType.startsWith('video/')) {
                    resolve({
                        default: `<video controls>
                            <source src="${fileUrl}" type="${fileType}">
                            Your browser does not support the video tag.
                        </video>`
                    });
                } else if (fileType.startsWith('audio/')) {
                    resolve({
                        default: `<audio controls>
                            <source src="${fileUrl}" type="${fileType}">
                            Your browser does not support the audio tag.
                        </audio>`
                    });
                } else {
                    reject('Unsupported file type');
                }
            } else {
                reject('Upload failed');
            }
        }));
    }

    abort() {
        // Implement abort jika perlu
    }
}


export class FileUploadPlugin extends Plugin {
    init() {
        const editor = this.editor;

        editor.model.schema.register('media', {
            allowWhere: '$block', // Allow `media` to appear where blocks are allowed
            isObject: true, // Treat it as an object (non-editable content)
            allowAttributes: ['src', 'type', 'controls', 'width', 'height'], // Allow these attributes
        });

        editor.model.schema.register('audio', {
            allowWhere: '$block',
            isObject: true,
            allowAttributes: ['src', 'controls', 'width', 'height']
        });

        // Conversion from model to view
        editor.conversion.for('upcast').elementToElement({
            model: 'media',
            view: {
                name: 'video',
                attributes: ['src', 'type', 'controls', 'width', 'height'] // Map these attributes
            }
        });

        editor.conversion.for('upcast').elementToElement({
            model: 'audio',
            view: {
                name: 'audio',
                attributes: ['src', 'controls', 'width', 'height']
            }
        });

        // Conversion from view to model
        editor.conversion.for('downcast').elementToElement({
            model: 'media',
            view: (modelElement, { writer }) => {
                const videoElement = writer.createContainerElement('video', {
                    src: modelElement.getAttribute('src'),
                    type: modelElement.getAttribute('type'),
                    controls: modelElement.getAttribute('controls') || true,
                    width: modelElement.getAttribute('width') || 'auto',
                    height: modelElement.getAttribute('height') || 'auto'
                });

                return toWidget(videoElement, writer);
            }
        });

        editor.conversion.for('downcast').elementToElement({
            model: 'audio',
            view: (modelElement, { writer }) => {
                return writer.createContainerElement('audio', {
                    src: modelElement.getAttribute('src'),
                    controls: true,
                    width: modelElement.getAttribute('width') || '300',
                    height: modelElement.getAttribute('height') || '50'
                });
            }
        });

        // editor.plugins.get('WidgetResize').attachTo({
        //     unit: 'px',
        //     editor,
        //     viewElement: 'video', // Elemen video yang akan di-resize
        //     modelElement: 'media',
        //     getResizeHost(viewElement) {
        //         return viewElement; // Target elemen untuk resizing
        //     },
        //     isCentered() {
        //         return true; // Pilihan apakah video di-center atau tidak
        //     },
        //     onCommit(newValue, oldValue, viewWriter, viewElement) {
        //         const writer = editor.model.change(writer => {
        //             writer.setAttribute('width', newValue.width, viewElement);
        //             writer.setAttribute('height', newValue.height, viewElement);
        //         });
        //     }
        // });

        

        editor.ui.componentFactory.add('fileUpload', locale => {
            const view = new ButtonView(locale);

            view.set({
                label: 'Upload File',
                // icon: null, // Tambahkan ikon sesuai kebutuhan
                icon: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-upload" viewBox="0 0 16 16"> <path d="M.5 9.9a.5.5 0 0 1 .5-.4h4V3.5a.5.5 0 0 1 1 0V9.5h4a.5.5 0 0 1 .4.9l-4 4.5a.5.5 0 0 1-.8 0l-4-4.5z"/> <path d="M4.8 10.1a.5.5 0 0 1 .4-.9h2.4V2.5a.5.5 0 0 1 1 0v6.7h2.4a.5.5 0 0 1 .4.9l-4 4.5a.5.5 0 0 1-.8 0l-4-4.5z"/> </svg>',
                tooltip: true
            });

            // Event ketika tombol ditekan
            view.on('execute', () => {
                const input = document.createElement('input');
                input.type = 'file';
                input.accept = 'image/*,audio/*,video/*'; // Batasi jenis file
                input.click();

                input.onchange = () => {
                    const file = input.files[0];
                    if (file) {
                        showLoading();
                        // Kirim file ke server
                        const formData = new FormData();
                        formData.append('file', file);

                        axiosInstance.post('media/upload', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }})
                            // .then(response => response.json())
                            .then(data => {
                                console.log('uploaded : ', data);
                                closeLoading();
                                if (data.data && data.data.url) {
                                    const fileUrl = data.data.url;
                                    const fileType = file.type;

                                    // Masukkan elemen ke editor berdasarkan tipe file
                                    if (fileType.startsWith('image/')) {
                                        editor.model.change(writer => {
                                            const imageElement = writer.createElement('imageBlock', {
                                                src: fileUrl
                                            });
                                            editor.model.insertContent(imageElement, editor.model.document.selection);
                                        });
                                    } else if (fileType.startsWith('video/')) {
                                        editor.model.change(writer => {
                                        const videoElement = writer.createElement('media', {
                                            src: fileUrl,
                                            type: 'video/mp4',
                                            controls: true,
                                            width: 400,
                                            height: 200
                                        });
                                        editor.model.insertContent(videoElement, editor.model.document.selection);
                                        });
                                    } else if (fileType.startsWith('audio/')) {
                                        editor.model.change(writer => {
                                            const audioElement = writer.createElement('audio', {
                                                src: fileUrl,
                                                controls: true,
                                                width: '300', // Ukuran default
                                                height: '50' // Ukuran default
                                            });
                                            editor.model.insertContent(audioElement, editor.model.document.selection);
                                        });
                                    }
                                }
                            })
                            .catch(err => {
                                closeLoading();
                                console.error('Upload failed:', err);
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: `${err.response.data.message}`,
                                })
                            });
                    }
                };
            });

            return view;
        });
    }
}
import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../../../services/AxiosInstance";

const NilaiAnalisisUjian = () => {
  const { id_ujian } = useParams();
  const [dataStatistik, setDataStatistik] = useState(null);

  const navigate = useNavigate();

  const [ujianData, setUjianData] = useState(null);


  const detailUjian = async () => {
    var res = await axiosInstance.get(`ujian/${id_ujian}`);
    var data = res.data.data;
    setUjianData({
      kartu_soal_code: data.paket_soal.code,
      ujian_code: data.code,
      kelas: data.paket_soal.detail.kelas,
      pemilik: data.paket_soal.created_by,
      jurusan: data.paket_soal.detail.jurusan,
      jenjang: data.paket_soal.detail.jenjang,
      mata_pelajaran: data.paket_soal.detail.jenis_kelompok_mata_pelajaran,
      waktu_pengerjaan: data.started_at,
      rombel: data.detail.rombel,
    })
    getStatistik();
  }
  
  // use effect
  useEffect(() => {
    detailUjian();
  }, []);

  const getStatistik = async () => {
    try {
      const response = await axiosInstance.get(`statistik/paket-soal/ujian/${id_ujian}`);
      const data = response.data.data;
      setDataStatistik(data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="col-12">
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">Detail Ujian</h3>
        </div>
        <div className="card-body">
          <div className="col-11">
            {ujianData != null && (
              <>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="mb-1">
                    <b className="mb-0">ID Kartu Soal :</b>
                    <p className="mb-0">{ujianData.kartu_soal_code}</p>
                  </div>
                  <div className="mb-1">
                    <b className="mb-0">ID Paket Soal :</b>
                    <p className="mb-0">{'-'}</p>
                  </div>
                  <div className="mb-1">
                    <b className="mb-0">ID Daftar Ujian :</b>
                    <p className="mb-0">{ujianData.ujian_code}</p>
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  
                  <div className="mb-1">
                    <b className="mb-0">Jenjang :</b>
                    <p className="mb-0">{ujianData.jenjang}</p>
                  </div>
                  <div className="mb-1">
                    <b className="mb-0">Kelas :</b>
                    <p className="mb-0">{ujianData.kelas}</p>
                  </div>
                  <div className="mb-1">
                    <b className="mb-0">Mata Pelajaran :</b>
                    <p className="mb-0">{ujianData.mata_pelajaran}</p>
                  </div>
                </div>
                <div>
                  <div className="mb-1">
                    <b className="mb-0">Pemilik :</b>
                    <p className="mb-0">{ujianData.pemilik}</p>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header d-flex justify-content-between flex-wrap">
          <h3 className="card-title">Statistik Capaian, Realibel dan Validitas</h3>
        </div>
        <div className="card-body table-responsive">
          {dataStatistik != null ? (
            <table className="table table-bordered table-stripped table-hover">
              <thead>
                <tr className="text-center">
                  <th rowSpan="2">No.</th>
                  <th rowSpan="2">ID Daftar Ujian</th>
                  <th rowSpan="2">ID Paket Soal</th>
                  <th rowSpan="2">NPSN</th>
                  <th rowSpan="2">Nama Sekolah</th>
                  <th rowSpan="2">Jen</th>
                  <th rowSpan="2">Jur</th>
                  <th rowSpan="2">Kls</th>
                  <th rowSpan="2">Jur</th>
                  <th rowSpan="2">Rombel</th>
                  <th rowSpan="2">Waktu Ujian</th>
                  <th rowSpan={2}>Jml</th>
                  <th colSpan="10">Sebaran Nilai</th>
                  <th colSpan={Object.entries(dataStatistik.value.score_rerata_nilai_per_topik).length > 0 && Object.entries(dataStatistik.value.score_rerata_nilai_per_topik).length}>Skor rerata nilai per topik</th>
                  <th colSpan={Object.entries(dataStatistik.value.score_rerata_presentase_nilai_per_topik).length > 0 && Object.entries(dataStatistik.value.score_rerata_presentase_nilai_per_topik).length}>Capain rerata nilai dalam % per topik</th>
                  <th colSpan={Object.entries(dataStatistik.value.capain_rerata_memenuhi_kkm_per_topik).length > 0 && Object.entries(dataStatistik.value.capain_rerata_memenuhi_kkm_per_topik).length}>Capain rerata memenuhi KKM Per Topik</th>
                  <th rowSpan="2">KKM</th>
                  <th rowSpan="2">Status</th>
                  {Object.entries(dataStatistik.value.reabilitas).length > 0 && (
                    Object.entries(dataStatistik.value.reabilitas).map(([key, value], index) => (
                      <>
                        <th colSpan="4">Reabilitas Soal Pilihan Ganda</th>
                        <th colSpan="2">Jumlah PG</th>
                      </>
                    ))
                  )}
                </tr>
                <tr>
                  <th>10%</th>
                  <th>20%</th>
                  <th>30%</th>
                  <th>40%</th>
                  <th>50%</th>
                  <th>60%</th>
                  <th>70%</th>
                  <th>80%</th>
                  <th>90%</th>
                  <th>100%</th>
                  {Object.entries(dataStatistik.value.score_rerata_nilai_per_topik).length > 0 ? (
                    Object.entries(dataStatistik.value.score_rerata_nilai_per_topik).map(([key, value], index) => (
                      <th>{key}</th>
                    ))
                  ) : (<th>Tidak ada topik</th>)}
                  {Object.entries(dataStatistik.value.score_rerata_presentase_nilai_per_topik).length > 0 ? (
                    Object.entries(dataStatistik.value.score_rerata_presentase_nilai_per_topik).map(([key, value], index) => (
                      <th>{key}</th>
                    ))
                  ) : (<th>Tidak ada topik</th>)}
                  {Object.entries(dataStatistik.value.capain_rerata_memenuhi_kkm_per_topik).length > 0 ? (
                    Object.entries(dataStatistik.value.capain_rerata_memenuhi_kkm_per_topik).map(([key, value], index) => (
                      <th>{key}</th>
                    ))
                  ) : (<th>Tidak ada topik</th>)}
                  {Object.entries(dataStatistik.value.reabilitas).length > 0 && (
                    Object.entries(dataStatistik.value.reabilitas).map(([key, value], index) => (
                      <>
                        <th>KR.20</th>
                        <th>Status</th>
                        <th>KR.21</th>
                        <th>Status</th>
                        <th>Valid</th>
                        <th>Invalid</th>
                      </>
                    ))
                  )}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{dataStatistik.id}</td>
                  <td>{dataStatistik.code}</td>
                  <td>{dataStatistik.ujian.paket_soal_code}</td>
                  <td>{dataStatistik.ujian.code}</td>
                  <td>{dataStatistik.ujian.created_by_user.detail.npsn}</td>
                  <td>{dataStatistik.ujian.created_by_user.detail.profile.institusi.nama_institusi}</td>
                  <td>{dataStatistik.ujian.created_by_user.detail.profile.institusi.jenjang.jenjang}</td>
                  <td>{dataStatistik.ujian.detail.kelas}</td>
                  <td>{ujianData.jurusan}</td>
                  <td>{dataStatistik.ujian.detail.rombel}</td>
                  <td>{dataStatistik.ujian.started_at}</td>
                  <td>{dataStatistik.data.length}</td>
                  <td>{dataStatistik.value.sebaran_nilai['10']}</td>
                  <td>{dataStatistik.value.sebaran_nilai['20']}</td>
                  <td>{dataStatistik.value.sebaran_nilai['30']}</td>
                  <td>{dataStatistik.value.sebaran_nilai['40']}</td>
                  <td>{dataStatistik.value.sebaran_nilai['50']}</td>
                  <td>{dataStatistik.value.sebaran_nilai['60']}</td>
                  <td>{dataStatistik.value.sebaran_nilai['70']}</td>
                  <td>{dataStatistik.value.sebaran_nilai['80']}</td>
                  <td>{dataStatistik.value.sebaran_nilai['90']}</td>
                  <td>{dataStatistik.value.sebaran_nilai['100']}</td>
                  {Object.entries(dataStatistik.value.score_rerata_nilai_per_topik).length > 0 ? (
                    Object.entries(dataStatistik.value.score_rerata_nilai_per_topik).map(([key, value], index) => (
                      <td>{value.toFixed(2)}</td>
                    ))
                  ) : (
                    <td>-</td>
                  )}
                  {Object.entries(dataStatistik.value.score_rerata_presentase_nilai_per_topik).length > 0 ? (
                    Object.entries(dataStatistik.value.score_rerata_presentase_nilai_per_topik).map(([key, value], index) => (
                      <td>{value.toFixed(2)}</td>
                    ))
                  ) : (
                    <td>-</td>
                  )}
                  {Object.entries(dataStatistik.value.capain_rerata_memenuhi_kkm_per_topik).length > 0 ? (
                    Object.entries(dataStatistik.value.capain_rerata_memenuhi_kkm_per_topik).map(([key, value], index) => (
                      <td>{value}</td>
                    ))
                  ) : (
                    <td>-</td>
                  )}
                  <td>{dataStatistik.value.kkm}</td>
                  <td>{dataStatistik.value.status}</td>
                  {Object.entries(dataStatistik.value.reabilitas).length > 0 && (
                    Object.entries(dataStatistik.value.reabilitas).map(([key, value], index) => (
                      <>
                        <td>{value['kr20']}</td>
                        <td>{value['kr20_kategori']}</td>
                        <td>{value['kr21']}</td>
                        <td>{value['kr20_kategori']}</td>
                        <td>{value['jumlah_valid']}</td>
                        <td>{value['jumlah_invalid']}</td>
                      </>
                    ))
                  )}
                </tr>
              </tbody>
            </table>
          ) : (
            <div className="col-12 text-center">
              <p>Tidak ada data</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NilaiAnalisisUjian;

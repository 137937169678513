import React, { useState, useRef, useEffect } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import axiosInstance from "../../../../services/AxiosInstance";
import {
  Badge,
  Modal,
  Button,
  Row,
  Col
} from "react-bootstrap";
import Swal from "sweetalert2";
import "./DetailHasil.css";

const DetailHasilTestOnline = (props) => {
  const inputEl = useRef(null);

  const { id_ujian } = useParams();
  const [searchParams] = useSearchParams();
  const type_page = searchParams.get('type_page');

  const [data, setData] = useState(
    document.querySelectorAll("#job_data tbody tr")
  );

  const getLocalStorage = (key) => {
    const item = localStorage.getItem(key);
    return item ?? null;
  };

  const setLocalStorage = (key, value) => {
    localStorage.setItem(key, value);
  };


  const sort = 100;
  const activePag = useRef(0);

  const [showPage, setShowPage] = useState(type_page ?? 'nilai');

  const [showKomposisi, setShowKomposisi] = useState(false);

  const chageData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };

  const handleShow = (param) => {
    setLocalStorage("showPage", param);
    setShowPage(param);
  };
  
  const handleShowKomposisi = () => {
    setShowKomposisi(!showKomposisi);
  };
  // use effect
  useEffect(() => {
    setData(document.querySelectorAll("#job_data tbody tr"));
  }, []);

  // Active pagginarion
  activePag.current === 0 && chageData(0, sort);
  // paggination
  let paggination = Array(Math.ceil(data.length / sort))
    .fill()
    .map((_, i) => i + 1);

  // Active paggination & chage data
  const onClick = (i) => {
    activePag.current = i;
    chageData(activePag.current * sort, (activePag.current + 1) * sort);
    //settest(i);
  };



  //LOAD DATA
  const [studentsData, setStudentsData] = useState(null);
  const [numberOfQuestions, setNumberOfQuestions] = useState(0);
  const [numberOfQuestionsUr, setNumberOfQuestionsUr] = useState(0);
  const [showKoreksiModal, setShowKoreksiModal] = useState(false);
  useEffect(() => {
    loadDataJawaban()
  }, [])
  const loadDataJawaban = async () => {
    var res = await axiosInstance.get(`ujian/${id_ujian}`);
    var data = res.data.data;
    loadPaketSoalDetail(data.paket_soal_id)
    var tmp = []
    for(var i = 0; i < data.peserta.length; i++){
      var response = []
      for(var a = 0; a < data.peserta[i].value.length; a++){
        response.push(data.peserta[i].value[a])
      }
      tmp.push({
        id: data.peserta[i].id,
        kelas: data.peserta[i].detail.kelas,
        rombel: data.peserta[i].detail.rombel,
        nama_siswa: data.peserta[i].user.name,
        status: data.peserta[i].status,
        responses: response,
        score: data.peserta[i].score,
        nilai_bobot: data.peserta[i].nilai_bobot,
        presentase_nilai_bobot: data.peserta[i].presentase_nilai_bobot,
        ranking: data.peserta[i].ranking
      })
    }
    let numberUr = [];
    numberUr = tmp[0]?.responses.find((soal) => soal.type == 'UR');
    console.log(numberUr.length)
    setNumberOfQuestionsUr(numberUr.length)
    setNumberOfQuestions(tmp[0]?.responses.length || 0)
    setStudentsData(tmp)
  }

  const [selectedPaketSoal, setSelectedPaketSoal] = useState(null);
  const loadPaketSoalDetail = async (id_paket_soal) => {
    var res = await axiosInstance.get(`paket-soal/${id_paket_soal}?with_content=true`);
    console.log('res : ', res)
    setSelectedPaketSoal(res.data.data)
  }

  const [selectedResponse, setSelectedResponse] = useState(null);
  const [selectedStudentId, setSelectedStudentId] = useState(null);
  const handleShowKoreksiModal = (studentId, response) => {
    setSelectedStudentId(studentId);
    setSelectedResponse(response);
    setShowKoreksiModal(true);
  };

  const handleCloseKoreksiModal = () => {
    setSelectedStudentId(null);
    setSelectedResponse(null);
    setShowKoreksiModal(false);
  };

  const handleKirimKoreksi = async (result, code) => {
    try {
      console.log("Kirim koreksi", result, code);
      var params = {
        "code": code,
        "result": result
      }
      var res = await axiosInstance.put(`test-online/guru/correcting-answer/${selectedStudentId}`, params);
      
      handleCloseKoreksiModal();
      loadDataJawaban();

      Swal.fire({
        icon: 'success',
        title: 'Koreksi Berhasil',
        showConfirmButton: true,
      })
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'Koreksi Gagal',
        text: error.message,
        showConfirmButton: true,
      })
    }
    
  };

  const getTypeCode = (type) => {
    if (type == "PG") {
      return "Pilihan Ganda";
    }else if (type == "MPG") {
      return "Multiple Pilihan Ganda";
    } else if (type == "BS") {
      return "Benar Salah";
    } else if (type == "UR") {
      return "Uraian";
    } else if (type == "PJ") {
      return "Penjodohan";
    } else if (type == "SA") {
      return "Sebab Akibat";
    }
  };

  const [nilaiValue, setNilaiValue] = useState(5);
  const handleChangeNilai = (e) => {
    setNilaiValue(e.target.value);
  };
  const handleExportToExcel = async (tipe) => {
    try {
      var urlAPI = ''

      if (tipe == "nilai") {
        //ujian/export/jawaban/id ujian
        urlAPI = `ujian/export/jawaban/${id_ujian}`
      }


      if (tipe == "rekap") {
        //ujian/export/hasil-ujian/id ujian
        urlAPI = `ujian/export/hasil-ujian/${id_ujian}`
      }
      
      // Meminta file Excel dari backend
      const response = await axiosInstance.get(urlAPI, {
        responseType: 'arraybuffer', // Pastikan response diterima dalam format arraybuffer
      });
  
      // Membuat Blob dari data response
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  
      // Membuat URL object untuk file Blob
      const url = window.URL.createObjectURL(blob);
  
      // Membuat elemen anchor untuk memicu unduhan
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', tipe + "_" + id_ujian +'.xlsx'); // Nama file yang akan diunduh
      document.body.appendChild(link);
  
      // Memicu klik untuk mengunduh file
      link.click();
  
      // Membersihkan URL object setelah digunakan
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading Excel file', error);
    }
  };

  const [isAscending, setIsAscending] = useState(true);

  const handleSort = (type) => {
    if (type == "nilai") {
      const sorted = [...studentsData].sort((a, b) =>
        isAscending ? a.nilai_bobot - b.nilai_bobot : b.nilai_bobot - a.nilai_bobot
      );
      setStudentsData(sorted);
      setIsAscending(!isAscending);
    } else if (type == "nilai_persen") {
      const sorted = [...studentsData].sort((a, b) =>
        isAscending ? a.presentase_nilai_bobot - b.presentase_nilai_bobot : b.presentase_nilai_bobot - a.presentase_nilai_bobot
      );
      setStudentsData(sorted);
      setIsAscending(!isAscending);
    } else if (type == "ranking") {
      const sorted = [...studentsData].sort((a, b) =>
        isAscending ? a.ranking - b.ranking : b.ranking - a.ranking
      );
      setStudentsData(sorted);
      setIsAscending(!isAscending);
    };
  };
  
  return (
    <div className="col-12">
      <div className="card dz-card">
        <div className="card-header d-flex justify-content-between flex-wrap">
          <div>
            <div className="card-title">{showPage == 'jawaban' ? 'Penilaian Soal Uraian' : 'Daftar Nilai dan analisis Hasil Ujian'}</div>
          </div>
          <ul
            as="ul"
            className={`nav nav-tabs dzm-tabs ${type_page != null ? 'd-none' : ''}`}
            id="myTab"
            role="tablist"
          >
            <li as="li" className="nav-item">
              <button
                className={`nav-link ${showPage == "nilai" ? "active" : ""}`}
                type="button"
                onClick={() => handleShow("nilai")}
              >
                Hasil Jawaban Siswa
              </button>
            </li>
            <li as="li" className="nav-item" role="presentation">
              <button
                className={`nav-link ${showPage == "jawaban" ? "active" : ""}`}
                type="button"
                onClick={() => handleShow("jawaban")}
              >
                Nilai Per Butir
              </button>
            </li>
            <li as="li" className="nav-item" role="presentation">
              <button
                className={`nav-link ${showPage == "bobot" ? "active" : ""}`}
                type="button"
                onClick={() => handleShow("bobot")}
              >
                Hasil Nilai Bobot
              </button>
            </li>
            <li as="li" className="nav-item" role="presentation">
              <button
                className={`nav-link ${showPage == "bobot_persen" ? "active" : ""}`}
                type="button"
                onClick={() => handleShow("bobot_persen")}
              >
                Hasil Nilai Persen
              </button>
            </li>
            <li as="li" className="nav-item" role="presentation">
              <button
                className={`nav-link ${showPage == "rekap" ? "active" : ""}`}
                type="button"
                onClick={() => handleShow("rekap")}
              >
                Rekap Hasil Nilai
              </button>
            </li>
            
          </ul>
        </div>
        <div className="card-body">
          <div className="col-12 text-end">
            {showPage == "nilai" && <button className="btn btn-primary btn-sm" onClick={() => handleExportToExcel('nilai')}>Export to Excel</button>}
            {showPage == "rekap" && <button className="btn btn-primary btn-sm" onClick={() => handleExportToExcel('rekap')}>Export to Excel</button>}
          </div>
          {showPage == "nilai" && <div className="card-body">
            <div className="table-responsive">
              <div id="job_data" className="dataTables_wrapper">
                <table
                  className="display w-100 dataTable table-bordered"
                  id="example5"
                  role="grid"
                  aria-describedby="example5_info"
                >
                  <thead>
                    <tr role="row">
                      <th className="sorting_asc ">No</th>
                      <th className="sorting ">Kelas</th>
                      <th className="sorting ">Rombel</th>
                      <th className="sorting ">Nama Peserta</th>
                      {/* <th className="text-center ">Nilai Akhir</th> */}
                      {Array.from({length: numberOfQuestions}, (_, index) => (
                        <th key={index}>{index + 1}</th>
                      ))}
                    </tr>
                  </thead>

                  <tbody>
                  {studentsData?.map((student, index) => (
                    <tr key={index + 1}>
                      <td>
                        <strong>{index + 1}</strong>
                      </td>
                      <td>{student.kelas}</td>
                      <td>{student.rombel}</td>
                      <td>{student.nama_siswa}</td>
                      {/* Render responses for each question */}
                      {/* {JSON.stringify(student.responses[0])} */}
                      {student.responses?.map((response, index) => (
                        <td key={index}>
                          {/* {typeof response.jawaban} */}
                          {/* {response.jawaban == null ? 'null' : 'ada'} */}
                          {response.jawaban != null ? (
                            <Badge onClick={response.type == "UR" ? () => handleShowKoreksiModal(student.id, response) : null} as="a" bg="badge-lg" bg={response.type == "UR" && response.status == null  ? "warning" : response.type == "UR" && response.status != null ? "success" : response.jawaban == "ragu" ? "warning" : response.status == "benar" ? "success" : "danger"}>
                              {response.type == "UR" && response.status == null ? <i className="fas fa-eye"></i> : response.type == "UR" && response.status != null ? "Sudah dikoreksi" : response.jawaban}
                            </Badge>
                          ) : (
                            ""
                          )}
                        </td>
                      ))}
                      <td>
                        {student.status == "selesai" ? (
                          <Badge bg="success">{student.status}</Badge>
                        ) : (
                          <Badge bg="danger">{student.status}</Badge>
                        )}
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          }

          {(showPage == "jawaban" || showPage == "bobot" || showPage == "bobot_persen" || showPage == "rekap") && (
            <>
              {showPage != "jawaban" || numberOfQuestionsUr != 0 ? (
                <div className="table-responsive">
                  <div id="job_data" className="dataTables_wrapper">
                    <table
                      className="display w-100 dataTable table-bordered"
                      id="example5"
                      role="grid"
                      aria-describedby="example5_info"
                    >
                      <thead>
                        <tr role="row">
                          <th className="sorting_asc">No</th>
                          <th className="sorting ">Kelas</th>
                          <th className="sorting ">Rombel</th>
                          <th className="sorting ">Nama Peserta</th>
                          {/* <th className="text-center ">Nilai Akhir</th> */}
                          {Array.from({length: showPage == 'jawaban' ? numberOfQuestionsUr : numberOfQuestions}, (_, index) => (
                            <th key={index}>{index + 1}</th>
                          ))}
                          {(showPage == "bobot" || showPage == "bobot_persen") && <th className="sorting ">Nilai Akhir</th>}
                          {showPage == "rekap" && <th className="sorting " onClick={() => {
                            handleSort("nilai")
                          }}>Nilai</th>}
                          {showPage == "rekap" && <th className="sorting " onClick={() => {
                            handleSort("nilai_persen")
                          }}>Nilai %</th>}
                          {showPage == "rekap" && <th className="sorting " onClick={() => {
                            handleSort("ranking")
                          }}>Ranking</th>}
                        </tr>
                      </thead>

                      <tbody>
                        {studentsData?.map((student, index) => (
                          <tr key={index + 1}>
                            <td>
                                <strong>{index + 1}</strong>
                            </td>
                            <td>{student.kelas}</td>
                            <td>{student.rombel}</td>
                            <td>{student.nama_siswa}</td>
                            {/* Render responses for each question */}
                            {/* {JSON.stringify(student.responses[0])} */}
                            {student.responses?.map((response, index) => (
                              showPage != "jawaban" || response.type == "UR" && (
                                <td key={index}>
                                  {parseFloat(showPage == "jawaban" || showPage == "rekap" ? (
                                    response.nilai
                                  ) : (
                                    showPage == "bobot" ? response.nilai_bobot : response.presentase_nilai_bobot).toFixed(2)
                                  )}
                                  {(showPage == "jawaban" && response.type == "UR") && <i className="fa fa-pencil" aria-hidden="true" onClick={() => handleShowKoreksiModal(student.id, response)}></i>}
                                </td>
                              )
                            ))}
                            {(showPage == "bobot" || showPage == "bobot_persen") && <td>
                                {parseFloat(showPage == "bobot" ? student.nilai_bobot : student.presentase_nilai_bobot).toFixed(2)}
                            </td>
                            }
                            {(showPage == "rekap") && <td>
                                {parseFloat(student.nilai_bobot).toFixed(2)}
                            </td>
                            }
                            {(showPage == "rekap") && <td>
                                {parseFloat(student.presentase_nilai_bobot).toFixed(2)}
                            </td>
                            }
                            {(showPage == "rekap") && <td> {student.ranking} </td>}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : (
                <div className="text-center">
                  <p>Tidak ada data {showPage == 'jawaban' && 'soal uraian'}</p>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <Modal className="fade bd-example-modal-lg" size="md" show={showKoreksiModal}>
			  <Modal.Header>
          <Modal.Title>{showPage == 'nilai' ? 'Jawaban' : 'Koreksi Jawaban'}</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={handleCloseKoreksiModal}
          >
				</Button>
			  </Modal.Header>
			  <Modal.Body>
          <Row>
            <Col lg={3}>Kunci</Col>
            <Col style={{display: 'inline-flex'}}>: &nbsp; {selectedPaketSoal && selectedResponse && <span dangerouslySetInnerHTML={{__html: selectedPaketSoal.value.filter((v) => v.code == selectedResponse.code)[0].value.pembahasan ?? "Tidak ada kunci"}}></span>}</Col>
          </Row>
          <Row>
            <Col lg={3}>Jawaban</Col>
            {selectedResponse?.jawaban == null && <Col>: Tidak ada Jawaban</Col>}
            {selectedResponse?.jawaban != null && <Col style={{display: 'inline-flex'}}>: &nbsp; <span dangerouslySetInnerHTML={{__html: selectedResponse?.jawaban}}></span></Col>}
          </Row>
          {showPage == 'jawaban' && <Row>
            <Col lg={3}>Nilai</Col>
            <Col>: {nilaiValue}</Col>
          </Row>}
          {showPage == 'jawaban' && <Row>
            <Col><input
                type="range"
                className="form-range"
                id="rangeInput"
                min="0"
                max="10"
                value={nilaiValue}
                onChange={handleChangeNilai}
              /></Col>
          </Row>
          }
        </Modal.Body>
			  {showPage == 'jawaban' && <Modal.Footer>
				{/* <Button variant="danger" onClick={()=>handleKirimKoreksi(nilaiValue, selectedResponse.code)}>Salah</Button> */}
				<Button variant="primary" onClick={()=>handleKirimKoreksi(nilaiValue, selectedResponse.code)}>Simpan</Button>
			  </Modal.Footer>}
			</Modal>
    </div>
  );
};

export default DetailHasilTestOnline;

import React from 'react';
import { Container, Row, Col, Table, Button } from 'react-bootstrap';
import { useCart } from '../../../services/CartContext';
import HeaderBursa from './HeaderBursa';
import Swal from 'sweetalert2';
import { useLoading } from '../Global/LoadingProvider';
import axiosInstance from '../../../services/AxiosInstance';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { isAuthenticated } from '../../../store/selectors/AuthSelectors';
import { connect } from 'react-redux';
function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();

    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}
const Cart = (props) => {
  const { cart, dispatch } = useCart();
  const { setLoading } = useLoading();
  

  const calculateTotal = () => {
    return cart.reduce((total, item) => total + item.price * item.quantity, 0);
  };

  const handleRemove = (id) => {
    dispatch({ type: 'REMOVE_FROM_CART', payload: id });
  };

  const checkoutTransaction = async (data) => {
    try {
      const response = await axiosInstance.post('/transaction', data);

      Swal.fire({
        title: 'Berhasil',
        text: 'Checkout berhasil',
        icon: 'success',
        confirmButtonText: 'OK'
      })
    } catch (error) {
      let errorMessage = "An error occurred.";
      if (error.response) {
        errorMessage = `${error.response.data.message || error.response.data}`;
      } else if (error.request) {
        errorMessage = "No response from server. Please check your connection.";
      } else {
        errorMessage = error.message;
      }

      // Display the error message in a SweetAlert2 popup
      Swal.fire({
        title: "Oops",
        text: errorMessage,
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  }

  const handleCheckout = () => {
    if(!props.isAuthenticated){
      Swal.fire({
        title: 'Oops',
        text: 'Silahkan login terlebih dahulu',
        icon: 'error',
        confirmButtonText: 'OK'
      })
      return;
    }
    setLoading(true);
    console.log('Proceed to checkout');
    cart.forEach(async (e) => {
      console.log('rosid soal', e);
      var params = {
        type: e.jenis == "Butir Soal" ? "soal" : "paket-soal",
        content_id: e.id,
      }
      await checkoutTransaction(params);
    });
    setLoading(false);
    
    dispatch({ type: 'CLEAR_CART' });
    // if(calculateTotal() > 0){
    //   Swal.fire({
    //     title: 'Oops',
    //     text: 'Saat ini metode pembayaran sedang dalam proses pengembangan',
    //     icon: 'error',
    //     confirmButtonText: 'OK'
    //   })
    // }
  };

  return (
    <>
        <HeaderBursa />
        <Container>
      <h1 className="my-4">Shopping Cart</h1>
      <Row>
        <Col md={8}>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Tipe</th>
                <th>Item</th>
                <th>Harga</th>
                <th>Jumlah</th>
                <th>Subtotal</th>
                <th>Aksi</th>
              </tr>
            </thead>
            <tbody>
              {cart.map((item, index) => (
                <tr key={item.id}>
                  <td>{index + 1}</td>
                  <td>{item.jenis}</td>
                  <td>{item.judul}</td>
                  <td>{item.price}</td>
                  <td>{item.quantity}</td>
                  <td>{item.price * item.quantity}</td>
                  <td>
                    <Button 
                      variant="danger" 
                      size="sm" 
                      onClick={() => handleRemove(item.id)}
                    >
                      Hapus
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
        <Col md={4}>
          <div className="p-3 border rounded">
            <h4>Ringkasan Keranjang</h4>
            <p>Total Items: {cart.length}</p>
            <h5>Total: {calculateTotal()}</h5>
            <Button 
              variant="primary" 
              className="w-100 mt-3" 
              onClick={handleCheckout}
            >
              Checkout
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  console.log('mapStateToProps cart', state);
  return {
    isAuthenticated: isAuthenticated(state),
    user: state.auth.auth,
  };
};

export default withRouter(connect(mapStateToProps)(Cart));
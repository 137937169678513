import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import profile from "../../../images/user.jpg";
import LogoutPage from "../../layouts/nav/Logout";
import LogoSite from "../../../images/site-logo.png";

import { connect, useDispatch } from "react-redux";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
// action
import { checkAutoLogin, isLogin } from "../../../services/AuthService";
import { isAuthenticated } from "../../../store/selectors/AuthSelectors";
import Swal from "sweetalert2";
import { useCart } from "../../../services/CartContext";

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();

    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

const HeaderBursa = (props) => {
  console.log('HeaderBursa', props);
  const location = useLocation();
  const path = location.pathname.split('/').pop();

  const [itemCount, setItemCount] = useState(0);
  const [isCollapsed, setIsCollapsed] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {cart} = useCart();

  // const total = cart.reduce((acc, item) => acc + item.quantity, 0);
  // useEffect(() => {
  //   checkAutoLogin(dispatch, navigate);
  // }, []);


  // Function to toggle navbar collapse state
  const toggleNavbar = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    console.log('cart', cart);
    // return;
    setItemCount(cart.reduce((acc, item) => acc + item.quantity, 0));
    console.log('itemCount', itemCount);
  }, [cart]);

  const handleLogin = () => {
    checkAutoLogin(dispatch, navigate, true);
  };

  const handleButtonPersonal = () => {
    if (props.user.group_code == "GU") {
      navigate("/dashboard");
    } else {
      Swal.fire({
        title: "Oops !",
        text: "Mohon maaf, anda tidak bisa masuk ke pembuatan Paket Soal dan Bahan Ajar dikarenakan hanya Guru (Gu) yang bisa mengaksesnya, Silahkan ganti dengan akun guru untuk mengaksesnya",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oke !",
      })
    }
  };
  
  return (
    <>
    <nav className="header-bursa navbar navbar-expand-lg navbar-light bg-light">
      <div className="container">
        <Link className="navbar-brand" to="/"><img src={LogoSite} alt="" width={'90%'} /></Link>
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleNavbar} 
          aria-controls="navbarNav"
          aria-expanded={!isCollapsed ? 'true' : 'false'}
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className={`collapse navbar-collapse ${isCollapsed ? '' : 'show'}`} 
          id="navbarNav"
        >
          
        <ul className="navbar-nav ms-auto align-items-center">
          <li className={'nav-item'}>
            {" "}
            <Link className={path == 'kartu-soal' ? 'active nav-link' : 'nav-link'} to={"/bursa/kartu-soal"}>
              {" "}
              <b className="text-light">Kartu Soal</b>{" "}
            </Link>{" "}
          </li>
          <li className={'nav-item'}>
            {" "}
            <Link className={path == 'paket-soal' ? 'active nav-link' : 'nav-link'} to={"/bursa/paket-soal"}>
              {" "}
              <b className="text-light">Paket Soal</b>{" "}
            </Link>{" "}
          </li>
          <li className={'nav-item'}>
            {" "}
            <Link className={path == 'tryout' ? 'active nav-link' : 'nav-link'} to={"/bursa/tryout"}>
              {" "}
              <b className="text-light">TryOut</b>{" "}
            </Link>{" "}
          </li>
          <li class="nav-item">
            {" "}
            <Link className="nav-link" to={"/bursa/keranjang"}>
              {" "}
              <b className="text-light" style={{ position: "relative", display: "inline-block" }}>
                <i className="fas fa-shopping-cart fa-2x"></i>
                {itemCount > 0 && (
                  <span
                    style={{
                      position: "absolute",
                      top: "-10px",
                      right: "-10px",
                      background: "red",
                      color: "white",
                      borderRadius: "50%",
                      padding: "0px 6px",
                      fontSize: "10px",
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {itemCount}
                  </span>
                )}
              </b>{" "}
            </Link>{" "}
          </li>

          {props.isAuthenticated ? (
            <li className="nav-item ">
              <Dropdown className="dropdown header-profile2">
                <Dropdown.Toggle
                  variant=""
                  as="a"
                  className="nav-link i-false c-pointer ms-0"
                >
                  <div className="header-info2 d-flex align-items-center">
                    <img src={profile} alt="" />
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu
                  align="end"
                  className="mt-1 dropdown-menu dropdown-menu-end"
                >
                  <div className="card mb-0">
                    <div className="card-header p-3">
                      <ul className="d-flex align-items-center">
                        <li className="ms-2">
                          <h4 className="mb-0">{ props.user.name }</h4>
                          <span>{props.user.group_code}</span>
                        </li>
                      </ul>
                    </div>
                    <div className="card-body p-3">
                      <div onClick={handleButtonPersonal} style={{ cursor: "pointer" }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                          className="svg-main-icon"
                        >
                          <g
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                          >
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path
                              d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
                              fill="#000000"
                              fillRule="nonzero"
                              opacity="0.3"
                            />
                            <path
                              d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
                              fill="var(--primary)"
                              fillRule="nonzero"
                            />
                          </g>
                        </svg>
                        <span className="ms-2">Personal </span>
                      </div>
                    </div>
                    <div className="card-footer text-center p-3">
                      <LogoutPage />
                    </div>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </li>
          ) : (
            <li class="nav-item" onClick={() => handleLogin() }>
                {" "}
                <b className="btn btn-outline-light text-light">
                  Log In
                </b>{" "}
              {" "}
            </li>
          )}
        </ul>
        </div>
      </div>
    </nav>
    </>
  );
};

const mapStateToProps = (state) => {
  console.log('mapStateToProps header bursa', state);
  return {
    isAuthenticated: isAuthenticated(state),
    user: state.auth.auth,
  };
};

export default withRouter(connect(mapStateToProps)(HeaderBursa));

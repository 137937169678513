import React, { useState, useRef, useEffect } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import axiosInstance from "../../../../services/AxiosInstance";
import { TESTONLINE_HOSTNAME } from "../../../../constant";
import Swal from "sweetalert2";
import Pagination from "../../Pagination";
import { formatDateTime } from "../../../utils/dateFormat";

const PreviewTestOnline = () => {
  const { id_ujian } = useParams();
  const [searchParams] = useSearchParams();
  const type_page = searchParams.get('type_page');
  
  const [activeTab, setActiveTab] = useState('peserta');
  const [soal, setSoal] = useState([]);
  const [bentukSoal, setBentukSoal] = useState([]);
  const [topikSoal, setTopikSoal] = useState([]);
  const [totalSoal, setTotalSoal] = useState(0);
  const [totalBobot, setTotalBobot] = useState(0);
  const [detailUjian, setDetailUjian] = useState(null);
  const [detailPaketSoal, setDetailPaketSoal] = useState(null);
  const [peserta, setPeserta] = useState([]);

  const getTypeName = (type) => {
    if (type == "PG") {
      return "pilihan-ganda";
    } else if (type == "MPG") {
      return "multiple-pilihan-ganda";
    } else if (type == "BS") {
      return "benar-salah";
    } else if (type == "UR") {
      return "uraian";
    } else if (type == "PJ") {
      return "penjodohan";
    } else if (type == "SA") {
      return "sebab-akibat";
    }
  };

  const getUjianDetail = async () => {
    var res = await axiosInstance.get(`ujian/${id_ujian}`);
    setDetailUjian(res.data.data);
    setPeserta(res.data.data.peserta)
    getPaketSoal(res.data.data.paket_soal.id);
  };

  const getPaketSoal = async (id_paket) => {
    var res = await axiosInstance.get(`paket-soal/${id_paket}?with_content=true`);
    var paketSoal = res.data.data.value;
    var topikSoal = res.data.data.topik;
    var scoreSoal = Object.values(res.data.data.score);

    var tmp = []
    var topik = []
    var bentuk = []

    const totalBobot = scoreSoal.reduce((sum, soal) => sum + soal.bobot, 0);
    const totalSoal = scoreSoal.reduce((sum, soal) => sum + soal.jumlah_soal, 0);
    setTotalSoal(totalSoal);
    setTotalBobot(totalBobot);

    const keys = Object.keys(res.data.data.score);
    for (var i = 0; i < scoreSoal.length; i++) {
      let bobot = scoreSoal[i].bobot / totalBobot;
      let newObjt = {
        sps: scoreSoal[i].jumlah_soal / scoreSoal[i].bobot,
        bb: bobot * 100,
        spsp: bobot / scoreSoal[i].jumlah_soal,
      };

      bentuk.push({
        type: keys[i],
        name: getTypeName(keys[i]),
        ...scoreSoal[i],
        sps : newObjt.sps,
        bb : newObjt.bb,
        spsp : newObjt.spsp,
      });
    }

    for (var i = 0; i < paketSoal.length; i++) {
      tmp.push({
        type: getTypeName(paketSoal[i].type),
        ...paketSoal[i]
      })
    }
    
    for (var i = 0; i < topikSoal.length; i++) {
      const soalIds = topikSoal[i].soal_ids;
      const filteredSoal = paketSoal.filter(soal => soalIds.includes(soal.id));

      let typeCounts = {
        PG: 0,
        MPG: 0,
        BS: 0,
        PJ: 0,
        SA: 0,
        UR: 0,
      };
    
      filteredSoal.forEach(soal => {
        if (typeCounts[soal.type] !== undefined) {
          typeCounts[soal.type]++;
        }
      });

      topik.push({
        code: topikSoal[i].code, 
        name: topikSoal[i].name, 
        no_urut: topikSoal[i].no_urut, 
        pg: typeCounts.PG,
        mpg: typeCounts.MPG,
        bs: typeCounts.BS,
        pj: typeCounts.PJ,
        sa: typeCounts.SA,
        ur: typeCounts.UR,
        total: topikSoal[i].soal_ids.length,
      })
    }

    setBentukSoal(bentuk);
    setDetailPaketSoal(res.data.data);
    setSoal(tmp);
    setTopikSoal(topik);
  }


  useEffect(() => {
    getUjianDetail();
  }, []);
  return (
    <>
      {type_page == 'preview' ? (
        <div className="card">
          <div className="card-header card-header-komposisi">
            <h4 className="card-title">Preview Soal</h4>
            <div className="card-toolbar">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${activeTab === "peserta" ? "active" : ""}`}
                    onClick={() => setActiveTab("peserta")}
                  >
                    Daftar Peserta
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${activeTab === "soal" ? "active" : ""}`}
                    onClick={() => setActiveTab("soal")}
                  >
                    Soal Ujian
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div className="card-body">
            <div className="tab-content mt-3">
              <div className={activeTab === "peserta" ? "tab-pane fade show active" : "tab-pane fade"}>
                <div className="table-responsive">
                  <table className="table table-bordered table-stripped table-hover">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>ID Paket Soal</th>
                        <th>ID Daftar Ujian</th>
                        <th>NISN</th>
                        <th>Nama Siswa</th>
                      </tr>
                    </thead>
                    <tbody>
                      {peserta != null ? (
                        peserta.map((v, i) => (
                          <tr key={i}>
                            <td>{i+1}</td>
                            <td>{v.paket_soal_code}</td>
                            <td>{v.paket_soal_code}</td>
                            <td>{v.user.name}</td>
                            <td>{v.user.name}</td>
                          </tr>
                        ))) : (
                          <>
                            <div className="col-12 text-center">
                              <p>Belum ada peserta</p>
                            </div>
                          </>
                        )
                      }
                    </tbody>
                  </table>
                </div>
              </div>
              <div className={activeTab === "soal" ? "tab-pane fade show active" : "tab-pane fade"}>
                {detailUjian == null ? (
                  <>
                    <div className="col-12 text-center">
                      <p>Tidak ada Soal</p>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-12">
                      <table className="w-100 nm">
                        <tr>
                          <td className="p-2 text-dark">
                            <b>Perangkai</b>
                          </td>
                          <td className="p-2 text-dark">
                            <b>: {detailUjian?.created_by_user?.name ?? '-'}</b>
                          </td>
                          <td className="p-2 text-dark">
                            <b>ID Ujian</b>
                          </td>
                          <td className="p-2 text-dark">
                            <b>: {detailUjian.code}</b>
                          </td>
                          <td className="p-2 text-dark">
                            <b>ID Paket Soal</b>
                          </td>
                          <td className="p-2 text-dark">
                            <b>: {detailUjian.paket_soal_code}</b>
                          </td>
                          <td className="p-2 text-dark">
                            <b>Nama Ujian</b>
                          </td>
                          <td className="p-2 text-dark">
                            <b>: {detailUjian.detail.nama}</b>
                          </td>
                        </tr>
                        <tr>
                          <td className="p-2 text-dark">
                            <b>Rombel</b>
                          </td>
                          <td className="p-2 text-dark">
                            <b>: {detailUjian.detail.rombel}</b>
                          </td>
                          <td className="p-2 text-dark">
                            <b>Ruangan</b>
                          </td>
                          <td className="p-2 text-dark">
                            <b>: {detailUjian.detail.ruangan}</b>
                          </td>
                          <td className="p-2 text-dark">
                            <b>Token</b>
                          </td>
                          <td className="p-2 text-dark">
                            <b>: {detailUjian.detail.token}</b>
                          </td>
                        </tr>
                        <tr>
                          <td className="p-2 text-dark">
                            <b>Status</b>
                          </td>
                          <td className="p-2 text-dark">
                            <b>: 
                              {detailUjian.status == "finished" && (<>
                                <div className="badge ms-2 badge-success">Selesai</div>
                              </>)}
                              {detailUjian.status == "created" && (<>
                                <div className="badge ms-2 badge-secondary">Draft</div>
                              </>)}
                              {detailUjian.status == "started" && (<>
                                <div className="badge ms-2 badge-secondary">Berlangsung</div>
                              </>)}
                            </b>
                          </td>
                          <td className="p-2 text-dark">
                            <b>Durasi</b>
                          </td>
                          <td className="p-2 text-dark">
                            <b>: {detailUjian.paket_soal.detail.durasi / 60} (menit)</b>
                          </td>
                          <td className="p-2 text-dark">
                            <b>Platform</b>
                          </td>
                          <td className="p-2 text-dark">
                            <b>: {detailUjian.type}</b>
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div className="col-sm-12 my-4">
                      <div className="row">
                        <div className="col-5">
                          {" "}
                          <hr />
                        </div>
                        <div className="col-2 text-center">
                          <h5 className="text-primary mt-1">Preview Paket Soal</h5>
                        </div>
                        <div className="col-5">
                          {" "}
                          <hr />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <section>
                        {soal.map((v, i) => (
                          <div key={i}>
                            {/* Petunjuk untuk soal pilihan ganda hanya pada soal pilihan ganda pertama */}
                            {i ===
                              soal.findIndex(
                                (item) => item.type === "PG"
                              ) &&
                              (v.type === "PG") && (
                                <>
                                  <b>I. Bentuk Soal Pilihan Ganda</b>
                                  <p>
                                    Pilihlah salah satu jawaban yang dianggap benar!
                                  </p>
                                </>
                              )}

                            {/* Tampilkan soal pilihan ganda */}
                            {v.type === "PG" || v.type === "SA" || v.type === "PJ" || v.type === "BS" ? (
                              <div className="row mt-3 d-flex" style={{ alignItems: "start" }}>
                                <div className="col-1 text-center">
                                  <b>{i + 1}.</b>
                                </div>
                                <div className="col-11">
                                  <div className="col-soal mb-3">
                                    <div dangerouslySetInnerHTML={{__html: v.value.pertanyaan}}></div>
                                  </div>
                                  {v.type == "PJ" && <div className="col-option mt-2">
                                      <span>Pasangan : {v.value.pasangan}</span><br/>
                                      <span>Pengecoh : {v.value.pengecoh}</span>
                                    </div>}
                                  {v.type != "PJ" && Object.keys(v.value.pilihan).map((val, index) => (
                                    <div
                                      className="col-option d-flex mt-2"
                                      key={index}
                                    >
                                      <input
                                        type="radio"
                                        className="me-3"
                                        readOnly
                                        checked={v.value.jawaban == val}
                                      />
                                      <div dangerouslySetInnerHTML={{__html: v.value.pilihan[val]}}></div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            ) : ""}

                            {v.type === "MPG" && (
                              <>
                              <div className="row mt-2">
                                <div className="col-1 text-center">
                                  <b></b>
                                </div>
                                <div className="col-11">
                                  <div className="col-soal mb-3">
                                    <div dangerouslySetInnerHTML={{__html: v.value.content}}></div>
                                  </div>
                                </div>
                              </div>
                              { v.value.soal.map((soal, soalIndex) => (
                                <div className="row mt-2" key={soalIndex}>
                                  <div className="col-1 text-center">
                                    <b>{i + 1}.</b>
                                  </div>
                                  <div className="col-11">
                                    <div className="col-soal mb-3">
                                      <div dangerouslySetInnerHTML={{__html: soal.pertanyaan}}></div>
                                    </div>
                                    {Object.keys(soal.pilihan).map((val, index) => (
                                      <div
                                        className="col-option d-flex mt-2"
                                        key={index}
                                      >
                                        <input
                                          type="radio"
                                          className="me-3"
                                          readOnly
                                          checked={soal.jawaban == val}
                                        />
                                        <div dangerouslySetInnerHTML={{__html: soal.pilihan[val]}}></div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              ))}
                              
                              </>
                            )}

                            {i ===
                              soal.findIndex((item) => item.type === "UR") &&
                              v.type === "UR" && (
                                <div className="row mt-3">
                                  <div className="col-sm-12">
                                    <div className="mt-5">
                                      <b>II. Bentuk Soal Uraian</b>
                                      <p>Jawablah pertanyaan di bawah ini!</p>
                                    </div>
                                  </div>
                                </div>
                              )}

                            {v.type === "UR" && (
                              <div className="row mt-2">
                                <div className="col-1 text-center">
                                  <b>{i + 1}.</b>
                                </div>
                                <div className="col-11">
                                  <div className="col-soal mb-3">
                                    <div dangerouslySetInnerHTML={{__html: v.value.pertanyaan}}></div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                        <div className="row">
                          <div className="col-12">
                            <hr />
                          </div>
                        </div>
                      </section>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Properti Paket Soal</h4>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-12 mb-4">
                {detailPaketSoal != null && (
                  <table className="w-100 nm">
                    <tr>
                      <td className="p-2 text-dark">
                        <b>Perangkai</b>
                      </td>
                      <td className="p-2 text-dark">
                        <b>: {detailPaketSoal?.created_by_user?.name ?? '-'}</b>
                      </td>
                      <td className="p-2 text-dark">
                        <b>ID Paket Soal</b>
                      </td>
                      <td className="p-2 text-dark">
                        <b>: {detailPaketSoal.code}</b>
                      </td>
                      <td className="p-2 text-dark">
                        <b>Kurikulum</b>
                      </td>
                      <td className="p-2 text-dark">
                        <b>: {detailPaketSoal.detail.kurikulum}</b>
                      </td>
                    </tr>
                    <tr>
                      <td className="p-2 text-dark">
                        <b>Jenjang</b>
                      </td>
                      <td className="p-2 text-dark">
                        <b>: {detailPaketSoal.detail.jenjang}</b>
                      </td>
                      <td className="p-2 text-dark">
                        <b>Kelas</b>
                      </td>
                      <td className="p-2 text-dark">
                        <b>: {detailPaketSoal.detail.kelas}</b>
                      </td>
                      <td className="p-2 text-dark">
                        <b>Jurusan</b>
                      </td>
                      <td className="p-2 text-dark">
                        <b>: {detailPaketSoal.detail.jurusan}</b>
                      </td>
                    </tr>
                    <tr>
                      <td className="p-2 text-dark">
                        <b>Durasi</b>
                      </td>
                      <td className="p-2 text-dark">
                        <b>: {detailPaketSoal.detail.mata_pelajaran}</b>
                      </td>
                      <td className="p-2 text-dark">
                        <b>Durasi</b>
                      </td>
                      <td className="p-2 text-dark">
                        <b>: {detailPaketSoal.detail.durasi / 60} (menit)</b>
                      </td>
                      <td className="p-2 text-dark">
                        <b>Platform</b>
                      </td>
                      <td className="p-2 text-dark">
                        <b>: {detailPaketSoal.type}</b>
                      </td>
                    </tr>
                  </table>
                )}
              </div>
              <div className="col-8 mt-2 table-responsive">
                <table
                  className="table table-bordered table-stripped table-hover mt-2"
                  id="example5"
                  role="grid"
                  aria-describedby="example5_info"
                >
                  <thead>
                    <tr role="row">
                      <th className="px-2">Bentuk Soal</th>
                      <th className="px-2">N</th>
                      <th className="px-2">Bb</th>
                      <th className="px-2">NB</th>
                      <th className="px-2">NS</th>
                      <th className="px-2">NK</th>
                      <th className="px-2">SpS</th>
                      <th className="px-2">Bb%</th>
                      <th className="px-2">SpS %</th>
                    </tr>
                  </thead>

                  <tbody>
                    {bentukSoal.map((v, i) => (
                      <tr key={i}>
                        <td className="px-2">{v.name.replaceAll('-', ' ')}</td>
                        <td className="px-2">{v.jumlah_soal}</td>
                        <td className="px-2">{v.bobot}</td>
                        <td className="px-2">{v.point_benar}</td>
                        <td className="px-2">{v.point_salah}</td>
                        <td className="px-2">{v.point_kosong}</td>
                        <td className="px-2">{v.sps.toFixed(2)}</td>
                        <td className="px-2">{v.bb + '%'}</td>
                        <td className="px-2">{v.spsp.toFixed(2) + '%'}</td>
                      </tr>
                    ))}

                    <tr>
                      <td className="px-2">Jumlah Soal</td>
                      <td className="px-2">{totalSoal}</td>
                      <td colSpan={5} className="px-2">{totalBobot}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-4">
                <ul className="mt-3">
                  <li className="mb-1">
                    <strong>N :</strong> Jumlah Kartu per Bentuk Soal
                  </li>
                  <li className="mb-1">
                    <strong>Bb :</strong> Nilai Bobot Skor untuk jenis butir soal tertentu dalam paket soal ujian
                  </li>
                  <li className="mb-1">
                    <strong>TB :</strong> Total Jumlah Bobot jenis Butir Soal
                  </li>
                  <li className="mb-1">
                    <strong>NB :</strong> Skor nilai Butir Soal jika dijawab benar
                  </li>
                  <li className="mb-1">
                    <strong>NS :</strong> Skor nilai Butir Soal jika dijawab salah
                  </li>
                  <li className="mb-1">
                    <strong>NK :</strong> Skor nilai Butir Soal jika tidak dijawab
                  </li>
                  <li className="mb-1">
                    <strong>SpS :</strong> Nilai skor per butir soal = Bb/N
                  </li>
                  <li className="mb-1">
                    <strong>Bb% :</strong> Nilai Bobot Skor untuk bentuk soal tertentu dalam paket soal ujian dalam % = Bb1 / Total Bobot
                  </li>
                </ul>
              </div>
              <div className="col-12 mt-2 table-responsive">
                <table
                  className="table table-bordered table-stripped table-hover mt-2"
                  id="example5"
                  role="grid"
                  aria-describedby="example5_info"
                >
                  <thead>
                    <tr role="row">
                      <th className="px-2">Nama Topik</th>
                      <th className="px-2">Kode</th>
                      <th className="px-2">PG</th>
                      <th className="px-2">MPG</th>
                      <th className="px-2">SA</th>
                      <th className="px-2">PJ</th>
                      <th className="px-2">BS</th>
                      <th className="px-2">UR</th>
                      <th className="px-2">Total</th>
                    </tr>
                  </thead>

                  <tbody>
                    {topikSoal != null ? (
                      topikSoal.map((v, i) => (
                        <tr key={i}>
                          <td className="px-2">{v.name}</td>
                          <td className="px-2">{v.code}</td>
                          <td className="px-2">{v.pg}</td>
                          <td className="px-2">{v.mpg}</td>
                          <td className="px-2">{v.sa}</td>
                          <td className="px-2">{v.pj}</td>
                          <td className="px-2">{v.bs}</td>
                          <td className="px-2">{v.ur}</td>
                          <td className="px-2">{v.total}</td>
                        </tr>
                      ))
                    ) : (
                      <>
                        <tr className="px-2" colSpan="3">Tidak ada Topik</tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PreviewTestOnline;
import React, { useEffect, useImperativeHandle, useState } from "react";
import axiosInstance from "../../../../services/AxiosInstance";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import Pagination from "../../Pagination";
import axiosBahanAjar from "../../../../services/axiosBahanAjar";

const PilihPaketSoal = React.forwardRef((props, ref) => {
  console.log("props PilihPaketSoal : ", props);
  useImperativeHandle(ref, () => ({
    getData() {
      return getFormData()
    }
  }))
  
  const [activePs, setActivePs] = useState(null);

  const [packageList, setPackageList] = useState([]);
  const [showStyle, setShowStyle] = useState("card");
  const [title, setTitle] = useState(null);
  const [kkm, setKkm] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [description, setDescription] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(12);

  useEffect(() => {
    loadData();
  }, [props.defaultData, currentPage]);

  const loadData = async () => {
    try {
      const response = await axiosBahanAjar.get(`paket-ajar?per_page=${perPage}&page=${currentPage}`);
      var data = response.data.data;
      console.log("response.data:", response.data);
      console.log("data:", data);
      
      setPackageList(data.items);
      setTotal(data.meta.totalItems);
      if(props.defaultData.title != null) {
        setTitle(props.defaultData.title);
        setDescription(props.defaultData.description);
        console.log("props.defaultData : ", props.defaultData.selectedPackage);
        // console.log("packageList : ", tmp);
        // console.log("index : ", tmp.findIndex(item => item.id == props.defaultData.selectedPackage.id));
          setSelectedPackage(props.defaultData.selectedPackage);
          // setActivePs(tmp.findIndex(item => item.id == props.defaultData.selectedPackage.id));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getNonZeroKeys = (score) => {
    return Object.keys(score).filter(key => {
        return Object.values(score[key]).every(value => value !== 0);
    });
  };


  const handleSelectedPackage = (contact, index) => {
    setSelectedPackage(contact);
    setActivePs(index);
  };

  const { typeujian } = useParams();

  const getFormData = async () => {
    console.log("typeujian : ", typeujian);
    console.log("getFormData : ", selectedPackage);

    if (title == null) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Nama Ujian harus diisi'
      })
      return null;
    }

    // if (kkm == null) {
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'Oops...',
    //     text: 'KKM harus diisi'
    //   })
    //   return null;
    // }

    // if (kkm <= 0) {
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'Oops...',
    //     text: 'KKM tidak boleh <= 0'
    //   })
    //   return null;
    // }

    if(selectedPackage == null) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Pilih Paket Soal Terlebih Dahulu'
      })
      return null;
    }

    if (typeujian == "test-online") {
      if (selectedPackage.type != "test-online/usbk") {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Pilih Paket Soal dengan Type test-online/usbk'
        })
        return null;
      }
    }

    if (description == null) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Deskripsi Ujian harus diisi'
      })
      return null;
    }

    return {
      title: title,
      selectedPackage: selectedPackage,
      description: description,
      // kkm: kkm
    };
  };

  const onPaging = (page) => {
    setCurrentPage(page);
  }

  return (
    <section>
      <div className="row">
        <div className="col-sm-12 mb-2">
          <div className="form-group mb-3">
            <label className="text-label" htmlFor="examName">
              Nama Pembelajaran *
            </label>
            <input
              type="text"
              id="examName"
              name="examName"
              className="form-control"
              placeholder="Masukkan nama pembelajaran"
              required
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="col-sm-6 mb-2">
          {/* <div className="form-group mb-3">
            <label className="text-label" htmlFor="questionPackage">
              KKM *
            </label>
            <div className="d-flex">
              <input
                type="text"
                id="questionPackage"
                name="questionPackage"
                className="form-control me-2"
                placeholder="Masukkan nilai KKM"
                required
                value={kkm}
                onChange={(e) => {
                  setKkm(e.target.value);
                }}
              />
              <button className="btn btn-outline-primary">Cari</button>
            </div>
          </div> */}
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="form-group mb-3">
            <label className="text-label" htmlFor="examName">
              Deskripsi Pembelajaran *
            </label>
            <input
              type="textarea"
              id="examName"
              name="examName"
              className="form-control"
              placeholder="Masukkan deskripsi pembelajaran"
              required
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <hr />
        </div>
      </div>
      <div className="row">
        {packageList.map((contact, index) => (
          <div
            onClick={() => handleSelectedPackage(contact, index)}
            className="col-xl-3 col-lg-4 col-sm-6"
            key={index}
          >
            <div className={`card card-select-p text-center ${activePs == index ? 'card-s-active' : ''}`}>
              <div className="card-body">
                <div className="user-content">
                  <div className="user-info">
                    <div className="div">
                      <h6 className="text-muted">{contact.id_paket_ajar}</h6>
                    </div>
                    <div className="user-details">
                      <h4 className="user-name mb-0">
                        {contact.topic}
                      </h4>
                      {/* <p>{contact.type}</p> */}
                      <p>by {contact.creator}</p>
                    </div>
                  </div>
                </div>
                {/* <div className="contact-icon mb-1">
                  {contact.jenis_soal.map((value, key) => (
                    <span className={`badge m-1 badge-${value.color}`}>
                      {value.name}
                    </span>
                  ))}
                </div> */}
              </div>
            </div>
          </div>
        ))}

        <Pagination totalData={total} dataPerPage={perPage} onChange={onPaging} />
      </div>
      <div className="row">
        <div className="col-12">
          <hr />
        </div>
      </div>
    </section>
  );
});

export default PilihPaketSoal;

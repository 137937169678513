import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { checkAutoLogin } from './AuthService';
import { useEffect } from 'react';
import { HOSTNAME } from '../constant';
import Swal from "sweetalert2";

axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;
axios.defaults.xsrfCookieName = "XSRF-TOKEN";
axios.defaults.xsrfHeaderName = "X-XSRF-TOKEN";
const axiosInstance = axios.create({
    baseURL: HOSTNAME,
    withCredentials: true,
    withXSRFToken: true,
    proxy: {
        protocol: 'https',
        host: 'https://maudev.schoolmedia.my.id',
        // hostname: '127.0.0.1' // Takes precedence over 'host' if both are defined
        // port: 9000,
        // auth: {
        //   username: 'mikeymike',
        //   password: 'rapunz3l'
        // }
    }
});

axiosInstance.interceptors.request.use((config) => {
    // config.headers['Content-Type'] = 'application/json';
    console.log('config', config);
    return config;
})



axiosInstance.interceptors.response.use(
    (response) => {
        console.log('response instance : ', response);
        return response;
    },
    (error) => {
        if (error.response) {
            const status = error.response.status;
            if (status === 422) {
                const errorMessages = error.response.data.errors || {};
                let errorText = '';
                for (const field in errorMessages) {
                    errorText += `${field}: ${errorMessages[field].join(', ')}\n`;
                }
                Swal.fire({
                    icon: 'error',
                    title: 'Validation Error',
                    text: errorText || error.response.data.message,
                });
            } 
        } else if (error.request) {
            console.error('No response received:', error.request);
        } else {
            console.error('Error setting up request:', error.message);
        }
    
        return Promise.reject(error);
    }
)

export default axiosInstance;
